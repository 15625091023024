import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const CroppingEnterpriseMenu = (props) => {
  const {
    setAdjustmentModal = noop,
    setDirectCostModal = noop,
    setGrazingModal = noop,
    setTransferModal = noop,
    setTransactionModal = noop,
    setCroppingValueModal = noop,
  } = props;

  return (
    <div>
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="success" size="sm">
          <Icon name="plus" className="mr-1" />
          Action{' '}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem toggle={true} onClick={() => setTransactionModal(true)}>
            Add Sale/Purchase
          </DropdownItem>
          <DropdownItem toggle={true} onClick={() => setAdjustmentModal(true)}>
            Add Adjustment
          </DropdownItem>
          <DropdownItem
            toggle={true}
            onClick={() => setAdjustmentModal(true, null, true)}
          >
            Opening Value
          </DropdownItem>
          <DropdownItem toggle={true} onClick={() => setTransferModal(true)}>
            Add Transfer
          </DropdownItem>
          <DropdownItem toggle={true} onClick={() => setGrazingModal(true)}>
            Add Grazing
          </DropdownItem>
          <DropdownItem toggle={true} onClick={() => setDirectCostModal(true)}>
            Add Direct Cost
          </DropdownItem>
          <DropdownItem
            toggle={true}
            onClick={() => setCroppingValueModal(true)}
          >
            Add Value
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default CroppingEnterpriseMenu;
