import { isNil } from 'lodash';

export interface UnitAttributes {
  prefix?: string;
  suffix?: string;
}
export interface MetricData {
  value: string;
}
export interface MetricItem {
  unit_attributes: UnitAttributes;
  data: MetricData;
}

type MetricFormatter = (value: number) => string;
export interface FormattedMetric {
  numericValue: number;
  formattedValue: string;
  formatter: MetricFormatter;
  prefix: string;
  suffix: string;
}

const wholeNumberFormatter: MetricFormatter = (value) =>
  Intl.NumberFormat('en', { maximumFractionDigits: 0 }).format(value);

export const getFormattedValue = ({ data, unit_attributes }: MetricItem): FormattedMetric => {
  const parsedValue = parseFloat(data?.value);
  const numericValue = Number.isNaN(parsedValue) ? 0 : parsedValue;
  let formatter = wholeNumberFormatter;
  let prefix = isNil(unit_attributes?.prefix) ? '' : unit_attributes?.prefix;
  let suffix = isNil(unit_attributes?.suffix) ? '' : unit_attributes?.suffix;

  if (unit_attributes?.prefix === '$') {
    // wholeNumberFormatter is correct for $ as well
    prefix = '$ ';
  } else if (unit_attributes?.suffix === '%') {
    formatter = (value) =>
      Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    suffix = ' %';
  }
  const formattedValue = `${prefix}${formatter(numericValue)}${suffix}`;

  return {
    formattedValue,
    formatter,
    numericValue,
    prefix,
    suffix,
  };
};
