import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import ReportCell from './ReportCell';
import FormInput from '../../../../core/form/components/FormInputSmall';
import { controls as controlsDefn } from '../../forms/report_section_row';

const ReportSectionRow = (props) => {
  const {
    row,
    attributes,
    functions,
    draggableProps,
    dragHandleProps,
    isDragging,
    innerRef,
    currentReloadValue = null,
  } = props;

  const [showEdit, setShowEdit] = useState(false);
  const [rowControls, setRowControls] = useState(controlsDefn);

  const toggleEdit = () => {
    const controls = cloneDeep(rowControls);

    controls.caption.value = row.caption;
    setRowControls(controls);

    setShowEdit(true);
  };

  const onSave = () => {
    functions.updateSectionRow(row.section_id, row.id, rowControls);
    setShowEdit(false);
  };

  const onRemove = () => {
    /* eslint-disable-next-line no-alert */
    const confirmed = window.confirm('Remove row permanently?');
    if (confirmed) {
      functions.removeSectionRow(row.section_id, row.id);
      setShowEdit(false);
    }
  };

  const onAttribute = () => {
    functions.setAttributeModal(true, 'row', row.id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const controls = cloneDeep(rowControls);

    controls[name].value = value;
    setRowControls(controls);
  };

  const renderColumns = () => {
    const cols = row.cols.sort((left, right) => left.sequence - right.sequence);
    return cols.map((col) => (
      <Col
        key={`row-column-${col.id}`}
        className="text-right mr-1 p-0"
        sm={1}
        style={{ minWidth: 160 }}
      >
        <ReportCell
          cell={col}
          attributes={attributes}
          functions={functions}
          currentReloadValue={currentReloadValue}
        />
      </Col>
    ));
  };

  const rowClassName = `p-1 mx-1 opacity-100  ${row.className}`;
  return (
    <div
      {...draggableProps}
      ref={innerRef}
      key={row.id}
      className={
        isDragging
          ? 'border-primary opacity-100 row-dragging border'
          : 'opacity-100 bg-white border border-white'
      }
    >
      <Row className={rowClassName}>
        {attributes.reportEditable && (
          <>
            <div {...dragHandleProps}>
              <Icon name="grip-dots" title="Reorder row"></Icon>
            </div>
            {!showEdit && (
              <Col onClick={toggleEdit} style={{ cursor: 'pointer' }}>
                {row.caption}
              </Col>
            )}

            {showEdit && (
              <Col className="d-flex justify-content-start m-0 p-0">
                <FormInput
                  handleChange={handleChange}
                  control={rowControls.caption}
                  className="mr-2"
                  style={{ maxWidth: 300 }}
                />
                <Icon name="check" className="text-success mr-3 mt-2" onClick={onSave} />
                <Icon
                  name="x-mark"
                  className="text-danger mr-3 mt-2"
                  onClick={() => setShowEdit(false)}
                />
              </Col>
            )}

            {!showEdit && (
              <div>
                <Icon
                  name="pen-to-square"
                  className="text-success mr-3"
                  onClick={() => toggleEdit()}
                />
                <Icon name="tags" className="text-primary mr-3 mt-2" onClick={onAttribute} />
                <Icon name="trash" className="text-danger mr-3" onClick={onRemove} />
              </div>
            )}
          </>
        )}

        {!attributes.reportEditable && <Col>{row.caption}</Col>}

        {renderColumns()}
      </Row>
    </div>
  );
};

export default ReportSectionRow;
