import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import { cloneDeep, omit } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import {
  initListviewControls,
  updateListviewControlOptions,
  validateListviewControls,
} from '../../../core/form/lib/validateListview';
import { controls as allocationControls } from '../forms/enterpriseAllocations';
import { controls as allocationPropertiesControls } from '../forms/enterpriseAllocationPropertiesLst';
import Mapster from '../../projects/containers/Mapster';
import EnterpriseAllocationPropertiesLsv from '../components/EnterpriseAllocationPropertiesLsv';
import { fetchAttributes } from '../actions/attributes';
import {
  fetchEnterprise,
  updateEnterprise,
  createEnterprise,
  removeEnterprise,
  fetchEnterpriseUsages,
} from '../actions/enterprises';
import { fetchProperties } from '../../projects/actions/properties';

class EnterpriseAllocationModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(allocationControls),
      data: {},
      id: null,
      isModalDeleteDisabled: false,
      isNew: false,
      mapSources: [],
      propertyControls: [cloneDeep(allocationPropertiesControls)],
      title: 'Enterprise Allocation',
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.loadAttributes = this.loadAttributes.bind(this);
    this.onAddProperty = this.onAddProperty.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchProperties());
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      // Fetch and store divisions in reducer for all cases
      const divisions = await this.props.dispatch(fetchAttributes({ type: 'divisions' }));

      // Only load enterprise types/reporting/production units for new enterprise allocations
      if (divisions?.rows?.length > 0 && !this.props.id)
        await this.loadAttributes(divisions?.rows[0].id);

      // Inititialize copy of controls
      let controls = initControls(cloneDeep(allocationControls));
      controls.allocations.value = [];

      let propertyControls = initListviewControls(
        controls.allocations.value,
        cloneDeep(allocationPropertiesControls)
      );

      // Set updated state for a new enterprise allocation
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        propertyControls,
        title: 'New Enterprise Allocation',
      };

      const { id } = this.props;
      if (id) {
        // Check if enterprise has usages - used to determine if the existing allocation is allowed to be deleted
        const hasUsages = await this.props.dispatch(fetchEnterpriseUsages(id));

        // Fetch enterprise row from database
        const enterprise = await this.props.dispatch(fetchEnterprise(id));
        if (enterprise) {
          const { division_id } = enterprise;
          // Load attributes using the existing allocation's division id
          if (division_id) await this.loadAttributes(division_id);

          // Populate control values/options with existing allocation
          controls = updateControls(controls, enterprise);

          if (enterprise.allocations?.length > 0) {
            propertyControls = enterprise.allocations.map(() =>
              cloneDeep(allocationPropertiesControls)
            );
          }
        }

        updatedState = {
          ...updatedState,
          controls,
          propertyControls,
          data: enterprise,
          id,
          isNew: false,
          isModalDeleteDisabled: hasUsages,
          title: 'Edit Enterprise Allocation',
        };
      } else {
        const { properties } = this.props.properties;
        const allPropertyIds = properties?.rows?.map(({ id }) => id);

        controls.allocations.value.push({
          property_id: allPropertyIds[0],
          estimated_ha: null,
          open_date: null,
          close_date: null,
        });
        propertyControls.push(cloneDeep(allocationPropertiesControls));
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    const { data, controls, isNew, propertyControls } = this.state;
    let saveData = cloneDeep(saveControls(controls, data));

    // Remove react_id fields from the allocations list
    saveData.allocations = controls.allocations.value.map((allocation) =>
      omit(allocation, 'react_id')
    );

    // Validate form fields
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);
    const { isListValid, updatedListControls } = await validateListviewControls(
      saveData.allocations,
      propertyControls
    );

    if (isValid && isListValid) {
      let success;
      if (isNew) {
        saveData = omit(saveData, ['id']);
        success = await this.props.dispatch(createEnterprise(saveData));
      } else {
        success = await this.props.dispatch(updateEnterprise(saveData));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
        propertyControls: updatedListControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_ATTRIBUTES' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} enterprise permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeEnterprise(data.id));
      if (success) this.onClose(true);
    }
  }

  async onChange(event) {
    const { controls } = this.state;

    const old_division_id = controls.division_id.value;

    this.handleChange(event);

    const { name, value } = event.target;
    switch (name) {
      case 'division_id':
        // Load another select;
        if (old_division_id !== value) {
          await this.loadAttributes(value);
        }
        break;
      default:
        break;
    }
  }

  onAddProperty() {
    const { properties } = this.props.properties;
    const { controls, propertyControls } = this.state;
    const allPropertyIds = properties?.rows?.map(({ id }) => id);

    controls.allocations.value.push({
      property_id: allPropertyIds[0],
      estimated_ha: null,
      open_date: null,
      close_date: null,
    });
    propertyControls.push(cloneDeep(allocationPropertiesControls));
    this.setState({ controls, propertyControls });
  }

  async loadAttributes(parent_id) {
    const types = ['enterprise_types', 'reporting_units', 'production_units'];
    for (const type of types) await this.props.dispatch(fetchAttributes({ type, parent_id }));
  }

  render() {
    const { title, isNew, mapSources, isModalDeleteDisabled } = this.state;
    let { controls, propertyControls } = this.state;
    const { responseMessage } = this.props.enterprises;
    const { properties } = this.props.properties;
    const { divisions, enterprise_types, reporting_units, production_units } =
      this.props.attributes;
    const { isOpen } = this.props;

    const iconName = 'clipboard-list';
    const lngLat = [150.7333, -23.1333];

    const temp_divisions = divisions.filter(
      (division) => division.tag !== 'sugar' && division.tag !== 'horticulture'
    );

    // Set control options criteria
    const typeOptions = {
      division_id: temp_divisions,
      type_id: enterprise_types,
      reporting_units_id: reporting_units,
      production_units_id: production_units,
    };

    // Update control options
    Object.entries(typeOptions).forEach(
      ([key, options]) => (controls = updateControlOptions(controls, key, options))
    );

    // Allocation Options
    if (propertyControls?.length > 0) {
      propertyControls = updateListviewControlOptions(
        propertyControls,
        'property_id',
        properties?.rows
      );
    }

    return (
      <Modal isOpen={isOpen} className="w90-modal">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Row className="m-0 p-0">
            <Col sm={7} className="m-0 p-2">
              <Form>
                <FormInput handleChange={this.handleChange} control={controls.name} />
                <FormInput handleChange={this.onChange} control={controls.division_id} />
                <FormInput handleChange={this.onChange} control={controls.type_id} />
                <Row>
                  <Col>
                    <FormInput
                      handleChange={this.onChange}
                      control={controls.production_units_id}
                    />
                  </Col>
                  <Col>
                    <FormInput handleChange={this.onChange} control={controls.reporting_units_id} />
                  </Col>
                </Row>
              </Form>
              <EnterpriseAllocationPropertiesLsv
                rows={controls.allocations.value || []}
                properties={properties?.rows ?? []}
                allocationControls={propertyControls}
                onAddProperty={this.onAddProperty}
              />
            </Col>
            <Col sm={5} className="border border-secondary m-0 p-2">
              <div className="bg-danger">
                <Mapster
                  handleSourceVisibility={() => {}}
                  expandMap={false}
                  lngLatCenter={lngLat}
                  toggleMap={() => {}}
                  mapSources={mapSources}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={isModalDeleteDisabled}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, properties }) => ({
  attributes,
  enterprises,
  properties,
});

export default connect(mapStoreToProps)(EnterpriseAllocationModal);
