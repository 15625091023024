import moment from 'moment';
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    authorised: true,
    authResponseMessage: null,
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: 'business_config',
    businessConfigActiveTab: '1',
    enterprises: {},
    allocations: [],
    filteredAllocations: [],
    distributions: [],
    ranges: {
      min_date: null,
      max_date: null,
    },
    selectedRanges: {
      from_date: null,
      to_date: null,
    },
    selectedProperties: [],
    distinctAssociatedDivisions: [],
  },
  action,
) {
  // Set expected reducer types
  const types = ['ENTERPRISE', 'BUSINESS_CONFIG', 'DISTINCT_ASSOCIATED_DIVISIONS'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_DISTINCT_ASSOCIATED_DIVISIONS_FULFILLED': {
      return {
        ...updatedState,
        distinctAssociatedDivisions: action.payload,
      };
    }
    case 'FETCH_ENTERPRISES_FULFILLED': {
      return {
        ...updatedState,
        enterprises: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_ALLOCATIONS_FULFILLED': {
      return {
        ...updatedState,
        allocations: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_RANGES_FULFILLED': {
      const ranges = action.payload;
      const { selectedRanges } = updatedState;

      if (!selectedRanges.from_date) selectedRanges.from_date = ranges.min_date;
      if (!selectedRanges.to_date) selectedRanges.to_date = ranges.max_date;

      return {
        ...updatedState,
        ranges,
        selectedRanges,
      };
    }
    case 'FETCH_ENTERPRISE_TRANSACTION_RANGES_FULFILLED': {
      return {
        ...updatedState,
        selectedRanges: action.payload,
      };
    }
    case 'SET_ENTERPRISE_SELECTED_RANGES': {
      const values = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        values[key] = moment.utc(value).toISOString();
      });

      return {
        ...updatedState,
        selectedRanges: {
          ...updatedState.selectedRanges,
          ...values,
        },
      };
    }
    case 'SET_ENTERPRISES_ACTIVE_TAB': {
      return {
        ...updatedState,
        activeTab: action.payload,
      };
    }
    case 'SET_ENTERPRISE_SELECTED_PROPERTIES': {
      return {
        ...updatedState,
        selectedProperties: action.payload,
      };
    }
    case 'FETCH_ENTERPRISE_DISTRIBUTIONS_FULFILLED': {
      return {
        ...updatedState,
        distributions: action.payload,
      };
    }
    case 'UNSET_ENTERPRISE_DISTRIBUTIONS': {
      return {
        ...updatedState,
        distributions: [],
      };
    }
    case 'SET_BUSINESS_CONFIG_ACTIVE_TAB': {
      return {
        ...updatedState,
        businessConfigActiveTab: action.payload,
      };
    }
    case 'SET_ENTERPRISE_ALLOCATION_FILTER': {
      return {
        ...updatedState,
        filteredAllocations: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
