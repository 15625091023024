import * as Sentry from '@sentry/react';
import { sentryConfiguration as sentryConfig } from '../../configs/sentry';

Sentry.init({
  attachStacktrace: true,
  debug: process.env.NODE_ENV === 'development',
  dsn: sentryConfig.dsn,
  environment: sentryConfig.environment,
  release: sentryConfig.release,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    })
  ],
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tracePropagationTargets: ['localhost', /^https:\/\/(staging-)?ops\.carbonizer\.com\.au\/api/],
  profilesSampleRate: sentryConfig.profilesSampleRate,
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
});

