import { noop } from 'lodash';
import React from 'react';
import { Row, Col } from 'reactstrap';
import EnterprisesCard from './EnterprisesCard';

const EnterprisesTab = (props) => {
  const { history, toggleEnterprisesTab = noop, showTab } = props;

  const isLivestockEnterprise =
    showTab.includes('sheep') || showTab.includes('cattle') || showTab.includes('other_livestock');

  return (
    <>
      <Row className="text-white p-0 m-0">
        <Col sm={3}>
          <EnterprisesCard
            disabled={false}
            toggleEnterprisesTab={toggleEnterprisesTab}
            history={history}
            title="Enterprise Allocations"
            tab="business_config"
            iconName="chart-network"
            description="Allocating Enterprises to land"
            colourClass="indigo"
          />
        </Col>
        {showTab.includes('cattle') && (
          <Col sm={3}>
            <EnterprisesCard
              disabled={false}
              toggleEnterprisesTab={toggleEnterprisesTab}
              history={history}
              title="Cattle Stockflow"
              tab="cattle"
              iconName="right-left"
              description="Sales, Purchases and Transfers."
              colourClass="success"
            />
          </Col>
        )}
        {showTab.includes('sheep') && (
          <>
            <Col sm={3}>
              <EnterprisesCard
                disabled={false}
                toggleEnterprisesTab={toggleEnterprisesTab}
                history={history}
                title="Sheep Stockflow"
                tab="sheep"
                iconName="right-left"
                description="Sales, Purchases and Transfers."
                colourClass="purple"
              />
            </Col>
            <Col sm={3}>
              <EnterprisesCard
                disabled={false}
                toggleEnterprisesTab={toggleEnterprisesTab}
                history={history}
                title="Sheep Woolflow"
                tab="wool"
                iconName="right-left"
                description="Sales, Adjustments and Values."
                colourClass="orange"
              />
            </Col>
          </>
        )}
        {showTab.includes('crop') && (
          <Col sm={3}>
            <EnterprisesCard
              disabled={false}
              toggleEnterprisesTab={toggleEnterprisesTab}
              history={history}
              title="Cropflow"
              tab="crop"
              iconName="right-left"
              description="Sales, Purchases and Transfers."
              colourClass="pink"
            />
          </Col>
        )}
        {showTab.includes('other_livestock') && (
          <Col sm={3}>
            <EnterprisesCard
              disabled={false}
              toggleEnterprisesTab={toggleEnterprisesTab}
              history={history}
              title="Other Livestock Stockflow"
              tab="other_livestock"
              iconName="right-left"
              description="Sales, Purchases and Transfers"
              colourClass="green"
            />
          </Col>
        )}
        {isLivestockEnterprise && (
          <Col sm={3}>
            <EnterprisesCard
              disabled={false}
              toggleEnterprisesTab={toggleEnterprisesTab}
              history={history}
              title="Agistments"
              tab="agistments"
              iconName="fence"
              description="Incomes and expenses"
              colourClass="pink"
            />
          </Col>
        )}
        {showTab.includes('other_business') && (
          <Col sm={3}>
            <EnterprisesCard
              disabled={false}
              toggleEnterprisesTab={toggleEnterprisesTab}
              history={history}
              title="Other Business"
              tab="other_business"
              iconName="right-left"
              description="Incomes, Direct costs"
              colourClass="blue"
            />
          </Col>
        )}
        <Col sm={3}>
          <EnterprisesCard
            disabled={false}
            toggleEnterprisesTab={toggleEnterprisesTab}
            history={history}
            title="Off Farm"
            tab="off_farm"
            iconName="right-left"
            description="Income"
            colourClass="cyan"
          />
        </Col>
        <Col sm={3}>
          <EnterprisesCard
            disabled={false}
            toggleEnterprisesTab={toggleEnterprisesTab}
            history={history}
            title="Business Information"
            tab="business_information"
            iconName="receipt"
            description="Overheads, Expenditure, Assets"
            colourClass="teal"
          />
        </Col>
        <Col sm={3}>
          <EnterprisesCard
            disabled={true}
            toggleEnterprisesTab={toggleEnterprisesTab}
            history={history}
            title="Landcare Indicators"
            tab="land_indicators"
            iconName="leaf"
            count={3}
            description="Environment Indicators"
            colourClass="orange"
          />
        </Col>
        <Col sm={3}>
          <EnterprisesCard
            disabled={false}
            toggleEnterprisesTab={toggleEnterprisesTab}
            history={history}
            title="Analytics"
            tab="analytics"
            iconName="chart-mixed"
            description="Analytics and Reports"
            colourClass="warning"
          />
        </Col>
      </Row>
    </>
  );
};

export default EnterprisesTab;
