import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { saveFormControls, updateControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/property';
import Mapster from '../../projects/containers/Mapster';

import { fetchProperties, updateProperty } from '../../projects/actions/properties';
import { fetchPropertyTotalArea } from '../actions/enterprises';
import { cloneDeep } from 'lodash';

class PropertyProfile extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      controls: cloneDeep(controls),
      mapSources: [],
      map: null,
    };

    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate() {
    const { data, controls, map } = this.state;

    if (this.props.property.id !== data.id) {
      const { property } = this.props;

      // Update data with selected property
      const updatedData = cloneDeep(property);

      // Get total property area from associated enterprise allocations
      this.props.dispatch(fetchPropertyTotalArea(property.id)).then((data) => {
        if (data?.area_ha) updatedData.area_ha = data.area_ha;

        // Update controls with fetched data
        const updatedControls = cloneDeep(updateControls(controls, updatedData));

        this.setState({
          data: updatedData,
          controls: updatedControls,
        });
      });
    }

    // Force a map redraw
    if (map) map.resize();
  }

  async onSave() {
    const { data, controls } = this.state;
    const { project } = this.props.portal;

    const formData = saveFormControls(controls, data);

    const success = await this.props.dispatch(updateProperty(formData));

    if (success) this.props.dispatch(fetchProperties({ filter_org_id: project.org_id }));
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.table_name} definition permanently. Continue?`,
    );
    if (confirmed) {
      //   const success = await this.props.dispatch(removeAttributeDef(data.id));
      //   if (success) {
      //     this.props.dispatch(fetchAttributeDefs());
      //     this.state.setModal(false);
      //     this.setState({ isOpen: false });
      //   };
    }
  }

  render() {
    const { controls, mapSources } = this.state;

    const lngLat = [150.7333, -23.1333];

    return (
      <Row className="h-100">
        <Col sm={5} className="m-0 p-2">
          <FormInput handleChange={this.handleChange} control={controls.name} />
          <FormInput handleChange={this.handleChange} control={controls.address} />
          <Row>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.state} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.lga} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.nearest_town} />
            </Col>
          </Row>

          <div className="border-bottom border-corporate mt-2 bg-light">
            <small className="text-corporate ">AREA</small>
          </div>

          <FormInput handleChange={this.handleChange} control={controls.area_ha} />

          <div className="d-flex justify-content-center mt-2">
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
        </Col>
        <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            handleSourceVisibility={() => {}}
            expandMap={false}
            lngLatCenter={lngLat}
            toggleMap={() => {}}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = ({ manage, farmportrait_portal }) => ({
  manage,
  portal: farmportrait_portal,
});

export default connect(mapStoreToProps)(PropertyProfile);
