import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Col } from 'reactstrap';

import ReportCellBase from './ReportCellBase';
import WidgetTile from '../WidgetTile';

class WidgetCell extends ReportCellBase {
  render() {
    const { metric, fetching } = this.state;
    const { cell, reports } = this.props;
    const { hasFiltersChanged } = reports;

    const hoverTitle = metric?.id ? metric.name : 'Unassigned cell';
    const onClearCell = reports.reportEditable ? this.removeCell : null;

    return (
      <>
        {cell?.metric_id && (
          <WidgetTile
            displayResyncWarning={hasFiltersChanged}
            isLoading={fetching}
            metric={metric}
            onEdit={this.viewMetric}
            onClearCell={onClearCell}
            colSize={3}
          />
        )}
        {!cell?.metric_id && (
          <Col sm={3} className="m-0 p-0">
            {reports.reportEditable && (
              <div
                className="hover-cell text-center m-1 p-3 bg-light rounded"
                title={hoverTitle}
                onClick={this.editContent}
                onKeyDown={this.editContent}
                role="button"
                tabIndex={0}
              >
                <div className="text-black">Click to select Widget</div>
                <Icon className="m-1 mr-2 text-success" size="1x" name="pen-to-square" />
              </div>
            )}
          </Col>
        )}
      </>
    );
  }
}

const mapStoreToProps = ({ reports, report_preview, enterprises }) => ({
  reports,
  report_preview,
  enterprises,
});

export default connect(mapStoreToProps)(WidgetCell);
