import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

const OverheadsToolbar = (props) => {
  const { onAddNew, filterClick } = props;

  return (
    <Fragment>
      <div className="d-flex justify-content-end m-2">
        <Button onClick={onAddNew} size="sm" color="success">
          Add Overhead
        </Button>
        <Button
          id="toggler"
          size="sm"
          color="warning"
          className="text-white ml-2"
          onClick={filterClick}
        >
          Filter
        </Button>
      </div>
    </Fragment>
  );
};

export default OverheadsToolbar;
