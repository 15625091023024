import axios from 'axios';
import errorResponse from '../../../../lib/errorResponse';
import { get, post, put, remove, download, upload } from '../../../../lib/genericAction';

import { apiAnalytics, apiProperties, apiPropertySearch } from '../../../../constants/api';

import { mapboxUrl } from '../../../../constants/map';

export function exportGeoJson(name, geojson) {
  return async (dispatch) => {
    dispatch({ type: 'EXPORT_SHP_PENDING' });

    try {
      const file = await axios.post(
        `${apiPropertySearch}/export/geojson`,
        { name, geojson },
        {
          headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
          responseType: 'blob',
        },
      );

      dispatch({ type: 'EXPORT_SHP_FULFILLED' });
      return file;
    } catch (err) {
      errorResponse(dispatch, err, 'EXPORT_SHP_REJECTED');
      return false;
    }
  };
}

export function fetchWMSObject(url, headers, params) {
  return async (dispatch) => get(dispatch, 'FETCH_WMS_OBJECT', url, params, headers, true);
}

export function convertGeometry(data) {
  return async (dispatch) =>
    post(dispatch, 'TRANSLATE_GEOMETRY', `${apiPropertySearch}/translate`, data, true);
}

export function fetchGeocodingByLatLong(access_token, coordinates) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_GEO_LOCATION', `${mapboxUrl}/${coordinates.lng},${coordinates.lat}.json`, {
      access_token,
    });
}

export function fetchLibrarySources() {
  return async (dispatch) => get(dispatch, 'FETCH_LIBRARY_SOURCES', `${apiPropertySearch}/sources`);
}

export function fetchPropertyInsights(id, inBackground = false) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_PROPERTY_INSIGHTS',
      `${apiProperties}/${id}/insights`,
      null,
      null,
      inBackground,
    );
}

export function generatePropertyInsights(id, params) {
  return async (dispatch) =>
    get(dispatch, 'GENERATE_PROPERTY_INSIGHTS', `${apiProperties}/${id}/insights/generate`, params);
}

export function fetchLotPlanList(lotplan) {
  return async (dispatch) =>
    post(dispatch, 'FETCH_LOTPLAN_LIST', `${apiPropertySearch}/lotplanlist`, { lotplan });
}

export function fetchLotPlanListBeta(lotplan) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_LOTPLAN_LIST',
      `${apiPropertySearch}/lotplanlist`,
      {
        lotplan,
      },
      null,
      true,
    );
}

export function fetchLotPlan(id, model) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_LOTPLAN_LIST', `${apiPropertySearch}/lotplan/${id}`, {
      model,
    });
}

export function fetchLotProximity(property, radius) {
  return async (dispatch) =>
    post(dispatch, 'FETCH_LOTPLAN_PROXIMITY', `${apiPropertySearch}/lotplan`, {
      property,
      radius,
    });
}

export function fetchPropertySearch(coords) {
  return async (dispatch) =>
    post(dispatch, 'FETCH_PROPERTY_SEARCH', `${apiPropertySearch}/properties`, { coords });
}

export function fetchCadastralProximitySearch(coords, distance = 2000) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CADASTRAL_PROXIMITY_SEARCH', `${apiPropertySearch}/proximity`, {
      coords,
      distance,
    });
}

export function fetchCadastralSearch(property_id) {
  return async (dispatch) =>
    post(dispatch, 'FETCH_CADASTRAL_SEARCH', `${apiPropertySearch}/cadastral`, {
      property_id,
    });
}

export function fetchProperties(params) {
  return async (dispatch) => get(dispatch, 'FETCH_PROPERTIES', apiProperties, params);
}

export function fetchPropertiesStats(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_PROPERTIES_STATS', `${apiProperties}/stats`, params);
}

export function fetchProperty(id) {
  return async (dispatch) => get(dispatch, 'FETCH_PROPERTY', `${apiProperties}/${id}`);
}

export function updateProperty(formData) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROPERTY', `${apiProperties}/${formData.get('id')}`, formData);
}

export function createProperty(project) {
  return async (dispatch) => post(dispatch, 'CREATE_PROPERTY', `${apiProperties}`, project);
}

export function removeProperty(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_PROPERTY', `${apiProperties}/${id}`);
}

export function uploadStratas(formData, onProgressChange) {
  return async (dispatch) =>
    upload(
      dispatch,
      'UPLOAD_PROPERTY_STRATAS',
      `${apiProperties}/${formData.get('property_id')}/stratas/upload`,
      formData,
      onProgressChange,
    );
}

export function uploadSites(formData, onProgressChange) {
  return async (dispatch) =>
    upload(
      dispatch,
      'UPLOAD_PROPERTY_SITES',
      `${apiProperties}/${formData.get('property_id')}/sites/upload`,
      formData,
      onProgressChange,
    );
}

export function downloadPropertyMapPack(params) {
  return async (dispatch) =>
    get(
      dispatch,
      'DOWNLOAD_PROPERTY_MAP_PACK',
      `${apiProperties}/${params.property_id}/maps/download`,
      params,
    );
}

export function downloadPropertyReportingPack(params) {
  return async (dispatch) =>
    get(
      dispatch,
      'DOWNLOAD_PROPERTY_REPORTING_PACK',
      `${apiAnalytics}/properties/${params.property_id}/${
        params.audit === 'true' ? 'audit' : 'reporting'
      }`,
      params,
    );
}
