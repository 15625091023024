import React from 'react';
import Listview from '../../../../core/form/components/Listview';
import moment from 'moment';
import { isNil } from 'lodash';

const ClientsLsv = (props) => {
  const {
    rows,
    onClick,
    // history
  } = props;

  const renderRows = (headers, row) => {
    const users = row.user_orgs.length;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'users':
          return (
            <td key={index} className={header.classes}>
              {users}
            </td>
          );
        case 'subscriptions':
          return (
            <td key={index} className={header.classes}>
              {row.org_subscriptions
                .filter(
                  (s) => s.enabled && (isNil(s.expires_on) || moment().isBefore(s.expires_on)),
                )
                .map((s) => s.subscription_type?.name)
                .sort()
                .join(', ')}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name' },
    { caption: 'Property', field: 'property_name' },
    { caption: 'Client', field: 'client' },
    { caption: '# Users', field: 'users' },
    { caption: 'Subscriptions', field: 'subscriptions' },
    { caption: '', field: 'actions', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;

  if (haveRows) {
    tableBodyTr = rows.map((project, index) => (
      <React.Fragment key={index}>
        <tr onClick={() => onClick(project)} style={{ cursor: 'pointer' }}>
          {renderRows(headers, project)}
        </tr>
      </React.Fragment>
    ));
  }

  const iconName = 'restroom';
  const emptyCaption = 'No Clients found';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default ClientsLsv;
