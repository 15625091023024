
import { appConfig } from 'configs/app';

function initConfiguration() {
    const config = {
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLERATE),
        profilesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_PROFILES_SAMPLERATE),
        replaysSessionSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLERATE),
        replaysOnErrorSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ONERROR_SAMPLERATE),
        environment: appConfig.environment || 'local',
        release: `carbonizer-webapp@${appConfig.version}+${appConfig.build}`
    };
    return config;
}

export const sentryConfiguration = initConfiguration();

