import React, { Fragment } from 'react';
import { noop } from 'lodash';
import { Alert, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const ReportsToolbar = ({
  hasFiltersChanged = false,
  isFilterOpen = false,
  onFilterClick = noop,
}) => (
  <>
    <Alert color="primary" className="d-flex justify-content-end p-0">
      <div className="d-flex justify-content-end m-2">
        {hasFiltersChanged && (
          <Alert color="light" className="m-0 px-2 py-1 fs-14px border-0">
            *Reload report to apply new filter values
          </Alert>
        )}
        <Button size="sm" color="warning" className="text-white ml-2" onClick={onFilterClick}>
          <div className="flex">
            Edit Filters
            <Icon name={isFilterOpen ? 'chevron-up' : 'chevron-down'} className="ml-1" />
          </div>
        </Button>
      </div>
    </Alert>
  </>
);

export default ReportsToolbar;
