import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import CroppingEnterpriseMenu from './CroppingEnterpriseMenu';
import { noop } from 'lodash';

const CroppingEnterpriseToolbar = (props) => {
  const {
    setModal = noop,
    setAdjustmentModal = noop,
    setDirectCostModal = noop,
    setGrazingModal = noop,
    setTransferModal = noop,
    setTransactionModal = noop,
    setCroppingValueModal = noop,
    onRefresh = noop,
  } = props;

  return (
    <div className="d-flex justify-content-end">
      <CroppingEnterpriseMenu
        setModal={setModal}
        setAdjustmentModal={setAdjustmentModal}
        setDirectCostModal={setDirectCostModal}
        setGrazingModal={setGrazingModal}
        setTransferModal={setTransferModal}
        setTransactionModal={setTransactionModal}
        setCroppingValueModal={setCroppingValueModal}
      />
      <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
        <Icon name="rotate" />
      </Button>
    </div>
  );
};

export default CroppingEnterpriseToolbar;
