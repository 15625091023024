import React from 'react';
import { connect } from 'react-redux';
import FormBase from '../../core/form/components/FormBase';
import GenericLsv from '../../core/form/components/GenericLsv';
import { controls as reportControls } from '../forms/reports';
import { cloneDeep } from 'lodash';
import ReportsToolbar from '../components/ReportsToolbar';
import { saveControls } from '../../core/form/lib/validateForm';

import { fetchReports, createReport } from '../../modules/portrait/actions/reports';

class Reports extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      showAddNew: false,
      controls: cloneDeep(reportControls),
    };

    this.addNew = this.addNew.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.edit = this.edit.bind(this);
  }

  async addNew() {
    const { controls } = this.state;

    const unsavedData = saveControls(controls, {});

    const success = await this.props.dispatch(
      createReport({ requires_subscription: false, ...unsavedData }),
    );
    if (success?.id) {
      this.props.dispatch(fetchReports());
      this.toggleAddNew();
    }
  }

  edit(id) {
    if (id) this.props.history.push(`/home/reports/${id}`);
  }

  toggleAddNew() {
    this.setState({ showAddNew: !this.state.showAddNew });
  }

  render() {
    const { showAddNew, controls } = this.state;
    const { reports } = this.props.reports;

    return (
      <>
        <ReportsToolbar
          addNew={this.addNew}
          controls={controls}
          toggleAddNew={this.toggleAddNew}
          showAddNew={showAddNew}
          handleChange={this.handleChange}
        />
        <GenericLsv
          controls={cloneDeep(reportControls)}
          darkTable={false}
          emptyCaption={'No Reports found'}
          hideHeader={false}
          iconName={'file-chart-column'}
          onClick={this.edit}
          rows={reports}
        />
      </>
    );
  }
}

const mapStoreToProps = ({ reports }) => {
  return {
    reports,
  };
};

export default connect(mapStoreToProps)(Reports);
