import Footer from 'jsx/components/core/form/components/Footer';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormSearch from 'jsx/components/core/form/components/FormSearch';
import MonthPicker from 'jsx/components/core/form/components/MonthPicker';
import Tree from 'jsx/components/core/form/components/Tree';
import {
  saveControls,
  setInvalidField,
  setValidField,
  updateControls,
  validateFormFieldControls,
} from 'jsx/components/core/form/lib/validateForm';
import Icon from 'jsx/components/core/icons/Icon';
import { fetchEnterpriseRanges } from 'jsx/components/modules/portrait/actions/enterprises';
import {
  createReportMetric,
  fetchMetricFromFormula,
  fetchReportMetric,
  removeReportMetric,
  updateReportMetric,
} from 'jsx/components/modules/portrait/actions/reports';
import WidgetGauge from 'jsx/components/modules/portrait/components/WidgetGauge';
import WidgetTile from 'jsx/components/modules/portrait/components/WidgetTile';
import { cloneDeep, isEmpty, omit } from 'lodash';
import moment from 'moment';
import React from 'react';
import { CirclePicker } from 'react-color';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  List,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { fetchOrgs } from '../../actions';
import { controls as metricControls } from '../../forms/metrics';

class MetricsEditor extends FormBase {
  constructor(props) {
    super(props);

    this.defaultMetric = {
      data: {
        goal: 0,
        value: 0,
      },
      subMetrics: [],
    };

    this.state = {
      controls: cloneDeep(metricControls),
      data: {},
      isClientSelected: false,
      isNew: true,
      isTimerRunning: false,
      metric: cloneDeep(this.defaultMetric),
      timer: {
        id: undefined,
        delay: 2000,
      },
      title: 'New Metric',
    };

    this.formatFormula = this.formatFormula.bind(this);
    this.generateMetricFromFormula = this.generateMetricFromFormula.bind(this);
    this.getOrgIdByName = this.getOrgIdByName.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.loadSubMetrics = this.loadSubMetrics.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClientSelect = this.onClientSelect.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onColourChange = this.onColourChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.renderWidget = this.renderWidget.bind(this);
  }

  componentDidMount() {
    // Set default clients
    this.props.dispatch(fetchOrgs());

    // Set default date ranges
    this.props.dispatch(fetchEnterpriseRanges());

    // Set initial state for new/edit metric
    const controls = cloneDeep(metricControls);
    let updatedState = {
      controls,
      isNew: true,
      metric: cloneDeep(this.defaultMetric),
      title: 'New Metric',
    };

    if (this.props?.match?.params?.id) {
      const { id } = this.props.match.params;
      updatedState = { ...updatedState, isNew: false, title: 'Edit Metric' };

      const { from_date, to_date } = this.props.enterprises?.selectedRanges;
      // Default client search to RCS Australia
      const { orgs } = this.props.manage;
      const defaultOrg = orgs.find(({ tag }) => tag === 'rcs_australia');

      const params = {
        id,
        org_id: defaultOrg.id,
        from_date,
        to_date,
      };

      this.props.dispatch(fetchReportMetric(params)).then((metric) => {
        // Update metric with top level properties to update controls correctly.
        let updatedMetric = cloneDeep(metric);

        // Format unit attributes
        ['goal', 'prefix', 'suffix'].forEach((attribute) => {
          const value = updatedMetric?.unit_attributes[attribute];
          if (value) updatedMetric[attribute] = value;
        });

        // Format widget attributes
        const color = updatedMetric?.widget_attributes?.color ?? null;

        updatedMetric = { ...updatedMetric, color };

        const updatedControls = updateControls(controls, updatedMetric);
        updatedControls.client_search.value = defaultOrg.name;

        this.setState({
          controls: updatedControls,
          metric: updatedMetric,
          isClientSelected: true,
        });
      });
    }

    this.setState(updatedState);
  }

  formatFormula() {
    const { value: formula } = this.state.controls.formula;

    return formula?.replaceAll('#', '');
  }

  getOrgIdByName(value) {
    const { orgs } = this.props.manage;

    return orgs.length > 0 ? orgs.find(({ name }) => name === value)?.id : null;
  }

  async generateMetricFromFormula() {
    const { isClientSelected, controls } = this.state;

    let updatedControls = cloneDeep(controls);
    const { client_search, formula } = updatedControls;
    // Base cases
    updatedControls.client_search = isClientSelected
      ? setValidField(client_search)
      : setInvalidField({
          control: client_search,
          custom_message: 'Please select a valid client...',
          override: true,
        });

    // Validate formula value
    updatedControls.formula = !isEmpty(formula.value)
      ? setValidField(formula)
      : setInvalidField({
          control: formula,
          custom_message: 'Please input a formula',
          override: true,
        });

    let updatedState = {
      controls: updatedControls,
    };

    if (formula.valid && client_search.valid && isClientSelected) {
      const { metric } = this.state;
      const { from_date, to_date } = this.props.enterprises.selectedRanges;
      const { value } = formula;

      // Get org id by selected client
      const org_id = this.getOrgIdByName(client_search.value);
      if (org_id) {
        const updatedMetric = await this.props.dispatch(
          fetchMetricFromFormula({ formula: value, from_date, to_date, org_id })
        );

        // Update/reset metric state
        updatedState = updatedMetric
          ? {
              ...updatedState,
              metric: {
                ...metric,
                ...updatedMetric,
                data: { ...metric?.data, ...updatedMetric.data },
              },
            }
          : { ...updatedState, metric: this.defaultMetric };
      }
    }

    this.setState(updatedState);
  }

  handleKeyDown(event) {
    const { value } = event.target;

    if (event.keyCode === 13 && value !== '') {
      const { timer } = this.state;

      clearTimeout(timer.id);
      this.setState({ isTimerRunning: false, timer });
      this.generateMetricFromFormula();

      return;
    }
  }

  async loadSubMetrics(parent_id) {
    const { value: name } = this.state.controls.client_search;
    const { from_date, to_date } = this.props.enterprises?.selectedRanges;
    const org_id = this.getOrgIdByName(name);

    if (org_id) {
      const metric = await this.props.dispatch(
        fetchReportMetric({ id: parent_id, from_date, to_date, org_id })
      );

      return metric?.subMetrics ?? [];
    }
  }

  onChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'client_search': {
        this.setState({ isClientSelected: false });
        break;
      }
      case 'formula': {
        const { timer } = this.state;

        // Reset timer/run spinner
        clearTimeout(timer.id);

        // Base check
        if (!value || value === '') {
          const { metric } = this.state;
          metric.data.value = null;
          this.setState({ isTimerRunning: false, metric, timer });
          break;
        }

        timer.id = setTimeout(() => {
          this.generateMetricFromFormula();
          this.setState({ isTimerRunning: false });
        }, timer.delay);

        this.setState({ timer, isTimerRunning: true });
        break;
      }
      case 'key': {
        /**
         * Change uppercase to lowercase
         * Negate all user input which is not a lowercase letter, number, underscore, or a dash.
         */
        event.target.value = String(value)
          .toLowerCase()
          .replace(/[^a-z0-9_-]/g, '');
        break;
      }
      default: {
        break;
      }
    }

    this.handleChange(event);
  }

  onClientSelect(option) {
    const { controls } = this.state;
    controls.client_search.value = option.name;
    this.setState({ isClientSelected: true, controls }, this.generateMetricFromFormula);
  }

  onClose() {
    this.props.history.push('/home/settings');
  }

  onColourChange({ hex }) {
    const { controls } = this.state;

    const updatedControls = { ...controls, color: { ...controls.color, value: hex } };
    this.setState({ controls: updatedControls });
  }

  async onDateChange(type, value) {
    let range = {};

    let date = moment(value);
    switch (type) {
      case 'to_date': {
        date = date.endOf('month');
        break;
      }
      case 'from_date':
      default: {
        date = date.startOf('month');
        break;
      }
    }

    range[type] = date.toISOString(true);

    await this.props.dispatch({ type: 'SET_ENTERPRISE_SELECTED_RANGES', payload: range });

    // Refresh metric data
    const { value: formula } = this.state.controls.formula;
    if (formula) this.generateMetricFromFormula(formula);
  }

  async onRemove() {
    const { id } = this.state.metric;

    const confirmed = window.confirm(`Removing Adjustment permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeReportMetric(id));
      if (success) this.onClose();
    }
  }

  async onSave() {
    const { controls, metric, isNew } = this.state;

    let saveData = saveControls(controls, metric);
    const { isValid, updatedControls } = validateFormFieldControls(saveData, controls);

    if (isValid) {
      let saveMethod = updateReportMetric;

      if (isNew) {
        saveData = omit(saveData, ['id']);
        saveMethod = createReportMetric;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose();
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  renderWidget() {
    const { controls, metric, isTimerRunning } = this.state;

    const { value: widgetTag } = controls.default_widget_tag;
    const { value: goal } = controls.goal;
    const { value: name } = controls.name;
    const { value: color } = controls.color;
    const { value: prefix } = controls.prefix;
    const { value: suffix } = controls.suffix;

    // Update metric with control values
    const updatedMetric = {
      ...metric,
      data: { ...metric?.data, goal },
      name,
      unit_attributes: { ...metric?.unit_attributes, prefix, suffix },
      widget_attributes: { ...metric?.widget_attributes, color },
    };

    switch (widgetTag) {
      case 'gauge':
        return (
          <WidgetGauge
            metric={updatedMetric}
            colSize={8}
            noHeader={false}
            isLoading={isTimerRunning}
          />
        );
      case 'tile':
      default:
        return (
          <WidgetTile
            metric={updatedMetric}
            colSize={8}
            noHeader={false}
            isLoading={isTimerRunning}
          />
        );
    }
  }

  render() {
    const { controls } = this.state;
    const { isClientSelected, isNew, metric, title } = this.state;
    const { selectedRanges } = this.props.enterprises;
    const { orgs } = this.props.manage;
    const { responseMessage } = this.props.reports;

    const iconName = isNew ? 'plus' : 'edit';

    const isPrefixSuffixDisabled = controls.default_widget_tag.value === 'gauge';

    if (isPrefixSuffixDisabled) {
      controls.prefix.value = null;
      controls.suffix.value = '%';
    }

    const isGoalDisabled = controls.default_widget_tag.value === 'tile';
    if (isGoalDisabled) controls.goal.value = null;

    // Set options
    controls.client_search.options = orgs;

    return (
      <Container className="mw-100 h-100">
        <Row xs="2" className="h-100">
          <Col xs="7" className="bg-light border h-100">
            <Card className="my-4 mw-100">
              <CardHeader className="bg-corporate text-white">
                <div className="d-flex mt-1">
                  <Icon size="lg" name={iconName} className="mr-2" />
                  <h5>{title}</h5>
                </div>
              </CardHeader>
              <CardBody>
                {responseMessage && (
                  <h6 className="text-center text-danger mt-2">{responseMessage}</h6>
                )}
                <Form>
                  <Row xs={2}>
                    <Col xs="8">
                      <FormInput handleChange={this.handleChange} control={controls.name} />
                    </Col>
                    <Col xs="4">
                      <FormGroup row className="mt-0 mb-0 align-items-center">
                        <Label for={controls.key.name} className="font-weight-bold col-form-label">
                          {controls.key.caption}
                        </Label>
                        <UncontrolledPopover
                          placement="right"
                          target={controls.key.name}
                          trigger="focus"
                        >
                          <PopoverHeader>Key requirements</PopoverHeader>
                          <PopoverBody>
                            <p className="p-0 m-0">May contain:</p>
                            <List type="unstyled">
                              <li className="text-success">✔️ letters and numbers</li>
                              <li className="text-success">✔️ underscores</li>
                              <li className="text-success">✔️ dashes</li>
                            </List>
                            <p className="p-0 m-0">May not contain:</p>
                            <List type="unstyled">
                              <li className="text-danger">❌ uppercase letters</li>
                              <li className="text-danger">❌ math operators</li>
                              <li className="text-danger">❌ special characters</li>
                            </List>
                          </PopoverBody>
                        </UncontrolledPopover>
                        <Input
                          id={controls.key.name}
                          className={'form-control-sm form-control'}
                          onChange={this.onChange}
                          name={controls.key.name}
                          type={controls.key.type}
                          value={controls.key.value ?? ''}
                        />
                        {!controls.key.valid && (
                          <small className="text-danger">{controls.key.message}</small>
                        )}
                        {controls.key.description && (
                          <small className="text-success">{controls.key.description}</small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormInput handleChange={this.handleChange} control={controls.description} />
                  <>
                    <Label for={controls.formula.name} className="font-weight-bold">
                      {controls.formula.caption}
                    </Label>
                    <Input
                      id={controls.formula.name}
                      onChange={this.onChange}
                      onKeyDown={this.handleKeyDown}
                      name={controls.formula.name}
                      type={controls.formula.type}
                      value={controls.formula.value ?? ''}
                    />

                    {!controls.formula.valid && (
                      <small className="text-danger">{controls.formula.message}</small>
                    )}
                  </>
                  <h5 className="m-0 mt-3 ml-1 mb-1 text-corporate border-bottom">
                    Widget Attributes
                  </h5>
                  <Row xs={2}>
                    <Col xs="8">
                      <FormInput
                        handleChange={this.handleChange}
                        control={controls.default_widget_tag}
                      />
                    </Col>
                    <Col xs="4">
                      <Label for={'circlePicker'} className="font-weight-bold mt-2" size="md">
                        {controls.color.caption}
                      </Label>
                      <div className="d-flex justify-content-center m-0">
                        <CirclePicker
                          circleSize={18}
                          circleSpacing={10}
                          name="circlePicker"
                          onChange={this.onColourChange}
                          width="w-100"
                        />
                      </div>
                    </Col>
                  </Row>
                  <h5 className="m-0 mt-3 ml-1 mb-1 text-corporate border-bottom">
                    Unit Attributes
                  </h5>
                  <Row xs={3}>
                    <Col xs="4">
                      <FormInput
                        handleChange={this.handleChange}
                        control={controls.prefix}
                        disabled={isPrefixSuffixDisabled}
                      />
                    </Col>
                    <Col xs="4">
                      <FormInput
                        handleChange={this.handleChange}
                        control={controls.suffix}
                        disabled={isPrefixSuffixDisabled}
                      />
                    </Col>
                    <Col xs="4">
                      <FormInput
                        handleChange={this.handleChange}
                        control={controls.goal}
                        disabled={isGoalDisabled}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter className="d-flex justify-content-end">
                <Footer
                  onClose={this.onClose}
                  onRemove={this.onRemove}
                  onSave={this.onSave}
                  isNew={isNew}
                />
              </CardFooter>
            </Card>
          </Col>
          <Col xs="5" className="bg-light border h-100">
            <Card className="my-4 mw-100">
              <CardHeader className="bg-corporate text-white">
                <div className="d-flex mt-1">
                  <Icon size="lg" name={'gauge-circle-bolt'} className="mr-2" />
                  <h5>Preview</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row className="bg-light border-bottom border-secondary p-1 mt-2">
                    <Col sm={8} className="d-flex justify-content-start">
                      <Label for={controls.client_search.name} className="mr-2 mb-0 mt-2">
                        Client:
                      </Label>
                      <FormSearch
                        handleChange={this.onChange}
                        handleSelect={this.onClientSelect}
                        control={controls.client_search}
                        selected={isClientSelected}
                        minimumLength={1}
                        width="100%"
                      />
                    </Col>
                    <Col sm={4} className="d-flex justify-content-end">
                      <MonthPicker
                        id="from-month"
                        caption="From:"
                        className="mr-2"
                        maximum={selectedRanges.to_date}
                        selected={selectedRanges.from_date}
                        handleChange={(date) => this.onDateChange('from_date', date)}
                      />
                      <MonthPicker
                        id="to-month"
                        caption="To:"
                        minimum={selectedRanges.from_date}
                        selected={selectedRanges.to_date}
                        handleChange={(date) => this.onDateChange('to_date', date)}
                        popperPlacement={'bottom-end'}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center my-4">{this.renderWidget()}</Row>
                  <Row>
                    <Col>
                      <div className="bg-light border-bottom border-secondary p-1 mt-2">
                        Formula
                      </div>
                      <div>{this.formatFormula()}</div>
                      <div className="bg-light border-bottom border-secondary p-1 mt-2">
                        Sub-Metrics
                      </div>
                      <small className="text-success">
                        Red icons represent functions which can not be drilled down in. Blue icons
                        are nested and can be opened for more detail and formula descriptions.
                      </small>
                      <Tree items={metric?.subMetrics} load={this.loadSubMetrics} />
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStoreToProps = ({ enterprises, manage, profile, reports }) => {
  return {
    enterprises,
    manage,
    profile,
    reports,
  };
};

export default connect(mapStoreToProps)(MetricsEditor);
