import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'enterprise_id',
    fieldName: 'enterprise.name',
    type: 'select',
    caption: 'Enterprise Name',
    validationRules: {
      isRequired: true,
    },
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    fieldName: 'property.name',
    type: 'select',
    caption: 'Property',
    validationRules: { isRequired: true },
    showInEditor: true,
    showInListview: true,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'animal_class_id',
    fieldName: 'animal_class.name',
    type: 'select',
    caption: 'Animal Class',
    validationRules: {},
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  date: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'date',
    name: 'date',
    type: 'date',
    caption: 'Value Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    sortColumn: 'date',
  },
  weight: {
    ...controlDefaults,
    fieldName: 'weight',
    unitType: 'kg',
    placeholder: '',
    name: 'weight',
    type: 'number',
    caption: 'Weight (kg/head)',
    validationRules: {},
    showInEditor: true,
    showInListview: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    type: 'number',
    caption: 'Value ($/kg)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  au_rating: {
    ...controlDefaults,
    fieldName: 'au_rating',
    placeholder: '',
    unitType: 'lsu',
    name: 'au_rating',
    type: 'number',
    caption: 'AU Rating',
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
};
