import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    purchases: {},
    sales: {},
    adjustments: {},
    breedings: {},
    transfers: {},
    summaries: {},
    stockflows: [],
    agistments: [],
    livestock_incomes: [],
    activeCattleTab: '1',
    activeSheepTab: '1',
    activeLiveStockTab: '1',
    activeAgistmentTab: '10',
  },
  action
) {
  // Set expected reducer types
  const types = ['LIVESTOCK'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_LIVESTOCK_PURCHASES_FULFILLED': {
      return {
        ...state,
        purchases: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_SALES_FULFILLED': {
      return {
        ...state,
        sales: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_ADJUSTMENTS_FULFILLED': {
      return {
        ...state,
        adjustments: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_BREEDINGS_FULFILLED': {
      return {
        ...state,
        breedings: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_TRANSFERS_FULFILLED': {
      return {
        ...state,
        transfers: action.payload,
      };
    }

    case 'FETCH_LIVESTOCK_AGISTMENTS_FULFILLED': {
      return {
        ...state,
        agistments: action.payload,
      };
    }

    case 'FETCH_LIVESTOCK_SUMMARIES_FULFILLED': {
      return {
        ...state,
        summaries: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_STOCKFLOWS_FULFILLED': {
      return {
        ...state,
        stockflows: action.payload,
      };
    }
    case 'FETCH_LIVESTOCK_INCOMES_FULFILLED': {
      return {
        ...state,
        livestock_incomes: action.payload,
      };
    }
    case 'SET_LIVESTOCK_CATTLE_ACTIVE_TAB': {
      return {
        ...state,
        activeCattleTab: action.payload,
      };
    }
    case 'SET_LIVESTOCK_SHEEP_ACTIVE_TAB': {
      return {
        ...state,
        activeSheepTab: action.payload,
      };
    }
    case 'SET_LIVESTOCK_OTHER_LIVESTOCK_ACTIVE_TAB': {
      return {
        ...state,
        activeLiveStockTab: action.payload,
      };
    }
    case 'SET_LIVESTOCK_AGISTMENT_ACTIVE_TAB': {
      return {
        ...state,
        activeAgistmentTab: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
