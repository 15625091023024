import { getFormattedValue } from 'jsx/components/core/form/lib/metricFormat';
import Icon from 'jsx/components/core/icons/Icon';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { connect } from 'react-redux';
import ReportCellBase from './ReportCellBase';

const handleEnter = (handler) => (event) => {
  if (event && event.key === 'Enter') {
    handler(event);
  }
};
class ReportCell extends ReportCellBase {
  render() {
    const { metric } = this.state;
    const { cell, reports } = this.props;

    let className = 'report-cell bg-light';
    if (reports.reportCellEditable) className += ' report-cell-edit border border-lightgray';

    if (!cell.metric_id && !reports.reportCellEditable) className = 'report-cell-none';

    let value = '-';
    let hoverTitle = 'Unassigned cell';

    if (cell.metric_id) {
      if (metric?.data?.value) {
        value = getFormattedValue(metric).formattedValue;
      }

      hoverTitle = `${metric?.name} - Click to see more`;
    }

    // In viewonly mode, enable detailed metric modal viewer if the metric has been set for the cell
    const metricViewer = cell.metric_id
      ? { onClick: this.viewMetric, style: { cursor: 'pointer' } }
      : { onClick: null, style: {} };

    const { hasFiltersChanged } = reports;
    if (hasFiltersChanged) className += 'border border-warning border-radius-5';

    return (
      <div className={className}>
        {reports.reportEditable && (
          <div className="hover-cell text-center" title={hoverTitle}>
            <Icon
              className="m-1 mr-2 text-success"
              size="1x"
              name="pen-to-square"
              onClick={this.editContent}
              style={{ cursor: 'pointer' }}
            />
            {metric?.id && (
              <>
                <Icon
                  className="m-1 ml-2 text-danger"
                  size="1x"
                  name="trash"
                  onClick={this.removeCell}
                  style={{ cursor: 'pointer' }}
                />
                <Icon
                  className="m-1 ml-2 text-primary"
                  size="1x"
                  name="eye"
                  onClick={this.viewMetric}
                  style={{ cursor: 'pointer' }}
                />
              </>
            )}
          </div>
        )}
        {this.state.fetching && (
          <div className="d-flex justify-content-center">
            <HalfCircleSpinner size={25} color={hasFiltersChanged ? '#fbbc05' : 'green'} />
          </div>
        )}
        {!this.state.fetching && (
          <>
            <div
              className={`d-flex ${
                hasFiltersChanged ? 'justify-content-between' : 'justify-content-end'
              } align-items-center mx-2`}
              tabIndex={0}
              role="button"
              onClick={metricViewer.onClick}
              onKeyDown={handleEnter(metricViewer.onClick)}
              title={hoverTitle}
              style={metricViewer.style}
            >
              {hasFiltersChanged && (
                <Icon
                  name="triangle-exclamation-solid"
                  className="text-warning"
                  title="Apply filters to update metric value"
                />
              )}
              {value}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ enterprises, report_preview, reports }) => ({
  enterprises,
  report_preview,
  reports,
});

export default connect(mapStoreToProps)(ReportCell);
