import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    animal_classes: [],
    values: [],
  },
  action
) {
  // Set expected reducer types
  const types = ['ANIMAL_CLASS'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ANIMAL_CLASSES_PENDING': {
      return {
        ...state,
        animal_classes: [],
      };
    }
    case 'FETCH_ANIMAL_CLASSES_FULFILLED': {
      return {
        ...state,
        animal_classes: action.payload.rows,
      };
    }
    case 'FETCH_ANIMAL_CLASS_VALUES_FULFILLED': {
      return {
        ...state,
        values: action.payload.rows,
      };
    }
    default:
      break;
  }

  return state;
}
