import * as React from 'react';
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { FormGroup, Label, Col } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty, isNil, omit } from 'lodash';
import moment from 'moment';
import Select from 'react-select';

import { Control, useCarbonizerForm } from '.';
import FormInputSwitch from '../FormInputSwitch';

const reservedNameWords = ['name', 'initialValue', 'validationRules', 'options'];

/**
 * Renders an input based on the control type
 * Future iterations could take an optional schema to define layout
 */
export const RenderInput = ({
  control,
  disabled,
  uniqueKey,
}: {
  control: Control;
  disabled?: boolean;
  uniqueKey?: string;
}) => {
  const {
    control: useFormContextControl,
    register,
    formState: { errors },
  } = useFormContext();
  const { name, validationRules, caption } = control;
  let { value = '' } = control;

  if (reservedNameWords.includes(control.name)) {
    console.warn(
      `RenderInput: Control name "${control.name}" is a reserved word and cannot be used`,
    );
  }

  if (control?.modal?.show === false) return null;

  let input = null;
  // The core components scaffold the labels and inputs, so exit early when we can use one of them
  switch (control.type) {
    case 'multiselect':
      return (
        <Controller
          defaultValue={control.value}
          control={useFormContextControl}
          name={control.name}
          render={({ field: { onChange, value: fieldValue, ref } }) => (
            <FormGroup row className="mt-0 mb-0">
              {control.caption && (
                <Label for={control.name} sm={12} className="font-weight-bold">
                  {control.caption}
                </Label>
              )}
              <Col sm={12}>
                <Select
                  defaultValue={control.value}
                  value={fieldValue}
                  isMulti
                  placeholder="Select properties to filter"
                  name={control.name}
                  options={control.options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  ref={ref}
                />
              </Col>
            </FormGroup>
          )}
        />
      );
    case 'switch':
      return (
        <Controller
          defaultValue={control.value}
          control={useFormContextControl}
          name={control.name}
          render={({ field: { onChange, value: fieldValue, ref } }) => (
            <FormInputSwitch
              groupClassName={undefined}
              handleChange={onChange}
              inputRef={ref}
              control={{
                ...omit(control, ['switchLabel']),
                value: fieldValue,
                descriptionStatus: {
                  switchOn: control.switchLabel.enabled,
                  switchOff: control.switchLabel.disabled,
                },
              }}
            />
          )}
        />
      );
    default:
      input = null;
  }

  switch (control.type) {
    case 'select':
      input = (
        <select
          disabled={disabled}
          className="form-control"
          defaultValue={control.value}
          {...register(name as never, { ...validationRules })}
        >
          {control.options.map((option) => (
            <option key={option.name} value={option.value} disabled={option?.disabled}>
              {option.label || option.name}
            </option>
          ))}
        </select>
      );
      break;
    case 'hidden':
      input = (
        <input
          type="hidden"
          className="form-control"
          defaultValue={control.value}
          {...register(name, { ...validationRules })}
          disabled={disabled}
        />
      );
      break;
    case 'date':
      let { value: defaultValue = '' } = control;

      defaultValue = moment(defaultValue).format('YYYY-MM-DD');
      input = (
        <Controller
          control={useFormContextControl}
          defaultValue={defaultValue}
          name={control.name}
          render={({ field: { value } }) => {
            const dateValue = moment(value).format('YYYY-MM-DD');
            return (
              <input
                className="form-control"
                type="date"
                value={dateValue}
                {...register(name, { ...validationRules })}
              />
            );
          }
          }
        />
      );
      break;
    case 'checkbox':
      input = (
        <input
          className="form-control"
          defaultChecked={control.value}
          {...register(name, { ...validationRules })}
          type={control.type}
        />
      );
      break;
    case 'text':
    default:
      input = (
        <input
          className="form-control"
          defaultValue={control.value}
          {...register(name, { ...validationRules })}
          type={control.type}
          disabled={disabled}
        />
      );
      break;
  }

  // Don't print labels etc for hidden inputs
  if (control.type === 'hidden') return input;

  return (
    <FormGroup row className="mt-0 mb-0" key={uniqueKey}>
      {caption && (
        <Label for={name} sm={12} className="font-weight-bold">
          {caption}
        </Label>
      )}
      <Col sm={12}>
        <div className="d-flex">{input}</div>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <span className="text-danger">{message}</span>}
        />
      </Col>
    </FormGroup>
  );
};

/**
 * Renders a list of inputs based on the controls
 */
export const RenderInputs = () => {
  const { controls } = useCarbonizerForm();
  if (isEmpty(controls)) return null;

  const inputs = Object.keys(controls).map((controlKey) => {
    const control = controls[controlKey];

    return <RenderInput key={control.name} control={control} uniqueKey={control.name} />;
  });

  return <>{inputs}</>;
};

export interface RenderFormProps {
  onSubmit?: (data: FieldValues) => SubmitHandler<FieldValues> | null;
  onInvalid?: (data: FieldValues) => SubmitHandler<FieldValues> | null;
}
/**
 * Renders a form based on the controls
 */
export const RenderForm = ({ onSubmit = () => null, onInvalid = () => null }: RenderFormProps) => {
  const { handleSubmit } = useFormContext();

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
      <RenderInputs />
      <button className="btn btn-primary" type="submit">
        Submit
      </button>
    </form>
  );
};
