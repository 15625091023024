import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const LivestockEnterpriseMenu = ({
  setDirectCostModal = noop,
  setLivestockIncomeModal = noop,
  setLivestockTransactionModal = noop,
  setLivestockOpeningModal = noop,
  setLivestockAdjustmentModal = noop,
  setLivestockBreedingModal = noop,
  setTransferModal = noop,
  setLivestockValueModal = noop,
}) => (
  <div>
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="success" size="sm">
        <Icon name="plus" className="mr-1" />
        Action
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockTransactionModal(true)}
        >
          Add Sale/Purchase
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockAdjustmentModal(true)}
        >
          Add Adjustment
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockOpeningModal(true)}
        >
          Add Opening Value
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockBreedingModal(true)}
        >
          Add Breeding
        </DropdownItem>
        <DropdownItem toggle={true} onClick={() => setTransferModal(true)}>
          Add Transfer
        </DropdownItem>
        <DropdownItem toggle={true} onClick={() => setDirectCostModal(true)}>
          Add Direct Cost
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockIncomeModal(true)}
        >
          Add Other Income
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setLivestockValueModal(true)}
        >
          Add Value
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  </div>
);

export default LivestockEnterpriseMenu;
