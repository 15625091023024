import { useEffect, useRef, useState, RefObject, useLayoutEffect } from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

export function useClickOutside(ref: RefObject<HTMLElement>, onClickOutside = noop) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref?.current?.contains(event.target as HTMLElement)) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * Hook to call a function at the supplied interval
 * @param {*} callback Function to call
 * @param {*} delay Time in milliseconds
 */
export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useLayoutEffect(() => {
    if (!delay && delay !== 0) return;

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(id);
    };
  }, [delay, savedCallback]);
};

type DeviceType = {
  andriod: boolean;
  windows: boolean;
  ios: boolean;
  unknown: boolean;
};
export const useDeviceType = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>({
    andriod: false,
    windows: false,
    ios: false,
    unknown: false,
  });

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/(android)/i.test(userAgent)) {
      setDeviceType({ ...deviceType, andriod: true });
      return;
    }
    if (/iphone|ipad|ipod/i.test(userAgent)) {
      setDeviceType({ ...deviceType, ios: true });
      return;
    }
    if (/windows phone/i.test(userAgent)) {
      setDeviceType({ ...deviceType, windows: true });
      return;
    }
    setDeviceType({ ...deviceType, unknown: true });
  }, [deviceType]);

  return deviceType;
};

export const useCheckAccess = (featureName: string) => {
  const { features = [] } = useSelector((state: any) => state.realm.currentApp);

  return features.includes(featureName);
};
