import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Round Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
  },
  selections_seed: {
    ...controlDefaults,
    placeholder: '',
    name: 'selections_seed',
    type: 'number',
    caption: 'Selections Seed',
    description: 'Seed value for subsample and gravel selection script',
    validationRules: {
      minLength: 3,
      isRequired: true,
    },
  },
  selections_count: {
    ...controlDefaults,
    placeholder: '',
    name: 'selections_count',
    type: 'number',
    caption: 'Selections Required',
    description: 'Minimum required selections for subsample selection script',
    validationRules: {
      minLength: 1,
      isRequired: true,
    },
  },
  backup_selections_count: {
    ...controlDefaults,
    placeholder: '',
    name: 'backup_selections_count',
    type: 'number',
    caption: 'Backup Selections Required',
    description: 'Minimum required backup selections for subsample selection script',
    validationRules: {
      minLength: 1,
      isRequired: true,
    },
  },
  sites_seed: {
    ...controlDefaults,
    placeholder: '',
    name: 'sites_seed',
    type: 'number',
    caption: 'Sites Seed',
    description: 'Seed value for stratification and site selection',
    validationRules: {
      minLength: 3,
      isRequired: true,
    },
  },
  soc_nir_round_type: {
    ...controlDefaults,
    placeholder: '',
    name: 'soc_nir_round_type',
    type: 'select',
    caption: 'SOC NIR Round Type',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
  },
  subsample_script_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'subsample_script_id',
    type: 'select',
    caption: 'Subsample NIR Round Type',
    validationRules: {
      isRequired: true,
    },
  },
  augments_count: {
    ...controlDefaults,
    placeholder: '',
    name: 'augments_count',
    type: 'number',
    description: 'Minimum required augment selections for subsample selection scripts',
    caption: 'Augment Samples Required',
    defaultValue: 10,
    validationRules: {
      minLength: 1,
      isRequired: true,
    },
  },
  override_mga_srid: {
    ...controlDefaults,
    placeholder: '',
    name: 'override_mga_srid',
    type: 'select',
    caption: 'Override MGA Zone',
    description: 'Only set this if you would the MGA zone to not be determined automatically',
    options: [
      { id: 28349, name: 'MGA Zone 49' },
      { id: 28350, name: 'MGA Zone 50' },
      { id: 28351, name: 'MGA Zone 51' },
      { id: 28352, name: 'MGA Zone 52' },
      { id: 28353, name: 'MGA Zone 53' },
      { id: 28354, name: 'MGA Zone 54' },
      { id: 28355, name: 'MGA Zone 55' },
      { id: 28356, name: 'MGA Zone 56' },
    ],
    validationRules: {},
  },
  subsample_type: {
    ...controlDefaults,
    placeholder: '',
    name: 'subsample_type',
    type: 'select',
    caption: 'Subsample Type',
    description: '',
    options: [
      { id: 'area_round', name: 'CEA' },
      { id: 'property_round', name: 'Round' },
    ],
    validationRules: {},
  },
  data_preparation_id: {
    ...controlDefaults,
    placeholder: 'Select Data Preparation',
    name: 'data_preparation_id',
    type: 'select',
    caption: 'Data Preparation Method',
    validationRules: {
      minLength: 6,
      isRequired: true,
    },
  },
  tonnes_biochar: {
    ...controlDefaults,
    placeholder: '',
    name: 'tonnes_biochar',
    type: 'text',
    caption: 'Tonnes BioChar',
    description: 'Tonnes of Bio Char applied (t)',
    validationRules: {
      isRequired: false,
    },
  },
  previous_round_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'previous_round_id',
    type: 'select',
    caption: 'Previous Round',
    description: 'Select the previous round',
    validationRules: {
      isRequired: false,
    },
  },
};
