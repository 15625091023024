import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
const PrivateRoute = ({ route, requestedPath, ...rest }) => {
  const { authenticated } = useSelector(({ auth }) => auth);
  const { pathname } = useLocation();
  if (!authenticated && route.authRequired) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: pathname },
        }}
      />
    );
  }

  return <Route {...rest} />;
};

export default PrivateRoute;
