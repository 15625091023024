import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { initControls } from 'jsx/components/core/form/lib/validateForm';
import Icon from 'jsx/components/core/icons/Icon';
import { getFormattedValue } from 'jsx/components/core/form/lib/metricFormat';
import FormBase from '../../../core/form/components/FormBase';
import Tree from '../../../core/form/components/Tree';
import WidgetGauge from '../components/WidgetGauge';
import WidgetTile from '../components/WidgetTile';
import { controls as metricControls } from '../forms/metrics';
import { fetchReportMetric } from '../actions/reports';

class MetricModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(metricControls),
      id: null,
      metric: {},
      subMetrics: [],
      title: 'Metric',
    };

    this.onCancel = this.onCancel.bind(this);
    this.loadSubMetrics = this.loadSubMetrics.bind(this);
    this.renderWidget = this.renderWidget.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { id, metric } = this.props;

      const subMetrics = await this.loadSubMetrics(id);

      this.setState({
        controls: initControls(cloneDeep(metricControls)),
        id,
        metric,
        subMetrics,
        title: metric?.name,
      });
    }
  }

  onCancel() {
    this.props.setModal(false);
  }

  async loadSubMetrics(parent_id) {
    const { from_date, to_date } = this.props.enterprises.selectedRanges;
    const { selectedProperties: property_ids = [] } = this.props.enterprises;
    const { divisions: division_ids = [], enterprises: enterprise_ids = [] } =
      this.props.reports.filters;
    const defaultOrgId = this.props.profile.currentUser?.user_orgs[0]?.org?.id;

    const org_id = this.props?.orgId ? this.props.orgId : defaultOrgId;
    const metric = await this.props.dispatch(
      fetchReportMetric({
        id: parent_id,
        from_date,
        to_date,
        org_id,
        property_ids,
        division_ids,
        enterprise_ids,
      }),
    );

    const subMetrics = metric.subMetrics.map((subMetric) => {
      const { formattedValue } = getFormattedValue(subMetric);

      return {
        ...subMetric,
        value: formattedValue,
      };
    });

    return subMetrics;
  }

  renderWidget() {
    const { metric } = this.state;
    const { hasFiltersChanged } = this.props.reports;

    switch (metric.default_widget_tag) {
      case 'gauge':
        return (
          <WidgetGauge
            metric={metric}
            colSize={4}
            noHeader
            displayResyncWarning={hasFiltersChanged}
          />
        );
      case 'tile':
      default:
        return (
          <WidgetTile
            displayResyncWarning={hasFiltersChanged}
            metric={metric}
            colSize={4}
            noHeader
          />
        );
    }
  }

  render() {
    const { metric, subMetrics, title } = this.state;

    const { isOpen } = this.props;
    const { responseMessage } = this.props.office;
    const iconName = 'gauge-circle-bolt';

    return (
      <Modal isOpen={isOpen} size="xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}

          <Row>
            {this.renderWidget()}
            <Col sm={8}>
              <h3>{metric.name}</h3>
              <p>{metric.description}</p>

              <div className="bg-light border-bottom border-secondary p-1">Formula</div>
              <div>{metric.formulaDescription}</div>
            </Col>
          </Row>

          <Row>
            <Col sm={4}></Col> {/* Empty column to align text */}
            <Col>
              <div className="bg-light border-bottom border-secondary p-1 mt-2">Sub-Metrics</div>
              <small className="text-danger">
                Red icons represent functions which can not be drilled down in. Blue icons are
                nested and can be opened for more detail and formula descriptions.
              </small>
              <Tree items={subMetrics} load={this.loadSubMetrics} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ enterprises, office, profile, reports }) => ({
  enterprises,
  office,
  profile,
  reports,
});

export default connect(mapStoreToProps)(MetricModal);
