import React, { useCallback, useState } from 'react';
import { Label, Button, Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import Icon from 'jsx/components/core/icons/Icon';
import { GenericFormModal } from 'jsx/components/core/form/components/GenericFormModal';
import FormInput from '../../core/form/components/FormInputSmall';

const createVersionControls = {
  version: {
    name: 'version',
    type: 'select',
    caption: 'Start report with version',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
};

const EMPTY_VALUE = '-';
const EMPTY_DISPLAY = 'Empty report';
const emptyReportOption = { id: null, value: EMPTY_VALUE, name: EMPTY_DISPLAY };

const ReportToolbar = (props) => {
  const {
    handleVersionChange,
    handleReportEditableChange,
    controls,
    createVersion,
    reportEditable,
    loadReportVersion,
    publishReportVersion,
    versionOptions,
  } = props;

  const [createVersionModalVisible, setCreateVersionModalVisible] = useState(false);
  const prepareNewVersion = useCallback(() => {
    setCreateVersionModalVisible(true);
  }, [setCreateVersionModalVisible]);

  const createReportWithInput = useCallback(
    ({ version }) => {
      createVersion({ clone_from: version === EMPTY_VALUE ? undefined : version });
      setCreateVersionModalVisible(false);
    },
    [setCreateVersionModalVisible, createVersion],
  );

  const closeModal = useCallback(() => {
    setCreateVersionModalVisible(false);
  }, [setCreateVersionModalVisible]);

  createVersionControls.version.options = [emptyReportOption].concat(
    versionOptions.map(({ id, name }) => ({ id, value: id, name })),
  );

  return (
    <Row className="bg-light rounded p-1 m-1 border border-lightgray">
      <GenericFormModal
        isOpen={createVersionModalVisible}
        controls={createVersionControls}
        onSubmit={createReportWithInput}
        onCancel={closeModal}
        title="Create New Version"
      />
      <Col sm={12} className="d-flex justify-content-end align-items-center">
        {controls.version.value && (
          <div className="d-flex align-items-center mr-2">
            <Label className="mt-2 mr-1">Editable:</Label>
            <Switch
              height={18}
              width={42}
              onChange={(checked) => handleReportEditableChange(checked)}
              checked={reportEditable}
              disabled={!controls.version.value}
            />
          </div>
        )}
        <div className="d-flex align-items-center ml-2">
          <Label className="mt-2 mr-1 text-center">Version:</Label>
          <FormInput
            handleChange={handleVersionChange}
            control={controls.version}
            hideCaption={true}
          />
        </div>

        <Button color="success" size="sm" onClick={prepareNewVersion} className="ml-2">
          Create New Version
        </Button>

        {controls.version.value && (
          <>
            <Button
              color="primary"
              size="sm"
              onClick={publishReportVersion}
              className="ml-2"
              disabled={false}
            >
              Publish Version
            </Button>
            <Button color="success" size="sm" onClick={loadReportVersion} className="ml-2">
              <Icon size="1x" name="rotate-right" />
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default ReportToolbar;
