import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Alert } from 'reactstrap';
import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import LivestockTransactionModal from './LivestockTransactionModal';
import LivestockEnterprises from './LivestockEnterprises';
import LivestockOpeningModal from '../containers/LivestockOpeningModal';
import Charts from '../components/LivestockEnterpriseCharts';
import Toolbar from '../components/LivestockEnterpriseToolbar';
import GenericLsv from '../../../core/form/components/GenericLsv';
import GenericModal from '../../../core/form/components/GenericModal';
import DirectCostModal from './DirectCostModal';
import LivestockIncomeModal from './LivestockIncomeModal';
import LivestockAdjustmentModal from './LivestockAdjustmentModal.jsx';
import LivestockBreedingModal from './LivestockBreedingModal';
import LivestockValueModal from './LivestockValueModal';
import SheepEnterpriseStockflowLsv from '../components/SheepEnterpriseStockflowLsv';
import { fetchAttribute } from '../actions/attributes';
import ExternalLink from '../components/ExternalLink';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import TransferModal from './TransferModal';
import LivestockPurchasesLsv from './LivestockPurchasesLsv';
class SheepEnterprises extends LivestockEnterprises {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
  }

  async componentDidMount() {
    // Set sheep division type for later attribute fetches
    const { id: division_id } = await this.props.dispatch(
      fetchAttribute({ type: 'divisions', tag: 'sheep' })
    );
    this.setState({ division_id });
  }

  async componentDidUpdate(prevProps) {
    const { activeTab: prevEnterprisesActiveTab } = prevProps.enterprises;
    const { activeTab: enterprisesActiveTab } = this.props.enterprises;
    if (
      prevEnterprisesActiveTab !== 'sheep' &&
      enterprisesActiveTab === 'sheep'
    ) {
      const { id: division_id } = await this.props.dispatch(
        fetchAttribute({ type: 'divisions', tag: 'sheep' })
      );
      this.setState({ division_id });
    }

    super.componentDidUpdate(prevProps);
  }

  loadDivision() {
    super.loadDivision('sheep');
  }

  onRefresh() {
    const { activeSheepTab: tab_id } = this.props.livestocks;
    if (tab_id) this.toggleTab(tab_id);
  }

  setModalOptions(type) {
    let options = super.setModalOptions(type);
    options.title = `Sheep ${options.title}`;

    // Set breeding control captions
    if (options.title === 'Sheep Breeding') {
      options = {
        ...options,
        controls: {
          ...options.controls,
          bought_pregnant_quantity: {
            ...options.controls.bought_pregnant_quantity,
            caption: '# Pregnant Ewes Bought',
          },
          branded_pregnant_quantity: {
            ...options.controls.branded_pregnant_quantity,
            caption: '# Pregnant Ewes Bought',
            group: 'marking',
          },
          breeding_period_id: {
            ...options.controls.breeding_period_id,
            caption: 'Lambing Period',
            group: 'lambing',
          },
          breeding_date: {
            ...options.controls.breeding_date,
            caption: 'Lambing Date',
            group: 'lambing',
          },
          branded_own_quantity: {
            ...options.controls.branded_own_quantity,
            caption: '# Marked from Own',
            group: 'marking',
          },
        },
      };
    }

    return options;
  }

  toggleTab(tab) {
    super.toggleTab(tab);

    if (this.props.livestocks.activeSheepTab !== tab) {
      this.props.dispatch({
        type: 'SET_LIVESTOCK_SHEEP_ACTIVE_TAB',
        payload: tab,
      });
    }
  }

  render() {
    const {
      id,
      modalType,
      isModalOpen,
      isDirectCostModalOpen,
      isLivestockIncomeModalOpen,
      isLivestockTransactionModalOpen,
      isLivestockOpeningModalOpen,
      isLivestockAdjustmentModalOpen,
      isLivestockBreedingModalOpen,
      isTransferModalOpen,
      isLivestockValueModalOpen,
      modalData,
      division_id,
    } = this.state;

    const {
      activeSheepTab,
      purchases,
      sales,
      adjustments,
      breedings,
      transfers,
      stockflows,
      livestock_incomes,
      responseMessage,
    } = this.props.livestocks;

    const { selectedRanges } = this.props.enterprises;
    const { direct_costs } = this.props.direct_costs;
    const { values } = this.props.animal_classes;

    const modalOptions = this.setModalOptions(modalType);
    const title = 'Sheep Enterprises';
    const iconName = 'sheep';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} iconName={iconName} />
        <Charts />
        <Toolbar
          setModal={this.setModal}
          onRefresh={this.onRefresh}
          setDirectCostModal={this.setDirectCostModal}
          setLivestockIncomeModal={this.setLivestockIncomeModal}
          setLivestockTransactionModal={this.setLivestockTransactionModal}
          setLivestockOpeningModal={this.setLivestockOpeningModal}
          setLivestockAdjustmentModal={this.setLivestockAdjustmentModal}
          setLivestockBreedingModal={this.setLivestockBreedingModal}
          setTransferModal={this.setTransferModal}
          setLivestockValueModal={this.setLivestockValueModal}
        />
        <DirectCostModal
          onRefresh={this.onRefresh}
          setModal={this.setDirectCostModal}
          id={id}
          isOpen={isDirectCostModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <LivestockOpeningModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockOpeningModal}
          id={id}
          isOpen={isLivestockOpeningModalOpen}
          division_id={division_id}
        />
        <LivestockAdjustmentModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockAdjustmentModal}
          data={modalData}
          isOpen={isLivestockAdjustmentModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
        />
        <LivestockIncomeModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockIncomeModal}
          id={id}
          isOpen={isLivestockIncomeModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <LivestockTransactionModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockTransactionModal}
          data={modalData}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          onRefresh={this.onRefresh}
          id={id}
          isOpen={isLivestockTransactionModalOpen}
          isNew={id === null}
          responseMessage={responseMessage}
        />
        <LivestockValueModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockValueModal}
          data={modalData}
          isOpen={isLivestockValueModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          onRefresh={this.onRefresh}
          id={id}
          isNew={id === null}
          responseMessage={responseMessage}
        />
        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <LivestockBreedingModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockBreedingModal}
          data={modalData}
          isOpen={isLivestockBreedingModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <TransferModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setTransferModal}
          data={modalData}
          isOpen={isTransferModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Stockflow"
            tabId="1"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="stockflow"
          />
          <FormTab
            caption="Values"
            tabId="2"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            disabled={false}
            tabTag="values"
          />
          <FormTab
            caption="Purchases"
            tabId="3"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="purchases"
          />
          <FormTab
            caption="Sales"
            tabId="4"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="sales"
          />
          <FormTab
            caption="Adjustments"
            tabId="5"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="adjustments"
          />
          <FormTab
            caption="Breeding Statistics"
            tabId="6"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="breeding"
          />
          <FormTab
            caption="Transfers"
            tabId="7"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="transfers"
          />
          <FormTab
            caption="Direct Costs"
            tabId="8"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="costs"
          />
          <FormTab
            caption="Other Income"
            tabId="9"
            activeTab={activeSheepTab}
            toggle={this.toggleTab}
            tabTag="livestock_incomes"
          />
        </Nav>

        <TabContent activeTab={activeSheepTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <ExternalLink
              divClasses={'m-2'}
              hasIcon={true}
              href={'https://aucalc.rcsaustralia.com.au/sheep'}
              name="calculator"
              iconClasses={'mr-2 text-corporate'}
              title={'AU Rating Calculator'}
            />
            <SheepEnterpriseStockflowLsv
              rows={stockflows}
              onValuationChange={this.onValuationChange}
              selectedRanges={selectedRanges}
              onRIChange={this.onRIChange}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setLivestockValueModal(true, id);
              }}
              rows={values}
            />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow
              page. You need to enter the enterprises and animal classes that
              are used on the stockflow page. Be consistent with enterprises and
              animal classes used on all sheep input tabs.
            </Alert>
            <LivestockPurchasesLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sheep purchases found"
              onClick={(id) => {
                this.setLivestockTransactionModal(true, id);
              }}
              rows={purchases}
            />
          </TabPane>
          <TabPane tabId="4" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow
              page. You need to enter the enterprises and animal classes that
              are used on the stockflow page. Be consistent with enterprises and
              animal classes used on all sheep input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sheep sales found"
              onClick={(id) => {
                this.setLivestockTransactionModal(true, id);
              }}
              rows={sales}
            />
          </TabPane>
          <TabPane tabId="5" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow
              page. You need to enter the enterprises and animal classes that
              are used on the stockflow page. Be consistent with enterprises and
              animal classes used on all sheep input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sheep adjustments found"
              onClick={(id) => {
                this.setLivestockAdjustmentModal(true, id);
              }}
              rows={adjustments}
            />
          </TabPane>
          <TabPane tabId="6" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sheep breedings found"
              onClick={(id) => {
                this.setLivestockBreedingModal(true, id);
              }}
              rows={breedings}
            />
          </TabPane>
          <TabPane tabId="7" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sheep transfers found"
              onClick={(id) => {
                this.setTransferModal(true, id);
              }}
              rows={transfers}
            />
          </TabPane>
          <TabPane tabId="8" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No direct costs found"
              onClick={(id) => {
                this.setDirectCostModal(true, id);
              }}
              rows={direct_costs}
              totalFormattingRules={{
                includeDollarSign: true,
                includeCommas: true,
              }}
            />
          </TabPane>
          <TabPane tabId="9" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other income found"
              onClick={(id) => {
                this.setLivestockIncomeModal(true, id);
              }}
              rows={livestock_incomes}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  livestocks,
  realm,
}) => ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  livestocks,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(SheepEnterprises));
