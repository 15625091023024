import React from 'react';

import { Alert } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';

const LoginFooter = (props) => {
  return (
    <div>
      <Alert color="danger">
        <Icon color="warning" size="3x" name="shield-check" className="m-3" />
        <div>
          Warning: Use of this system is restricted to individuals and activities authorized by
          management. Unauthorized use may result in appropriate disciplinary action and/or legal
          prosecution.
        </div>
      </Alert>

      <Alert>
        <Icon color="primary" name="chrome" />
        <div>
          Please note this application has only been tested with Google Chrome. It may work with
          other browsers but has not yet been tested.
        </div>
      </Alert>
    </div>
  );
};

export default LoginFooter;
