import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as incomeControls } from '../forms/livestockIncome';

import {
  createLivestockIncome,
  fetchLivestockIncome,
  removeLivestockIncome,
  updateLivestockIncome,
} from '../actions/livestocks';
import { fetchEnterpriseDistributions } from '../actions/enterprises';
import LivestockIncomeDistributionsLsv from '../components/LivestockIncomeDistributionsLsv';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';
import ResponseMessageTab from '../../../core/form/components/ResponseMessageTab';

class LivestockIncomeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(incomeControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Livestock Other Income',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDistributionChange = this.onDistributionChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { id, division_id } = this.props;
      let controls = initControls(cloneDeep(incomeControls));
      controls.distributions.value = [];

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Livestock Other Income',
      };

      this.props.dispatch(fetchEnterpriseDistributions({ type: 'enterprise', division_id }));

      if (id) {
        updatedState = {
          ...updatedState,
          id,
          isNew: false,
          title: 'Edit Livestock Other Income',
        };

        this.props.dispatch(fetchLivestockIncome({ id, division_id })).then((data) => {
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    data = saveControls(controls, data);
    // Convert distribution_pcnt into decimal form
    data.distributions = controls.distributions.value.map((distribution) => {
      const distribution_pcnt = distribution.distribution_pcnt / 100;
      return {
        ...distribution,
        distribution_pcnt,
      };
    });

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    // All form fields are valid
    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createLivestockIncome(data));
      } else {
        success = await this.props.dispatch(updateLivestockIncome(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_LIVESTOCK_INCOME_ATTRIBUTES' });
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_DISTRIBUTIONS' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing other income permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeLivestockIncome(data.id));
      if (success) this.onClose(true);
    }
  }

  onDistributionChange() {
    let { controls } = this.state;

    if (controls?.distributions?.value?.length > 0) {
      // Handle updating
      const totalValue = controls.distributions.value
        .map((distribution) => {
          return distribution.distribution_amount
            ? parseFloat(distribution.distribution_amount)
            : 0;
        })
        .reduce((a, b) => a + b);

      if (controls.value.value !== totalValue) {
        controls.value.value = totalValue;
        this.setState({
          controls,
        });
      }
    }
  }

  render() {
    let { controls, title, isNew } = this.state;
    const { isOpen } = this.props;
    const { transaction_intervals } = this.props.attributes;
    const { distributions } = this.props.enterprises;
    const { responseMessage } = this.props.livestocks;
    const iconName = 'clipboard-list';

    // Add default distribution values if no values are populated
    if (controls.distributions.value === null || controls.distributions.value?.length === 0)
      controls.distributions.value = distributions;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.category} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={transaction_intervals}
              intervalKey={'transaction_interval_id'}
              dateKey={'transaction_date'}
            />
            <FormInput handleChange={this.handleChange} control={controls.value} />
          </Form>
          <LivestockIncomeDistributionsLsv
            rows={controls.distributions.value}
            totalValue={controls.value.value ?? 0}
            onDistributionChange={this.onDistributionChange}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    attributes: store.attributes,
    enterprises: store.enterprises,
    livestocks: store.livestocks,
  };
};

export default connect(mapStoreToProps)(LivestockIncomeModal);
