import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

import FormBase from 'jsx/components/core/form/components/FormBase';

class ReportMatrix extends FormBase {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderValueCols = (cols, colSpan = 1, align = 'right') => {
    const tdStyle = { borderBottom: 'none', border: '1px solid gray', textAlign: align };
    const columns = [];
    for (let col = 0; col < cols; col += 1) {
      columns.push(
        <td key={col} colSpan={colSpan} style={tdStyle}>
          ##
        </td>,
      );
    }

    return columns;
  };

  render() {
    const thStyle = { borderBottom: 'none', border: '1px solid gray' };
    const labelStyle = { width: 250, border: '1px solid gray' };
    const prefixStyle = { width: 10, border: '1px solid gray', textAlign: 'center' };
    const cols = 11;

    return (
      <>
        <Table className="bg-light mt-2">
          <thead>
            <tr>
              <th style={{ ...labelStyle, border: 'none' }} colSpan={3}></th>
              <th colSpan={cols} className="text-center bg-lightgray" style={thStyle}>
                ZYZ Operations
              </th>
            </tr>
            <tr>
              <th style={{ ...labelStyle, border: 'none' }} colSpan={3}></th>
              <th colSpan={7} className="text-center bg-light" style={thStyle}>
                Property 1
              </th>
              <th colSpan={4} className="text-center bg-light" style={thStyle}>
                Property 2
              </th>
            </tr>
            <tr>
              <th style={{ ...labelStyle, border: 'none' }} colSpan={3}></th>
              <th colSpan={4} className="text-center bg-lightgray" style={thStyle}>
                Cattle
              </th>
              <th colSpan={3} className="text-center bg-lightgray" style={thStyle}>
                Cropping
              </th>
              <th colSpan={4} className="text-center bg-lightgray" style={thStyle}>
                Sheep
              </th>
            </tr>
            <tr>
              <th style={labelStyle} className="bg-lightgray" colSpan={3}>
                Flow of Funds
              </th>
              <th className="text-center bg-orange" style={thStyle}>
                1a
              </th>
              <th className="text-center bg-orange" style={thStyle}>
                1b
              </th>
              <th className="text-center bg-orange" style={thStyle}>
                1c
              </th>
              <th className="text-center bg-orange" style={thStyle}>
                1d
              </th>
              <th className="text-center bg-pink" style={thStyle}>
                2a
              </th>
              <th className="text-center bg-pink" style={thStyle}>
                2b
              </th>
              <th className="text-center bg-pink" style={thStyle}>
                2c
              </th>
              <th className="text-center bg-success" style={thStyle}>
                3a
              </th>
              <th className="text-center bg-success" style={thStyle}>
                3b
              </th>
              <th className="text-center bg-success" style={thStyle}>
                3c
              </th>
              <th className="text-center bg-success" style={thStyle}>
                3d
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={prefixStyle}>A</td>
              <td style={prefixStyle}></td>
              <td style={labelStyle}>Sales/income</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Internal Sales (non-cash)</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Closing inventory value</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>=</td>
              <td
                style={{
                  ...labelStyle,
                  borderTop: '2px solid black',
                  backgroundColor: 'lightgray',
                }}
              >
                = Gross Income
              </td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}>B</td>
              <td style={prefixStyle}></td>
              <td style={labelStyle}>Purchases</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Internal purchases - (non-cash)</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Opening inventory value</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}>B</td>
              <td style={prefixStyle}>=</td>
              <td
                style={{
                  ...labelStyle,
                  borderTop: '2px solid black',
                  backgroundColor: 'lightgray',
                }}
              >
                Cost of Sale
              </td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}>C</td>
              <td style={prefixStyle}>A+B=</td>
              <td
                style={{
                  ...labelStyle,
                  borderTop: '2px solid black',
                  backgroundColor: 'lightgray',
                }}
              >
                Gross Product
              </td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}></td>
              <td style={labelStyle}>Cash Direct Cost</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Non-cash Direct Cost</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Opportunity cost (non-cash)</td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}>D</td>
              <td style={prefixStyle}>=</td>
              <td
                style={{
                  ...labelStyle,
                  borderTop: '2px solid black',
                  backgroundColor: 'lightgray',
                }}
              >
                Total Direct Cost
              </td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}>E</td>
              <td style={prefixStyle}>C-D=</td>
              <td
                style={{
                  ...labelStyle,
                  borderTop: '2px solid black',
                  backgroundColor: 'lightgray',
                }}
              >
                Gross Margin
              </td>
              {this.renderValueCols(cols)}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Divisional Cash Overheads</td>
              {this.renderValueCols(1, 4, 'center')}
              {this.renderValueCols(1, 3, 'center')}
              {this.renderValueCols(1, 4, 'center')}
            </tr>
            <tr>
              <td style={prefixStyle}></td>
              <td style={prefixStyle}>+</td>
              <td style={labelStyle}>Divisional Non-Cash Overheads</td>
              {this.renderValueCols(1, 4, 'center')}
              {this.renderValueCols(1, 3, 'center')}
              {this.renderValueCols(1, 4, 'center')}
            </tr>
          </tbody>
        </Table>
      </>
    );
  }
}

const mapStoreToProps = ({ manage, farmportrait_portal, property_usages }) => ({
  manage,
  portal: farmportrait_portal,
  property_usages,
});

export default connect(mapStoreToProps)(ReportMatrix);
