import { allowed, defaults } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    authenticated: false,
    authenticationType: 'local',
    associated: false,
    currentId: null,
    error: null,
    fetching: false,
    fetched: false,
    isMasquerade: false,
    loginMessage: '',
    registered: false,
    registerMessage: '',
    resetMessage: null,
    responseMessage: null,
    responseMessageColour: 'text-danger',
    verifyMessage: null,
    verified: false,
  },
  action
) {
  // Set expected reducer types
  const types = [
    'AUTHENTICATION',
    'AUTHENTICATED',
    'FORGOT',
    'INVITE',
    'MODE',
    'LOGIN',
    'LOGOUT',
    'MASQUERADE',
    'MASQUERADE',
    'PASSWORD',
    'REALM',
    'REGISTRATION',
    'TOKEN',
    'USER',
    'VERIFY',
  ];

  if (!allowed(types, action)) {
    return state;
  }

  // Set defaults
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'GENERATE_PASSWORD_RESET_LINK_FULFILLED': {
      return {
        ...state,
        responseMessage: action.payload,
        responseMessageColour: 'text-success',
      };
    }
    case 'SET_LOGIN_MESSAGE': {
      return {
        ...state,
        loginMessage: action.payload,
      };
    }
    case 'SET_AUTHENTICATION_TYPE': {
      return {
        ...state,
        authenticationType: action.payload,
      };
    }
    case 'SET_MASQUERADE': {
      return {
        ...state,
        isMasquerade: action.payload,
      };
    }
    case 'ASSOCIATE_USER_FULFILLED': {
      return {
        ...state,
        associated: true,
      };
    }

    case 'REGISTRATION_PENDING': {
      return {
        ...state,
      };
    }
    case 'REGISTRATION_FULFILLED': {
      return {
        ...state,
        registered: action.payload,
        registerMessage: '',
      };
    }
    case 'SET_AUTHENTICATED_FULFILLED': {
      return {
        ...state,
        authenticated: action.payload,
      };
    }
    case 'FETCH_TOKEN_FULFILLED': {
      return {
        ...state,
        authenticated: action.payload.authenticated,
      };
    }
    case 'SET_VERIFY_FULFILLED': {
      return {
        ...state,
        verified: action.payload,
      };
    }
    case 'FETCH_INVITE_FULFILLED': {
      return {
        ...state,
        registerMessage: null,
        verified: action.payload,
      };
    }
    case 'FETCH_HASH_USER_FULFILLED': {
      return {
        ...state,
        resetMessage: null,
      };
    }
    case 'UPDATE_PASSWORD_FULFILLED': {
      return {
        ...state,
        responseMessage: null,
      };
    }
    case 'FORGOT_FULFILLED': {
      return {
        ...state,
        responseMessageColour: 'text-success',
        responseMessage: action.payload,
      };
    }
    /* This is a special action set by the generic action when an authorisation
     * error occurs on one of our endpoints */
    case 'AUTHENTICATION_ERROR': {
      return {
        ...state,
        responseMessage: action.payload,
        authenticated: false,
      };
    }

    default:
      break;
  }
  return state;
}
