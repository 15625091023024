import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as animalClassControls } from '../forms/animalClasses';

import { fetchAttributes } from '../actions/attributes';

import {
  createAnimalClass,
  fetchAnimalClass,
  removeAnimalClass,
  updateAnimalClass,
  fetchAnimalClassUsages,
} from '../actions/animal_classes';

import { cloneDeep } from 'lodash';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

class AnimalClassModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(animalClassControls),
      data: {},
      id: null,
      isNew: false,
      isModalDeleteDisabled: false,
      title: 'Animal Class',
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.loadAttributes = this.loadAttributes.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(animalClassControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Animal Class',
      };

      // Populate the animal class types with the default division options.
      this.props.dispatch(
        fetchAttributes({ type: 'divisions', rules: { allowAnimalClasses: true } })
      );

      if (this.props.id) {
        const { id } = this.props;
        updatedState = { ...updatedState, id, isNew: false, title: 'Edit Animal Class' };

        this.props.dispatch(fetchAnimalClassUsages(id)).then((isModalDeleteDisabled) =>
          this.setState({
            isModalDeleteDisabled,
          })
        );

        this.props.dispatch(fetchAnimalClass(id)).then((data) => {
          data.division_id = data.type.parent_id;
          if (data.division_id) this.loadAttributes(data.division_id);
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;
    const { animal_class_types } = this.props.attributes;

    if (animal_class_types?.length === 0) controls.type_id.value = null;

    data = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createAnimalClass(data));
      } else {
        success = await this.props.dispatch(updateAnimalClass(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_ANIMAL_CLASS_TYPES_ATTRIBUTES' });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} class permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeAnimalClass(data.id));
      if (success) this.onClose(true);
    }
  }

  onChange(event) {
    const { controls } = this.state;

    const old_division_id = controls.division_id.value;

    this.handleChange(event);

    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'division_id':
        // Load another select;
        if (old_division_id !== value) {
          this.loadAttributes(value);
        }
        break;
      default:
        break;
    }
  }

  onCheckboxChange(event) {
    const { controls } = this.state;
    const { name, checked } = event.target;
    let updatedControls = cloneDeep(controls);
    updatedControls[name] = checked;
    this.setState({
      controls: updatedControls,
    });

    this.handleChange(event);
  }

  loadAttributes(parent_id) {
    const action =
      parent_id === '' || parent_id === '-'
        ? { type: 'UNSET_ANIMAL_CLASS_TYPES_ATTRIBUTES' }
        : fetchAttributes({ type: 'animal_class_types', parent_id });

    this.props.dispatch(action);
  }

  render() {
    let { controls, title, isNew, isModalDeleteDisabled } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.animal_classes;
    const { divisions, animal_class_types } = this.props.attributes;
    const iconName = 'paw';

    let deleteDisabledTooltip;
    let nonEditableTooltip;

    if (isModalDeleteDisabled)
      deleteDisabledTooltip = 'Transactions exist, cannot delete this class';

    if (!isNew) nonEditableTooltip = 'Cannot edit Division and Class Type for existing classes';

    controls.type_id.disabled = animal_class_types.length === 0;
    const optionsCriteria = [
      { key: 'division_id', data: divisions },
      { key: 'type_id', data: animal_class_types },
    ];

    optionsCriteria.forEach(
      ({ key, data }) => (controls = updateControlOptions(controls, key, data))
    );

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput
              handleChange={this.onChange}
              control={controls.division_id}
              disabled={!isNew}
              title={nonEditableTooltip ?? null}
            />
            <FormInput
              handleChange={this.onChange}
              control={controls.type_id}
              disabled={!isNew}
              title={nonEditableTooltip ?? null}
            />
            <FormInputSwitch
              control={controls.valid}
              className={'ml-0 mr-0'}
              handleChange={this.handleChange}
            />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={isModalDeleteDisabled}
              title={deleteDisabledTooltip ?? null}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    attributes: store.attributes,
    animal_classes: store.animal_classes,
  };
};

export default connect(mapStoreToProps)(AnimalClassModal);
