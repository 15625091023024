import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Button } from 'reactstrap';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';
import ClientsLsv from '../components/clients/ClientsLsv';
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';
import ClientsToolbar from '../components/clients/ClientsToolbar';
import GenericModal from '../../../core/form/components/GenericModal';
import ClientActivitiesFilter from '../../portrait/components/ClientActivitiesFilter';
import SingleSearch from '../../../core/form/components/SingleSearch';
import { controls } from '../forms/clientNew.js';

import InvitationsLsv from '../../../manage/components/InvitationsLsv';

import {
  fetchOrgs,
  createClientOrg,
  fetchInviteOrgs,
  fetchInvitations,
  removeInvite,
  setOrgParams,
} from '../../../manage/actions';

import { setFilters } from '../../../core/activity/actions';

import { fetchOrgActivityTypes } from '../../../core/activity/actions';
import { fetchActivities } from '../../../core/activity/actions';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';

class Clients extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'clients',
      controls,
      errorMessage: null,
      isClientModalOpen: false,
      orgs: {},
      searchValue: '',
    };

    this.edit = this.edit.bind(this);
    this.fillControlDefaults = this.fillControlDefaults.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onCreateOrg = this.onCreateOrg.bind(this);
    this.reload = this.reload.bind(this);
    this.removeInvitation = this.removeInvitation.bind(this);
    this.setClientModal = this.setClientModal.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
    this.onSearchActivityChange = this.onSearchActivityChange.bind(this);
    this.handleActivitySearchChange = this.handleActivitySearchChange.bind(this);
  }

  async componentDidMount() {
    let { controls } = this.state;

    const { params } = this.props.manage;
    if (params.search_value) this.setSearchValue(params.search_value);

    await this.props.dispatch(fetchOrgs(params));

    // Build options list
    const orgs = await this.props.dispatch(fetchInviteOrgs());
    controls.default_org_id.options = orgs.map((org) => ({ id: org.id, name: org.name }));

    this.setState({ controls, orgs });
  }

  // Use this to set defaults in generic modal after initcontrols
  async fillControlDefaults(controls) {
    const { orgs } = this.state;

    // Set default organsation
    if (orgs.length > 0) {
      controls.default_org_id.value = orgs[0].id;
    }

    return controls;
  }

  async reload() {
    // Clear params.search_value
    const { params } = this.props.manage;
    await this.props.dispatch(fetchOrgs(params));
  }

  async onChange() {
    const { searchValue } = this.state;
    await this.props.dispatch(setOrgParams({ search_value: searchValue }));
  }

  async onSearchActivityChange(event) {
    if (event) event.preventDefault();

    const { params, filters, searchValue } = this.props.activities;

    let searchParams = {
      ...params,
    };
    if (searchValue) searchParams.search_value = searchValue;
    else delete searchParams.search_value;

    this.props.dispatch(setFilters(searchParams, filters, true));
  }

  async onCreateOrg(data) {
    const success = await this.props.dispatch(createClientOrg(data));

    if (success) {
      await this.props.dispatch(fetchOrgs());
    }

    // Past to genericModal so it knows if we saved properly
    const message = '';
    return { success, message };
  }

  async removeInvitation(id) {
    await this.props.dispatch(removeInvite(id));
    this.props.dispatch(fetchInvitations());
  }

  async toggleTab(tab, tag) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    const { params } = this.props.manage;
    if (params.searchValue) this.setSearchValue(params.searchValue);

    switch (tag) {
      case 'clients':
        await this.props.dispatch(fetchOrgs(params));
        break;
      case 'invites':
        this.props.dispatch(fetchInvitations());
        break;
      case 'activity':
        this.props.dispatch(fetchOrgActivityTypes());
        this.props.dispatch(fetchActivities({ limit: 30 }, this.props.activities.filters, true));
        break;
      default:
        break;
    }
  }

  edit(client) {
    const lnk = `/home/clients/${client.id}`;
    this.props.history.push(lnk);
  }

  handleSearchChange(event) {
    // Update search value
    const searchValue = event.target.value;
    this.setState({ searchValue });

    const enter_button_code = 13;
    if (event.keyCode === enter_button_code) this.onChange();
  }

  handleActivitySearchChange(event) {
    this.props.dispatch({ type: 'SET_ACTIVITIES_SEARCH_VALUE', payload: event.target.value });

    const enter_button_code = 13;
    if (event.keyCode === enter_button_code) this.onSearchActivityChange(event);
  }

  setClientModal(isClientModalOpen) {
    this.setState({ isClientModalOpen });
  }

  setSearchValue(searchValue) {
    this.setState({ searchValue });
  }

  loadMoreActivities() {
    const { params, filters } = this.props.activities;

    let new_params = {
      ...params,
      limit: params.limit + 30,
    };

    this.props.dispatch(fetchActivities(new_params, filters, true));
  }

  render() {
    const { orgs, authorised, authResponseMessage, responseMessage, invitations } =
      this.props.manage;

    const { activities } = this.props.activities;

    const { controls, searchValue, isClientModalOpen, activitySearchValue } = this.state;

    const iconName = 'restroom';
    const title = 'Clients';
    const description = 'Manage client properties, projects and interface access';
    const inviteMessage =
      'No Invitations found. You can invite users by selecting a client, click user tab and use Invite User button.';

    const customActivityFields = [
      'associate_with',
      'created_datetime',
      'action',
      'comment',
      'createdby',
      'org.name',
      'level',
      'items',
    ];

    return (
      <div>
        <div className="p-3">
          <PageTitle title={title} description={description} iconName={iconName} />
          <ResponseMessage responseMessage={authResponseMessage} />
          {authorised && (
            <Fragment>
              <Nav tabs className="mt-2">
                <FormTab
                  caption="Clients"
                  tabId="clients"
                  activeTab={this.state.activeTab}
                  toggle={this.toggleTab}
                  tabTag="clients"
                />
                <FormTab
                  caption="Invitations"
                  tabId="invitations"
                  activeTab={this.state.activeTab}
                  toggle={this.toggleTab}
                  tabTag="invites"
                />
                <FormTab
                  caption="Activity"
                  tabId="activity"
                  activeTab={this.state.activeTab}
                  toggle={this.toggleTab}
                  tabTag="activity"
                />
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="clients" className="mb-2 p-3">
                  <ClientsToolbar
                    checkAccess={this.checkAccess}
                    clients={orgs}
                    onChange={this.onChange}
                    handleSearchChange={this.handleSearchChange}
                    searchValue={searchValue}
                    setModal={this.setClientModal}
                  />
                  <GenericModal
                    setModal={this.setClientModal}
                    isOpen={isClientModalOpen}
                    controls={controls}
                    modalTitle="Client"
                    isNew={true}
                    iconName="people"
                    onSave={this.onCreateOrg}
                    onClose={this.reload}
                    data={{}}
                    fillControlDefaults={this.fillControlDefaults}
                    responseMessage={responseMessage}
                  />
                  <ClientsLsv
                    history={this.props.history}
                    rows={orgs}
                    onClick={this.edit}
                    checkAccess={this.checkAccess}
                  />
                </TabPane>

                <TabPane tabId="invitations" className="mb-2 p-1">
                  <InvitationsLsv
                    hideHeader={true}
                    removeInvitation={this.removeInvitation}
                    rows={invitations || []}
                    message={inviteMessage}
                  />
                </TabPane>

                <TabPane tabId="activity" className="mb-2 p-1">
                  <div className="bg-light p-2 d-flex justify-content-end mb-1">
                    <SingleSearch
                      handleSearchChange={this.handleActivitySearchChange}
                      placeholder="Filter by keyword"
                      value={activitySearchValue}
                    />
                    <Button
                      onClick={this.onSearchActivityChange}
                      size="sm"
                      color="success"
                      className="mr-2"
                    >
                      Go
                    </Button>
                    <Button id="toggler" size="sm" color="warning" className="text-white ml-2">
                      Filters
                    </Button>
                  </div>
                  <ClientActivitiesFilter toggler="#toggler" />
                  <ActivitiesLsv
                    customFields={customActivityFields}
                    rows={activities || []}
                    pagination={true}
                    onLoadMore={this.loadMoreActivities}
                  />
                </TabPane>
              </TabContent>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ manage, realm, activities }) => ({
  manage,
  realm,
  activities,
});

export default connect(mapStoreToProps)(withContainerError(Clients));
