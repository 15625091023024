import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const WoolMenu = ({
  setWoolTransactionModal = noop,
  setWoolAdjustmentModal = noop,
  setWoolValueModal = noop,
}) => (
  <div>
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="success" size="sm">
        <Icon name="plus" className="mr-1" />
        Action{' '}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          toggle={true}
          onClick={() => setWoolTransactionModal(true)}
        >
          Add Sale
        </DropdownItem>
        <DropdownItem
          toggle={true}
          onClick={() => setWoolAdjustmentModal(true)}
        >
          Add Adjustment
        </DropdownItem>
        <DropdownItem toggle={true} onClick={() => setWoolValueModal(true)}>
          Add Value
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  </div>
);

export default WoolMenu;
