import { isEqual, sortBy } from 'lodash';
import moment from 'moment';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import Icon from 'jsx/components/core/icons/Icon';
import { connect } from 'react-redux';
import { Button, Nav, TabContent, TabPane } from 'reactstrap';
import withFeatureFlagCheck from 'jsx/components/core/features/withFeatureFlagCheck';

import FormBase from '../../../core/form/components/FormBase';

import FormTab from '../../../core/form/components/FormTab';
import Report from '../components/analytics/Report';
import WidgetGroups from '../components/WidgetGroups';
import MetricModal from './MetricModal';

import { fetchReports, fetchReportMatrix } from '../actions/reports';
import ReportsFilter from '../components/analytics/ReportsFilter';
import ReportsToolbar from '../components/analytics/ReportsToolbar';
import { fetchDistinctAssociatedDivisions, fetchEnterpriseRanges } from '../actions/enterprises';
import ReportMatrix from '../components/analytics/ReportMatrix';

class EnterpriseDashboard extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'matrix',
      errorMessage: null,
      filterOpen: false,
      groups: [],
      currentReloadValues: {},
      isMetricModalOpen: false,
      modalMetric: {},
    };
  }

  async componentDidMount() {
    // Ensure we have a date range.
    const { selectedRanges } = this.props.enterprises;
    if (!selectedRanges.from_date || !selectedRanges.to_date) {
      await this.props.dispatch(fetchEnterpriseRanges());
    }

    this.props.dispatch(fetchDistinctAssociatedDivisions());
    this.updatePreviousFilters();
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }

    const hasFiltersChanged = this.haveFiltersChanged();
    if (isActive && !isEqual(hasFiltersChanged, this.props.reports.hasFiltersChanged)) {
      this.props.dispatch({ type: 'SET_HAS_REPORT_FILTERS_CHANGED', payload: hasFiltersChanged });
    }
  }

  refreshCurrentReloadValues = () => {
    const { currentReloadValues } = this.state;
    const keys = Object.keys(currentReloadValues);
    if (keys.length > 0) {
      keys.forEach((key) => this.updateReloadValue(key));
    }
  };

  applyFilters = () => {
    this.updatePreviousFilters();
    this.refreshCurrentReloadValues();
  };

  getPreviousFiltersFromProps = () => {
    const { from_date, to_date } = this.props.enterprises.selectedRanges;
    const { divisions: division_ids = [], enterprises: enterprise_ids = [] } =
      this.props.reports.filters;
    const { selectedProperties: property_ids = [] } = this.props.enterprises;

    return {
      from_date,
      to_date,
      division_ids,
      property_ids,
      enterprise_ids,
    };
  };

  haveFiltersChanged = () => {
    const { selectedProperties = [], selectedRanges } = this.props.enterprises;
    const { previousFilters, filters: reportFilters } = this.props.reports;

    if (Object.keys(previousFilters).length === 0) return false;

    const { divisions = [], enterprises = [] } = reportFilters;
    const { from_date, to_date } = selectedRanges;
    const filters = [
      { previous: previousFilters.from_date, current: from_date },
      { previous: previousFilters.to_date, current: to_date },
      { previous: previousFilters.division_ids, current: divisions },
      { previous: previousFilters.enterprise_ids, current: enterprises },
      { previous: previousFilters.property_ids, current: selectedProperties },
    ];

    return filters.some(({ previous, current }) => !isEqual(previous, current));
  };

  loadReport = async (id) => {
    const { reports } = this.props.reports;
    const report = reports.find((targetReport) => targetReport.id === id);
    if (!report) return;

    this.updateReloadValue(id);
  };

  onEditMetric = (currentMetric) => {
    this.setState({ currentMetric });
    this.setMetricModal(true);
  };

  onRefresh = async () => {
    const { activeTab, currentReloadValues } = this.state;

    const reports = await this.props.dispatch(
      fetchReports({ enabled: true, includePublished: true }, true),
    );

    if (reports.length > 0) {
      reports.forEach(({ id }) => {
        if (!currentReloadValues[id]) this.updateReloadValue(id);
      });
    }

    if (activeTab) {
      this.toggleTab(activeTab);
    }
  };

  /**
   *  Leaving this unused function in the container to demonstrate WidgetDivisions
   *  which is not yet supported in Report but a nice layout for viewing Divisions.
   */
  renderGroups = () => {
    const { groups } = this.state;
    return <WidgetGroups groups={groups} onEditMetric={this.onEditMetric} />;
  };

  renderReportNavs = () => {
    const { reports } = this.props.reports;
    const { activeTab } = this.state;

    return reports.map(({ id, name }, index) => (
      <FormTab
        key={index}
        caption={name}
        tabId={id}
        activeTab={activeTab}
        toggle={this.toggleTab}
      />
    ));
  };

  renderReportTabs = () => {
    const { activeTab, currentReloadValues } = this.state;
    const { reports, hasFiltersChanged } = this.props.reports;

    // Set report functions
    const functions = {
      setMetricModal: this.setMetricModal,
    };

    return reports.map(({ id, published_version }) => (
      <TabPane key={`tabPane-${id}`} tabId={id} className="p-0 h-100">
        <div className="d-flex justify-content-end bg-light border border-lightgray rounded p-1 mt-1">
          <small className="mt-1 mr-3">Report Version: {published_version.version}</small>
          <Button
            size="sm"
            color={hasFiltersChanged ? 'danger' : 'primary'}
            onClick={() => this.reloadReport(id)}
          >
            Reload Report
          </Button>
        </div>

        <Report
          data={published_version}
          functions={functions}
          currentReloadValue={activeTab === id ? currentReloadValues[id] ?? null : null}
        />
      </TabPane>
    ));
  };

  setMetricModal = (isMetricModalOpen, modalMetric) => {
    this.setState({
      isMetricModalOpen,
      modalMetric,
    });
  };

  toggleTab = (tab, tag = null) => {
    switch (tab) {
      case 'matrix': {
        this.props.dispatch(fetchReportMatrix());
        break;
      }
      default: {
        if (tag) this.loadReport(tag);
      }
    }

    this.setState({ activeTab: tab });
  };

  toggleFilterBox = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  reloadReport = (reportId) => {
    this.applyFilters();
    this.updateReloadValue(reportId);
  };

  updateReloadValue = (id) => {
    const { currentReloadValues } = this.state;

    currentReloadValues[id] = moment().valueOf();

    this.setState(currentReloadValues);
  };

  updatePreviousFilters = () => {
    this.props.dispatch({
      type: 'SET_PREVIOUS_REPORT_FILTERS',
      payload: this.getPreviousFiltersFromProps(),
    });
  };

  render() {
    const { activeTab, filterOpen, isMetricModalOpen, modalMetric } = this.state;
    const { distinctAssociatedDivisions, enterprises } = this.props.enterprises;
    const { hasFiltersChanged, reports, matrix } = this.props.reports;
    const { isReportMatrixEnabled } = this.props;
    const emptyCaption = 'No Published Analytics/Reports found';
    const iconName = 'file-chart-column';

    const enterprisesForReportsFilter =
      sortBy(
        enterprises?.rows?.map(({ division_id, id, name }) => ({
          colour: 'blue',
          division_id,
          id,
          name,
        })),
        ['name'],
      ) ?? [];

    return (
      <div className="p-0 h-100">
        <ReportsToolbar
          hasFiltersChanged={hasFiltersChanged}
          onApplyFiltersClick={this.applyFilters}
          onFilterClick={this.toggleFilterBox}
          isFilterOpen={filterOpen}
        />
        <ReportsFilter
          filterOpen={filterOpen}
          divisions={distinctAssociatedDivisions}
          enterprises={enterprisesForReportsFilter}
          refresh={this.onRefresh}
        />
        {reports.length > 0 && (
          <>
            <Nav tabs className="mt-2">
              {isReportMatrixEnabled && (
                <FormTab
                  caption="Report Matrix"
                  tabId="matrix"
                  activeTab={activeTab}
                  toggle={this.toggleTab}
                />
              )}
              {this.renderReportNavs()}
            </Nav>
            <TabContent activeTab={activeTab} className="h-100">
              {isReportMatrixEnabled && (
                <TabPane tabId="matrix" className="p-0 h-100">
                  <ReportMatrix layout={matrix} />
                </TabPane>
              )}
              {this.renderReportTabs()}
            </TabContent>
            <MetricModal
              setModal={this.setMetricModal}
              id={modalMetric?.id}
              metric={modalMetric}
              isOpen={isMetricModalOpen}
              isNew={false}
            />
          </>
        )}

        {reports.length === 0 && (
          <div className="p-5 text-center">
            <Icon size="3x" name={iconName} className="text-corporate" />
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ enterprises, realm, reports }) => ({
  enterprises,
  realm,
  reports,
});

export default withFeatureFlagCheck(
  'farmeye-report-matrix',
  'isReportMatrixEnabled',
  connect(mapStoreToProps)(withContainerError(EnterpriseDashboard)),
);
