import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiEnterprises } from '../../../../constants/api';

export function fetchEnterprises(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ENTERPRISES', `${apiEnterprises}`, params);
}

export function fetchEnterprise(id) {
  return async (dispatch) => get(dispatch, 'FETCH_ENTERPRISE', `${apiEnterprises}/${id}`);
}

export function fetchEnterpriseRanges() {
  return async (dispatch) => get(dispatch, 'FETCH_ENTERPRISE_RANGES', `${apiEnterprises}/ranges`);
}

export function fetchEnterpriseTransactionRanges() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_TRANSACTION_RANGES', `${apiEnterprises}/ranges/transactions`);
}

export function createEnterprise(data) {
  return async (dispatch) => post(dispatch, `CREATE_ENTERPRISE`, `${apiEnterprises}`, data);
}

export function updateEnterprise(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ENTERPRISE', `${apiEnterprises}/${data.id}`, data);
}

export function removeEnterprise(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ENTERPRISE', `${apiEnterprises}/${id}`);
}

export function fetchEnterpriseAllocations(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_ALLOCATIONS', `${apiEnterprises}/allocations`, params);
}

export function fetchEnterpriseAllocation(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${id}`);
}

export function fetchPropertyTotalArea(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_PROPERTY_TOTAL_AREA', `${apiEnterprises}/allocations/property/${id}/area`);
}

export function fetchEnterpriseDistributions(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_DISTRIBUTIONS', `${apiEnterprises}/distributions`, params);
}

export function createEnterpriseAllocation(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_ENTERPRISE_ALLOCATION`, `${apiEnterprises}/allocations`, data);
}

export function updateEnterpriseAllocation(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${data.id}`, data);
}

export function removeEnterpriseAllocation(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${id}`);
}

export function fetchEnterpriseUsages(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_USAGES', `${apiEnterprises}/usages/${id}`);
}

export function fetchDistinctAssociatedDivisions() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_DISTINCT_ASSOCIATED_DIVISIONS', `${apiEnterprises}/divisions/`);
}
