import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    group: 'enterprise',
    fieldName: 'property.name',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Animal Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  date: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'date',
    type: 'date',
    caption: 'Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    fieldName: 'value',
    name: 'value',
    type: 'number',
    caption: 'Value $/kg',
    formattingRules: {
      includeCommas: true,
      includeDecimals: false,
    },
    showInEditor: true,
  },
  // Listview controls
  enterprise_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    showInListview: true,
  },
  property_listview: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    fieldName: 'property.name',
    type: 'select',
    caption: 'Property',
    showInEditor: false,
    showInListview: true,
  },
  animal_class_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Animal Class',
    showInListview: true,
  },
  date_listview: {
    ...controlDefaults,
    placeholder: '',
    name: 'date',
    type: 'date',
    caption: 'Date',
    showInListview: true,
    sortColumn: 'date',
  },
  value_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'value',
    name: 'value',
    type: 'number',
    caption: 'Value $/kg',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    showInListview: true,
  },
};
