import { allowed, defaults } from 'jsx/lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    activeSettingsTab: null,
    authorised: true,
    authResponseMessage: null,
    error: null,
    responseMessage: null,
    userResponseMessage: null,
    currentUser: {},
    users: [],
    invitations: [],
    roles: [],
    documentDefs: [],
    outputMappings: [],
    attributeDefs: [],
    orgs: [],
    codes: [],
    orgCodes: [],
    soilTemplates: [],
    costcodes: [],
    leavecodes: [],
    phasecodes: [],
    methodologies: [],
    data_preparations: [],
    subsample_scripts: [],
    soc_nir_type_scripts: [],
    settingsMenus: [
      { caption: 'Cost Codes', url: '', iconName: 'user' },
      { caption: 'Custom Attributes', url: '', iconName: 'users' },
      { caption: 'Organisations', url: '', iconName: 'box-full' },
    ],
    params: {},
    filters: {
      users: ['enabled'],
    },
    invitationLink: null,
    sampling_plans: [],
    listview_configuration: {},
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'ATTRIBUTE',
    'CODE',
    'DATA',
    'DOCUMENT',
    'INVITE',
    'METHODOLOGIES',
    'OUTPUT',
    'ORG',
    'ROLE',
    'SET_SETTINGS',
    'SOIL',
    'SUBSAMPLE',
    'TEST',
    'USER',
    'SAMPLING_PLAN',
    'LISTVIEW_CONFIGURATION',
    'SOC_NIR',
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_SETTINGS_ACTIVE_TAB': {
      return {
        ...updatedState,
        activeSettingsTab: action.payload,
      };
    }
    case 'FETCH_ROLES_FULFILLED': {
      return {
        ...updatedState,
        roles: action.payload,
      };
    }
    case 'FETCH_USERS_FULFILLED': {
      return {
        ...updatedState,
        users: action.payload,
      };
    }
    case 'FETCH_USER_FULFILLED': {
      return {
        ...updatedState,
        currentUser: action.payload,
      };
    }
    case 'FETCH_INVITED_FULFILLED': {
      return {
        ...updatedState,
        invitations: action.payload,
      };
    }
    case 'SET_USER_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'SET_USER_FILTERS': {
      return {
        ...updatedState,
        filters: action.payload,
      };
    }
    case 'INVITE_USER_FULFILLED': {
      return {
        ...updatedState,
        userResponseMessage: 'User invitation emailed!',
      };
    }
    case 'INVITE_LINK_FULFILLED': {
      return {
        ...updatedState,
        invitationLink: action.payload,
      };
    }
    case 'UNSET_USER_INVITE_LINK': {
      return {
        ...updatedState,
        invitationLink: null,
      };
    }

    case 'FETCH_DOCUMENT_DEFS_PENDING': {
      return {
        ...updatedState,
        documentDefs: [],
      };
    }
    case 'FETCH_DOCUMENT_DEFS_FULFILLED': {
      return {
        ...updatedState,
        documentDefs: action.payload,
      };
    }
    case 'FETCH_OUTPUT_MAPPINGS_PENDING': {
      return {
        ...updatedState,
        outputMappings: [],
      };
    }
    case 'FETCH_OUTPUT_MAPPINGS_FULFILLED': {
      return {
        ...updatedState,
        outputMappings: action.payload,
      };
    }
    case 'FETCH_ATTRIBUTE_DEFS_FULFILLED': {
      return {
        ...updatedState,
        attributeDefs: action.payload,
      };
    }
    case 'FETCH_ORGS_PENDING':
      return {
        ...updatedState,
        orgs: [],
      };
    case 'FETCH_ORGS_FULFILLED':
      return {
        ...updatedState,
        orgs: action.payload,
      };
    case 'SET_ORG_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'FETCH_TEST_CODES_PENDING':
      return {
        ...updatedState,
        codes: [],
      };
    case 'FETCH_TEST_CODES_FULFILLED':
      return {
        ...updatedState,
        codes: action.payload,
      };
    case 'FETCH_ORG_TEST_CODES_PENDING':
      return {
        ...updatedState,
        orgCodes: [],
      };
    case 'FETCH_ORG_TEST_CODES_FULFILLED':
      return {
        ...updatedState,
        orgCodes: action.payload,
      };
    case 'FETCH_SOIL_TEMPLATES_FULFILLED':
      return {
        ...updatedState,
        soilTemplates: action.payload,
      };
    case 'FETCH_COSTCODES_FULFILLED':
      return {
        ...updatedState,
        costcodes: action.payload,
      };
    case 'FETCH_LEAVECODES_FULFILLED':
      return {
        ...updatedState,
        leavecodes: action.payload,
      };
    case 'FETCH_PHASECODES_FULFILLED':
      return {
        ...updatedState,
        phasecodes: action.payload,
      };
    case 'FETCH_METHODOLOGIES_FULFILLED': {
      return {
        ...updatedState,
        methodologies: action.payload,
      };
    }
    case 'FETCH_DATA_PREPARATIONS_FULFILLED': {
      return {
        ...updatedState,
        data_preparations: action.payload,
      };
    }
    case 'FETCH_SUBSAMPLE_SCRIPTS_FULFILLED': {
      return {
        ...updatedState,
        subsample_scripts: action.payload,
      };
    }
    case 'FETCH_SOC_NIR_TYPE_SCRIPTS_FULFILLED': {
      return {
        ...updatedState,
        soc_nir_type_scripts: action.payload,
      };
    }
    case 'FETCH_SAMPLING_PLANS_FULFILLED': {
      return {
        ...updatedState,
        sampling_plans: action.payload,
      };
    }
    case 'SET_LISTVIEW_CONFIGURATION':
    case 'FETCH_LISTVIEW_CONFIGURATION_FULFILLED': {
      const formattedResponse = action.payload.reduce((acc, curr) => {
        acc[curr.listview_tag] = curr;
        return acc;
      }, {});

      return {
        ...updatedState,
        listview_configuration: {
          ...updatedState.listview_configuration,
          ...formattedResponse,
        },
      };
    }
    case 'UPDATE_LISTVIEW_CONFIGURATION_FULFILLED': {
      const { listview_tag } = action.payload;

      return {
        ...updatedState,
        listview_configuration: {
          ...updatedState.listview_configuration,
          [listview_tag]: action.payload,
        },
      };
    }
    default:
      break;
  }

  return updatedState;
}
