import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { connect } from 'react-redux';
import SessionRefreshModal from 'jsx/components/core/authentication/components/SessionRefreshModal';
import * as Sentry from '@sentry/react';
import * as ContainerError from 'jsx/components/core/errors/ContainerError';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageview } from 'configs/googleanalytics';
import { msalConfig } from '../components/core/authentication/constants/authConfigMicrosoft';
import RealmLoader from '../components/core/loader/components/RealmLoader';
import SocketReducer from '../lib/socketReducer';
import AppRoutes from './AppRoutes';
import { fetchMode, setRealm } from '../actions';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const App = ({ dispatch, loading, auth, realm }) => {
  const location = useLocation();

  const refreshRealm = () => {
    dispatch(setRealm());
    dispatch(fetchMode());
  };

  useEffect(() => {
    refreshRealm();
    // Start socket client for updating reducers
    if (process.env.REACT_APP_DISABLE_SOCKET_CONNECTION !== 'true') {
      const socketReducer = new SocketReducer({ props: { ...auth, dispatch } });
    }
  }, []);

  useEffect(() => {
    pageview(location.pathname, process.env.REACT_APP_GA_MEASUREMENT_ID);
  }, [location]);

  const { authenticated } = auth;
  const realmFound = realm.currentApp.id !== null;

  return (
    <Sentry.ErrorBoundary fallback={ContainerError}>
      <QueryClientProvider client={queryClient}>
        <RealmLoader found={realmFound} />
        <SessionRefreshModal isAuthenticated={authenticated} />
        {realmFound && (
          <MsalProvider instance={msalInstance}>
            <AppRoutes authenticated={authenticated} loading={loading} />
          </MsalProvider>
        )}
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

const mapStoreToProps = ({ ajaxRequests, auth, realm }) => ({
  loading: ajaxRequests.calls > 0,
  auth,
  realm,
});

export default connect(mapStoreToProps)(App);
