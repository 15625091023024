import React from 'react';
import { useDispatch } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { masqueradeRequest } from 'jsx/components/core/authentication/actions';
import { useCheckAccess } from 'jsx/lib/hooks';
import Listview from '../../core/form/components/Listview';
import Pill from '../../core/form/components/Pill';

const getBooleanTableData = (index, caption, classes) => (
  <td key={index} className="text-center">
    <div className="d-flex justify-content-center">
      <Pill caption={caption} classes={classes} />
    </div>
  </td>
);

const UsersLsv = (props) => {
  const { rows, history } = props;
  const dispatch = useDispatch();
  // Edit User
  const edit = (userId) => {
    const link = `/home/users/${userId}`;
    history.push(link);
  };

  const canMasquerade = useCheckAccess('masquerade');
  const canEditUsers = useCheckAccess('userEdit');

  const onMasquerade = async (userId) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      'Masquerading selected user and logging into this app as if you were them. Only Administrators are able to do this. Continue?',
    );

    if (confirmed) {
      const url = await dispatch(masqueradeRequest(userId, 'server'));
      window.open(url, '_blank');
    }
  };

  const renderRows = (headers, user) => {
    const { firstname, lastname } = user;

    const tableTd = headers.map(({ classes, field }, index) => {
      const roles = user.user_roles.map((user_role, roleIndex) => {
        const { id, name } = user_role.role;
        return <Pill key={roleIndex} caption={name} id={id} classes="bg-primary" />;
      });

      const successClasses = 'bg-success text-white text-center px-2';
      const errorClasses = 'bg-red text-white text-center px-2';
      const fullname = `${firstname} ${lastname}`;

      switch (field) {
        case 'roles':
          return (
            <td key={index} className={classes}>
              <div className="d-flex">{roles}</div>
            </td>
          );
        case 'name':
          return (
            <td key={index} className={classes}>
              {fullname}
            </td>
          );
        case 'enabled': {
          const { enabled } = user;
          const caption = enabled ? 'Yes' : 'No';
          return getBooleanTableData(index, caption, enabled ? successClasses : errorClasses);
        }
        case 'is_locked': {
          const { is_locked } = user;
          const caption = is_locked ? 'Yes' : 'No';
          return getBooleanTableData(index, caption, is_locked ? errorClasses : successClasses);
        }
        case 'week_budget_hours':
          return (
            <td key={index} className="text-right">
              {user[field]}
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {user[field]}
            </td>
          );
      }
    });

    const actionIcons = [];
    if (canEditUsers) {
      actionIcons.push(
        <Icon
          name="pen-to-square"
          title="Edit"
          as="button"
          onClick={() => edit(user.id)}
          className="mr-2 text-primary"
        />,
      );
    }
    if (canMasquerade) {
      actionIcons.push(
        <Icon
          name="mask"
          title="Masquerade"
          className="text-primary"
          as="button"
          onClick={() => onMasquerade(user.id)}
        />,
      );
    }
    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-end">
        {actionIcons}
      </td>,
    );

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Email', field: 'email', classes: 'text-left' },
    { caption: 'Mobile', field: 'mobile', classes: 'text-left' },
    { caption: 'Week Budget Hours', field: 'week_budget_hours', classes: 'text-left' },
    { caption: 'Roles', field: 'roles', classes: 'text-left' },
    { caption: 'Enabled', field: 'enabled', classes: 'text-left' },
    { caption: 'Locked', field: 'is_locked', classes: 'text-left' },
  ];

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row) => (
      <tr key={row.id} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'users';
  const emptyCaption = 'No Users found';

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default UsersLsv;
