import * as React from 'react';
import Icon from '../../icons/Icon';

const SCROLL_LENGTH = 500;

export interface ScrollBoxProps {
  offsetPixels?: number;
  children: React.ReactNode;
}

export const ScrollBox = ({ children, offsetPixels = 0 }: ScrollBoxProps) => {
  const container = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [canScroll, setCanScroll] = React.useState({ left: false, right: true });

  React.useLayoutEffect(() => {
    const currentContainer = container?.current;

    function handleScroll() {
      const maxScrollLeft =
        (currentContainer?.scrollWidth || 0) - (currentContainer?.clientWidth || 0);

      setCanScroll({
        left: currentContainer?.clientLeft !== currentContainer?.scrollLeft,
        right: maxScrollLeft !== currentContainer?.scrollLeft,
      });
    }
    currentContainer?.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      currentContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [container]);

  React.useLayoutEffect(() => {
    const firstChild = container?.current?.firstElementChild;
    const veiwportHeight = window.innerHeight;

    function calculateContainerHeight() {
      if (!firstChild) return;

      if (veiwportHeight - offsetPixels > firstChild?.clientHeight) {
        setContainerHeight(firstChild?.clientHeight);
      } else {
        setContainerHeight(veiwportHeight - offsetPixels);
      }
    }

    window.addEventListener('resize', calculateContainerHeight);
    calculateContainerHeight();

    return () => {
      window.removeEventListener('resize', calculateContainerHeight);
    };
  });

  return (
    <>
      <button
        disabled={!canScroll?.left}
        className="btn btn-light px-3"
        onClick={() => {
          if (!container?.current) return;
          container?.current.scrollBy({
            top: 0,
            left: -SCROLL_LENGTH,
            behavior: 'smooth',
          });
        }}
      >
        <Icon name="chevron-left" />
      </button>
      <div className="scroll-shadows" style={{ height: containerHeight }} ref={container}>
        {children}
      </div>
      <button
        disabled={!canScroll?.right}
        className="btn btn-light px-3"
        onClick={() => {
          if (!container?.current) return;
          container.current.scrollBy({
            top: 0,
            left: SCROLL_LENGTH,
            behavior: 'smooth',
          });
        }}
      >
        <Icon name="chevron-right" />
      </button>
    </>
  );
};
