import FormSearch from 'jsx/components/core/form/components/FormSearch';
import MonthPicker from 'jsx/components/core/form/components/MonthPicker';
import { noop } from 'lodash';
import React from 'react';
import { Col, Label, Row } from 'reactstrap';

const ReportPreviewToolbar = (props) => {
  const {
    controls,
    onChange = noop,
    onClientSelect = noop,
    isClientSelected,
    onDateChange = noop,
    selectedRanges,
  } = props;

  return (
    <Row className="bg-light rounded p-1 m-1 border border-lightgray">
      <Col sm={8} className="d-flex justify-content-start">
        <Label for={controls.client_id.name} className="mr-2 mb-2 mt-2">
          Client:
        </Label>
        <FormSearch
          handleChange={onChange}
          handleSelect={onClientSelect}
          control={controls.client_id}
          selected={isClientSelected}
          minimumLength={1}
          width={'100%'}
        />
      </Col>
      <Col sm={4} className="d-flex justify-content-end">
        <MonthPicker
          id="from-month"
          caption="From:"
          className="mr-2"
          maximum={selectedRanges.to_date}
          selected={selectedRanges.from_date}
          handleChange={(date) => onDateChange('from_date', date)}
        />
        <MonthPicker
          id="to-month"
          caption="To:"
          minimum={selectedRanges.from_date}
          selected={selectedRanges.to_date}
          handleChange={(date) => onDateChange('to_date', date)}
        />
      </Col>
    </Row>
  );
};

export default ReportPreviewToolbar;
