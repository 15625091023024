import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import Icon from 'jsx/components/core/icons/Icon';

import EnterpriseDashboard from './EnterpriseDashboard';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import MonthPicker from '../../../core/form/components/MonthPicker';
import Placeholder from '../components/Placeholder';

import Business from './Business';
import BusinessConfig from './BusinessConfig';
import Agistments from './Agistments';
import SheepEnterprises from './SheepEnterprises';
import CattleEnterprises from './CattleEnterprises';
import SugarEnterprises from './SugarEnterprises';
import CroppingEnterprises from './cropping/CroppingEnterprises';
import OtherLivestockEnterprises from './OtherLivestockEnterprises';
import OtherBusinessEnterprises from './OtherBusinessEnterprises';
import OffFarm from './OffFarm';
import { fetchEnterpriseTransactionRanges } from '../actions/enterprises';
import { fetchProperties } from '../../projects/actions/properties';
import WoolEnterprises from './wool/WoolEnterprises';

class Enterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      tabRefresh: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchProperties());
    this.setRefresh(true);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  onDateChange = (type, value) => {
    const range = {};

    let date = moment(value);
    switch (type) {
      case 'to_date': {
        date = date.endOf('month');
        break;
      }
      case 'from_date':
      default: {
        date = date.startOf('month');
        break;
      }
    }

    range[type] = date.toISOString(true);

    this.props.dispatch({ type: 'SET_ENTERPRISE_SELECTED_RANGES', payload: range });
    this.setRefresh(true);
  };

  onPropertyChange = (activePropertyFilters) => {
    const propertyFilters = activePropertyFilters.map(({ value }) => value);
    this.setSelectedProperties(propertyFilters);
    this.setRefresh(true);
  };

  setRefresh = (tabRefresh) => {
    this.setState({ tabRefresh });
  };

  setSelectedProperties = (payload) =>
    this.props.dispatch({
      type: 'SET_ENTERPRISE_SELECTED_PROPERTIES',
      payload,
    });

  showAllTransactions = async () => {
    // Fetch minimum and maximum date from all transactions
    await this.props.dispatch(fetchEnterpriseTransactionRanges()); // On fulfilled, reducer updates selected ranges
    this.setRefresh(true);
  };

  toggleTab = async (tab) => {
    const { activeTab } = this.props.enterprises;

    if (activeTab !== tab)
      await this.props.dispatch({ type: 'SET_ENTERPRISES_ACTIVE_TAB', payload: tab });

    this.setRefresh(true);
  };

  render() {
    const { tabRefresh } = this.state;
    const { activeTab, authorised, authResponseMessage, enterprises, ranges, selectedRanges } =
      this.props.enterprises;
    const { currentUser } = this.props.profile;

    let currentOrg = '';
    let title = '';
    if (currentUser.id) {
      currentOrg = `${currentUser.user_orgs[0].org.name}`;

      const enterpriseOptions = this.props?.properties?.properties?.rows?.map((props) => ({
        ...props,
        value: props.id,
        label: props.name,
      }));

      title = (
        <div className="d-flex align-items-center" style={{ height: '20px' }}>
          <span className="pr-1">Enterprises for: </span>
          <Select
            defaultValue={[]}
            isMulti
            placeholder="Select properties to filter"
            name="property-select"
            options={enterpriseOptions || []}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={this.onPropertyChange}
          />
        </div>
      );
    }

    const showTab = [
      ...(new Set(enterprises.rows?.map((enterprise) => enterprise.division.tag)) ?? []),
    ];

    // Check if any livestock enterprises present
    const isLivestockEnterprise =
      showTab.includes('sheep') ||
      showTab.includes('cattle') ||
      showTab.includes('other_livestock');

    return (
      <div className="p-0 h-100">
        <ResponseMessage responseMessage={authResponseMessage} />

        {authorised && (
          <>
            <div className="bg-light p-2 border-bottom border-corporate d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <Button size="sm" color="success" onClick={this.goBack}>
                  <Icon size="1x" name="chevron-left" className="mr-2" />
                  Back
                </Button>
                <h5 className="m-1 ml-2 align-items-center">{currentOrg}</h5>
              </div>

              <div className="d-flex justify-content-end">
                <h5 className="m-2 ml-2" style={{ fontSize: '16px' }}>
                  {title}
                </h5>
                {ranges.min_date && (
                  <>
                    <div className="d-flex ">
                      <MonthPicker
                        id="from-month"
                        caption="From:"
                        className="mr-2"
                        maximum={selectedRanges.to_date ?? ranges.max_date}
                        selected={selectedRanges.from_date ?? ranges.min_date}
                        handleChange={(date) => this.onDateChange('from_date', date)}
                      />
                      <MonthPicker
                        id="to-month"
                        caption="To:"
                        className="mr-2"
                        minimum={selectedRanges.from_date ?? ranges.min_date}
                        selected={selectedRanges.to_date ?? ranges.max_date}
                        handleChange={(date) => this.onDateChange('to_date', date)}
                      />
                    </div>
                    <Button size="sm" color="success" onClick={this.showAllTransactions}>
                      Show All Transactions
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Row className="p-0 m-0 h-100">
              <Col className="p-0 m-0 h-100 verticalnav">
                <Nav vertical className="mt-2">
                  <FormTab
                    caption="Business Configuration"
                    tabId="business_config"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                  />

                  {isLivestockEnterprise && (
                    <FormTab
                      caption="Agistments"
                      tabId="agistments"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled={false}
                    />
                  )}

                  {showTab.includes('sheep') && (
                    <>
                      <FormTab
                        caption="Sheep Enterprises"
                        tabId="sheep"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                        disabled={false}
                      />
                      <FormTab
                        caption="Wool Production and Inventory"
                        tabId="wool"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                        disabled={false}
                      />
                    </>
                  )}
                  {showTab.includes('cattle') && (
                    <FormTab
                      caption="Cattle Enterprises"
                      tabId="cattle"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                    />
                  )}
                  {showTab.includes('other_livestock') && (
                    <FormTab
                      caption="Other Livestock Enterprises"
                      tabId="other_livestock"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                    />
                  )}
                  {showTab.includes('sugar') && (
                    <FormTab
                      caption="Sugar Enterprises"
                      tabId="sugar"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled
                    />
                  )}
                  {showTab.includes('crop') && (
                    <FormTab
                      caption="Cropping Enterprises"
                      tabId="crop"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                    />
                  )}

                  {showTab.includes('other_business') && (
                    <FormTab
                      caption="Other Business Enterprises"
                      tabId="other_business"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled={false}
                    />
                  )}
                  <FormTab
                    caption="Off Farm Income"
                    tabId="off_farm"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled={false}
                  />

                  <FormTab
                    caption="Business Information"
                    tabId="business_information"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled={false}
                  />
                  <FormTab
                    caption="Analytics/Reports"
                    tabId="analytics"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled={false}
                  />
                  <FormTab
                    caption="Landcare Indicators"
                    tabId="land_indicators"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled
                  />
                </Nav>
              </Col>
              <Col className="h-100 overflow-auto">
                <TabContent activeTab={activeTab} className="h-100">
                  <TabPane tabId="business_config" className="p-1 h-100">
                    <BusinessConfig
                      isActive={activeTab === 'business_config'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="analytics" className="mb-2 p-2">
                    <EnterpriseDashboard
                      isActive={activeTab === 'analytics'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  {isLivestockEnterprise && (
                    <TabPane tabId="agistments" className="mb-2 p-2">
                      <Agistments
                        isActive={activeTab === 'agistments'}
                        forceRefresh={tabRefresh}
                        setRefresh={this.setRefresh}
                      />
                    </TabPane>
                  )}
                  <TabPane tabId="sheep" className="mb-2 p-2">
                    <SheepEnterprises
                      isActive={activeTab === 'sheep'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="cattle" className="mb-2 p-2">
                    <CattleEnterprises
                      isActive={activeTab === 'cattle'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="other_livestock" className="mb-2 p-2">
                    <OtherLivestockEnterprises
                      isActive={activeTab === 'other_livestock'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="sugar" className="mb-2 p-2">
                    <SugarEnterprises />
                  </TabPane>
                  <TabPane tabId="crop" className="mb-2 p-2">
                    <CroppingEnterprises
                      isActive={activeTab === 'crop'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="other_business" className="mb-2 p-2">
                    <OtherBusinessEnterprises
                      isActive={activeTab === 'other_business'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="off_farm" className="mb-2 p-2">
                    <OffFarm
                      isActive={activeTab === 'off_farm'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="business_information" className="mb-2 p-2">
                    <Business
                      isActive={activeTab === 'business_information'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="land_indicators" className="mb-2 p-2">
                    <Placeholder rows={[]} title="Landcare Indicators" />
                  </TabPane>
                  <TabPane tabId="wool" className="mb-2 p-2">
                    <WoolEnterprises
                      isActive={activeTab === 'wool'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({
  clients,
  enterprises,
  farmportrait_portal,
  profile,
  properties,
  realm,
}) => ({
  clients,
  enterprises,
  portal: farmportrait_portal,
  profile,
  properties,
  realm,
});

export default connect(mapStoreToProps)(Enterprises);
