import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import HeaderMenuItem from '../components/core/menu/components/HeaderMenuItem';

import farmEyeLogo from '../../images/farmeye/logo_icon_colour.png';

const HomeHeader = (props) => {
  const { currentApp, location, currentUser } = props;

  // Build Management menu from realm config
  let manageItems = <small className="p-2 text-secondary text-center">No Manage Items</small>;
  if (currentApp.manage.length > 0) {
    manageItems = currentApp.manage.map((manageItem, index) => (
      <DropdownItem key={index} className="pl-3">
        <HeaderMenuItem
          noIcons={false}
          url={manageItem.url}
          caption={manageItem.caption}
          iconName={manageItem.iconName}
          location={location}
          classes="text-black"
        />
      </DropdownItem>
    ));
  }

  let helpMenu = <div />;
  if (currentApp.help) {
    helpMenu = (
      <UncontrolledButtonDropdown title="Help">
        <DropdownToggle caret color="corporate" className="p-0 m-0 pr-1">
          <Icon size="2x" name="info-circle" className="ml-3 mr-1 " />
        </DropdownToggle>
        <DropdownMenu right>
          <div className="d-flex flex-column justify-content-center p-2 bg-lightgray">
            <small className="text-left">
              <span className="text-corporate-secondary">
                Email:{' '}
                <a href={`mailto:${currentApp.help?.contact?.email}`}>
                  {currentApp.help?.contact?.email}
                </a>
              </span>
            </small>
            <small className="text-left">
              <span className="text-corporate-secondary">
                Phone: {currentApp.help?.contact?.phone}
              </span>
            </small>
          </div>
          {currentApp.help?.menu?.map(({ caption, url }, index) => (
            <a key={index} href={url} target="_blank" rel="noreferrer" className="text-black">
              <DropdownItem key="help" className="pl-3">
                {caption}
              </DropdownItem>
            </a>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }

  // Build main menu from realm config
  let menuItems = <small className="p-2 text-secondary text-center">No Realm Items</small>;
  if (currentApp.manage.length > 0) {
    const canViewSamplingPlans = currentApp.features.includes('samplingPlansView');
    menuItems = currentApp.menus
      .filter((menu) => {
        if (menu.caption.includes('Sampling Plans')) {
          return canViewSamplingPlans;
        }
        return true;
      })
      .map((menu, index) => (
        <HeaderMenuItem
          caption={menu.caption}
          disabled
          iconName={menu.iconName}
          key={index}
          location={location}
          noIcons
          url={menu.url}
        />
      ));
  }

  let loggedInAs = '';
  let loggedInCompany = '';
  let loggedInUser = '';
  let project = {};
  if (currentUser.id) {
    if (currentUser.projects && currentUser.projects.length > 0) {
      project = currentUser.projects[0];
    }

    loggedInCompany = `${currentUser.user_orgs[0].org.name}`;
    loggedInUser = currentUser.name;
    loggedInAs = `${currentUser.name} as ${loggedInCompany}`;
  }

  const renderAppLogo = ({ id }) => {
    switch (id) {
      case 'farmportrait':
      case 'farmportrait_portal':
        return <img src={farmEyeLogo} alt="FarmEye" height={50} />;
      case 'carbonizer':
      case 'elementree':
      default:
        return <Icon className="mt-2" name={currentApp.icon} />;
    }
  };

  return (
    <>
      <div className="bg-corporate d-flex flex-row justify-content-between p-1">
        <Link to="/home">
          {currentApp.logo && <img src={currentApp.logo} alt="Carbonizer" className="" />}
          {!currentApp.logo && (
            <div className="d-flex justify-content-center app-header-logo ml-2 p-1 text-white">
              {renderAppLogo(currentApp)}
              <span className="ml-3">{currentApp.title}</span>
            </div>
          )}
        </Link>

        <div className="d-flex flex-rows justify-content-end mt-1 ml-3 mr-2">
          {menuItems}
          {helpMenu}
          <UncontrolledButtonDropdown title={loggedInAs}>
            <DropdownToggle caret color="corporate" className="p-0 m-0 pr-1">
              <Icon size="2x" name="circle-user" className="ml-3 mr-1 " />
            </DropdownToggle>

            <DropdownMenu right>
              <div className="d-flex flex-column justify-content-center p-2 bg-lightgray">
                <small className="text-left">
                  <span className="text-corporate-secondary">Project:</span> {project.name}{' '}
                </small>
                <small className="text-left">
                  <span className="text-corporate-secondary">Organisation:</span> {loggedInCompany}
                </small>
                <small className="text-left">
                  <span className="text-corporate-secondary">User:</span> {loggedInUser}
                </small>
              </div>
              {manageItems}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
