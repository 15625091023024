import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const OtherLivestockMenu = ({
  setModal = noop,
  setDirectCostModal = noop,
  setLivestockAdjustmentModal = noop,
  setLivestockOpeningModal = noop,
}) => (
  <UncontrolledButtonDropdown>
    <DropdownToggle caret color="success" size="sm">
      <Icon name="plus" className="mr-1" />
      Action
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem toggle={true} onClick={() => setModal(true)}>
        Add Sale/Purchase
      </DropdownItem>
      <DropdownItem toggle={true} onClick={() => setLivestockAdjustmentModal(true)}>
        Add Adjustment
      </DropdownItem>
      <DropdownItem toggle={true} onClick={() => setLivestockOpeningModal(true)}>
        Add Opening Value
      </DropdownItem>
      <DropdownItem toggle={true} onClick={() => setDirectCostModal(true)}>
        Add Direct Cost
      </DropdownItem>
      <DropdownItem toggle={true} onClick={() => setModal(true, 'value')}>
        Add Value
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledButtonDropdown>
);

export default OtherLivestockMenu;
