import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './jsx/lib/sentry';
import { Provider } from 'react-redux';
import App from './jsx/containers/App.jsx';
import store from './store';

const app = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  app,
);
