import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { routes } from '../constants/routes';

import Header from './HomeHeader';

class HomeVertical extends React.Component {
  render() {
    const { match, location } = this.props;
    const { currentApp } = this.props.realm;
    const { currentUser } = this.props.profile;
    const { isMasquerade } = this.props.auth;

    return (
      <>
        {isMasquerade && <div className="masquerade-tooltip">Masquerade</div>}
        <Header currentApp={currentApp} location={location} currentUser={currentUser} />

        {routes.map(({ tabs = [], ...route }, index) => {
          const ComponentToRender = route.component;
          return (
            <React.Fragment key={`tab-${index}`}>
              {tabs.map((curr, tabIndex) => (
                <Route
                  exact={route.exact}
                  key={`tab-subroute-${tabIndex}`}
                  path={`${match.path}${route.path}/${curr}`}
                  render={(routeProps) => <ComponentToRender {...routeProps} tabRoutes={tabs} />}
                />
              ))}

              <Route
                exact={route.exact}
                path={`${match.path}${route.path}`}
                render={(routeProps) => <ComponentToRender {...routeProps} tabRoutes={tabs} />}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

const mapStoreToProps = (store) => ({
  loading: store.ajaxRequests.calls > 0,
  realm: store.realm,
  manage: store.manage,
  profile: store.profile,
  auth: store.auth,
});

export default connect(mapStoreToProps)(HomeVertical);
