import PillPicker from 'jsx/components/core/form/components/PillPicker';
import PillPresenter from 'jsx/components/core/form/components/PillPresenter';
import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';

class ReportsFilter extends React.Component {
  handleChange = (event, category) => {
    const { value } = event.target;
    const { filters } = this.props.reports;
    const target = filters[category].find((id) => id === value);
    if (target) return;

    const updatedFilters = { ...filters, [category]: [...filters[category], value] };

    this.updateFilters(updatedFilters);
  };

  handleDelete = (targetId, category) => {
    const { filters } = this.props.reports;
    const target = filters[category].find((id) => id === targetId);
    if (!target) return;

    const updatedFilters = { ...filters };
    // Remove all enterprises associated with target division
    if (
      category === 'divisions' &&
      updatedFilters.divisions.length > 1 &&
      updatedFilters.enterprises.length > 0
    ) {
      const { rows: enterprises } = this.props.storedEnterprises.enterprises;
      // Filter reducer stored enterprises by selected enterprise filters and target division
      const targetEnterpriseFiltersToRemove =
        enterprises
          .filter(
            ({ id, division_id }) =>
              updatedFilters.enterprises.includes(id) && division_id === targetId,
          )
          ?.map(({ id }) => id) ?? [];

      if (targetEnterpriseFiltersToRemove.length > 0) {
        updatedFilters.enterprises = updatedFilters.enterprises.filter(
          (id) => !targetEnterpriseFiltersToRemove.includes(id),
        );
      }
    }

    updatedFilters[category] = updatedFilters[category].filter((id) => id !== targetId);

    this.updateFilters(updatedFilters);
  };

  updateFilters = (filters = []) => {
    this.props.dispatch({ type: 'SET_REPORT_FILTERS', payload: filters });
  };

  render() {
    const { filterOpen, divisions = [], enterprises = [] } = this.props;
    const { filters } = this.props.reports;

    const divisionAttributes = {
      caption: 'Division',
      filterCount: filters.divisions.length,
      selection: filters.divisions,
    };

    const enterpriseAttributes = {
      caption: 'Enterprise',
      filterCount: filters?.enterprises?.length,
      selection: filters.enterprises,
    };

    // Filter enterprises by selected divisions
    const filteredEnterprises =
      divisionAttributes.filterCount > 0
        ? enterprises.filter(({ division_id }) =>
            divisionAttributes.selection.includes(division_id),
          )
        : enterprises;

    return (
      <>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={3} className="border border-light rounded bg-light m-1">
                <PillPicker
                  caption={divisionAttributes.caption}
                  handleChange={(event) => this.handleChange(event, 'divisions')}
                  handleDelete={(id) => this.handleDelete(id, 'divisions')}
                  rows={divisions}
                  selection={divisionAttributes.selection}
                  showPills={false}
                />
              </Col>
              <Col sm={3} className="border border-light rounded bg-light m-1">
                <PillPicker
                  caption={enterpriseAttributes.caption}
                  handleChange={(event) => this.handleChange(event, 'enterprises')}
                  handleDelete={(id) => this.handleDelete(id, 'enterprises')}
                  rows={filteredEnterprises}
                  selection={enterpriseAttributes.selection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {(divisionAttributes.filterCount > 0 || enterpriseAttributes.filterCount > 0) && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={divisionAttributes.caption}
              handleDelete={(id) => this.handleDelete(id, 'divisions')}
              rows={divisions}
              selection={divisionAttributes.selection}
              colourField="colour"
              category="divisions"
            />
            <PillPresenter
              caption={enterpriseAttributes.caption}
              handleDelete={(id) => this.handleDelete(id, 'enterprises')}
              rows={filteredEnterprises}
              selection={enterpriseAttributes.selection}
              colourField="colour"
              category="enterprises"
            />
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = ({ enterprises: storedEnterprises, reports }) => ({
  storedEnterprises,
  reports,
});

export default connect(mapStoreToProps)(ReportsFilter);
