import React, { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import {
  allAreTrue,
  deriveDollarAmount,
  derivePercentage,
  getValidationChecks,
} from '../lib/distributions';

const LivestockIncomeDistributionsLsv = (props) => {
  const { rows, onDistributionChange } = props;
  let { totalValue } = props;
  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let { classes, field } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) {
          parent = key && parent ? parent[key] : null;
          caption = parent;
        }
      }

      const cellAttributes = {
        index,
        classes,
        row,
        isHandlerEnabled: true,
      };

      let inputAttributes = {
        control: {
          id: `${index}-${field}`,
          name: field,
          value: caption,
        },
        disabled: false,
      };

      switch (field) {
        case 'distribution_pcnt':
          // Derive and update % amount
          const distribution_amount = row['distribution_amount'];
          if (
            allAreTrue(getValidationChecks(distribution_amount)) &&
            allAreTrue(getValidationChecks(totalValue))
          ) {
            caption = derivePercentage(totalValue, distribution_amount);
            inputAttributes = { ...inputAttributes, control: { value: caption } };
            row[field] = caption;
          }

          return getFormInputDataCell(
            { ...cellAttributes, isHandlerEnabled: false },
            { ...inputAttributes, disabled: true }
          );
        case 'distribution_amount':
          if (!row[field]) {
            // Derive and update $ amount
            const distribution_pcnt = row['distribution_pcnt'];
            if (
              allAreTrue(getValidationChecks(distribution_pcnt)) &&
              allAreTrue(getValidationChecks(totalValue))
            ) {
              caption = deriveDollarAmount(totalValue, distribution_pcnt);
              inputAttributes = { ...inputAttributes, control: { value: caption } };
              row[field] = caption;
            }
          }
          return getFormInputDataCell(cellAttributes, inputAttributes);
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const getFormInputDataCell = (cellAttributes, inputAttributes) => {
    const { index, classes, row, isHandlerEnabled } = cellAttributes;

    if (isHandlerEnabled)
      inputAttributes = { ...inputAttributes, handleChange: (event) => handleChange(event, row) };

    return (
      <td key={index} className={classes}>
        {<FormInput {...inputAttributes} />}
      </td>
    );
  };

  const handleChange = (event, row) => {
    const name = event.target.name;
    const value = parseFloat(event.target.value);

    // Set default values: display no values if input is empty
    row['distribution_amount'] = 0;
    row['distribution_pcnt'] = 0;

    // Validate value
    if (allAreTrue(getValidationChecks(value))) {
      // Display user input
      row[name] = value;
    }
    onDistributionChange();
    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Enterprise', field: 'enterprise.name', classes: 'text-right' },
    {
      caption: '$ Distribution',
      field: 'distribution_amount',
      classes: 'text-left',
    },
    {
      caption: '% Distribution',
      field: 'distribution_pcnt',
      classes: 'text-left',
    },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  const tableBodyTr = rows.map((row, index) => {
    return (
      <tr
        key={index}
        onClick={() => {
          setEditMode(row, true);
        }}
        style={{ cursor: 'pointer' }}
      >
        {renderRows(headers, row)}
      </tr>
    );
  });

  return <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />;
};

export default LivestockIncomeDistributionsLsv;
