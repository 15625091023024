import React, { useState } from 'react';
import moment from 'moment';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import ListviewCheckbox from '../../../core/form/components/ListviewCheckbox';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { noop } from 'lodash';

const SheepEnterpriseStockflowLsv = ({
  rows,
  selectedRanges,
  onRIChange = noop,
  onValuationChange = noop,
}) => {
  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const classes = `${header.classes}`;
      const { field, formattingRules } = header;
      let caption = row[field];
      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values())
          parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      let control = {
        id: `${index}-${field}`,
        name: field,
        value: caption,
        isEdit: false,
      };

      switch (field) {
        case 'animal_class_reproduction.is_reproductive': {
          delete control.isEdit;
          return (
            <td key={index} className={classes}>
              <ListviewCheckbox
                control={control}
                className={'ml-0 mr-0'}
                handleChange={(event) => handleCheckboxClick(event, row)}
              />
            </td>
          );
        }
        case 'adjustments.total': {
          // Display 0 if no adjustments
          if (!caption) {
            return (
              <td key={index} className={classes}>
                {0}
              </td>
            );
          }

          // Create tooltip for adjustments breakdown
          const { breakdown } = row.adjustments;
          let tooltip = ``;
          breakdown.forEach(
            (adjustment_type) =>
              (tooltip += `\n${adjustment_type.name}: ${adjustment_type.quantity}`),
          );

          // Return non editable field with breakdown title
          return (
            <td key={index} className={classes} title={tooltip}>
              {caption}
            </td>
          );
        }
        // Customise closing controls
        case 'closing.weight_per_head.value':
        case 'closing.value_per_unit.value':
        case 'closing.au_rating.value': {
          if (row[field]) {
            control = {
              ...control,
              ...row[field],
            };
          }
          // Get custom cell attributes
          const { title, updateClasses } = getCellAttributes(
            field,
            row.closing,
            selectedRanges?.to_date,
          );

          if (control.isEdit) {
            // Return field with handler methods
            return (
              <td key={index} className={classes}>
                {
                  <FormInput
                    className={'text-center p-0 slim'}
                    autoFocus={true}
                    handleChange={(event) => handleChange(event, row)}
                    handleKeyDown={(event) => handleKeyDown(event, field, row)}
                    handleBlur={() => handleBlur(field, row)}
                    control={control}
                  />
                }
              </td>
            );
          }

          // Check and apply formatting rules
          if (formattingRules)
            control.value = numberRuleFormat(control.value, formattingRules);

          // Return editable field
          return (
            <td
              key={index}
              className={`${classes} ${updateClasses}`}
              title={title}
              onClick={() => {
                setEditMode(field, row, true);
              }}
            >
              {control.value}
            </td>
          );
        }
        case 'closing.value_per_head': {
          // Get custom cell attributes
          const { title, updateClasses } = getCellAttributes(
            field,
            row.closing,
            selectedRanges?.to_date,
          );

          // Check and apply formatting rules
          if (formattingRules)
            control.value = numberRuleFormat(control.value, formattingRules);

          // Return non-editable field
          return (
            <td
              key={index}
              className={`${classes} ${updateClasses}`}
              title={title}
            >
              {control.value}
            </td>
          );
        }

        // Customise opening controls
        case 'opening.weight_per_head.value':
        case 'opening.value_per_unit.value':
        case 'opening.value_per_head':
        case 'opening.au_rating.value': {
          // Get custom cell attributes
          const { title, updateClasses } = getCellAttributes(
            field,
            row.opening,
            selectedRanges?.from_date,
          );

          // Check and apply formatting rules
          if (formattingRules)
            control.value = numberRuleFormat(control.value, formattingRules);

          // Return non-editable field
          return (
            <td
              key={index}
              className={`${classes} ${updateClasses}`}
              title={title}
            >
              {control.value}
            </td>
          );
        }
        case 'opening.quantity':
        case 'purchases.quantity':
        case 'purchases.value_per_head':
        case 'purchases.weight_per_head.value':
        case 'sales.quantity':
        case 'sales.value_per_head':
        case 'sales.weight_per_head.value':
        case 'enterprise.name':
        case 'transfers.in':
        case 'transfers.out':
        case 'closing.quantity': {
          let tooltip;
          let tooltip_classes;
          // Highlight any discrepancies
          if (field === 'closing.quantity' && caption < 0) {
            tooltip_classes = 'bg-danger text-white';
            tooltip = 'Negative Inventory';
          }

          // Check and apply formatting rules
          if (formattingRules)
            caption = numberRuleFormat(caption, formattingRules);

          // Return non-editable field
          return (
            <td
              key={index}
              className={`${classes} ${tooltip_classes}`}
              title={tooltip ?? null}
            >
              {caption}
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const handleCheckboxClick = (event, row) => {
    const { checked } = event.target;

    const confirmed = window.confirm(
      `About to update the Reproductive Index for "${row.enterprise.name} - ${row.name}". Continue?`,
    );
    if (confirmed) {
      onRIChange(checked, row);
      setChanged(!changed);
    }
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;

    row[name] = {
      ...row[name],
      value,
      change: true,
    };

    setChanged(!changed);
  };

  const handleKeyDown = (event, field, row) => {
    // If the enter key (13), nothing else
    if (event.keyCode === 13) setEditMode(field, row, false);
  };
  // On unfocus, turn off edit mode
  const handleBlur = (field, row) => setEditMode(field, row, false);

  const setEditMode = (name, row, isEdit = false) => {
    row[name] = {
      ...row[name],
      isEdit,
    };

    // If edit mode false and value has changed
    if (!isEdit && row[name].change) {
      onValuationChange(name, row);

      // Reset change to false
      row[name] = {
        ...row[name],
        change: false,
      };
    }

    setChanged(!changed);
  };

  const getCellAttributes = (field, valuation_values, compare_date) => {
    // Select and format dates
    let date;
    if (field.includes('weight')) date = valuation_values.weight_kg_date;
    else if (field.includes('value')) date = valuation_values.value_date;
    if (field.includes('au_rating_lsu')) date = valuation_values.au_rating_date;
    date = moment(date);
    compare_date = moment(compare_date);

    // Set title if date is valid
    let title = date._isValid ? `As at ${date.format('yyyy-MM-DD')}` : '';

    // Unit type object found.  Add the units being used to the display
    const splitFields = field.split('.');
    const unitObjectSplitDepth = 3; // Unit object will look something like sales.weight_per_head.value
    const unitValueIndex = 2; // index into the field splitting for the value of the unit object
    const unitNameIndex = 1; // index into the field splitting for the name of the unit object.

    if (
      splitFields.length === unitObjectSplitDepth &&
      splitFields[unitValueIndex] === 'value'
    ) {
      const unitValue = valuation_values[splitFields[unitNameIndex]].unit_type;
      title += `\nUnits: ${unitValue}`;
    }

    // Set custom classes
    let updateClasses = '';
    if (compare_date) {
      const diff = compare_date.diff(date, 'months');
      if (diff > 3) updateClasses = 'bg-warning text-white';
      if (diff > 6) updateClasses = 'bg-danger text-white';
    }

    return {
      title,
      updateClasses,
    };
  };

  const upperHeaders = [
    { caption: null, cells: 4, classes: 'border-bottom-0' },
    {
      caption: 'Opening',
      cells: 5,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    { caption: null, cells: 1, classes: 'border-bottom-0' },
    {
      caption: 'Purchases',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Sales',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    { caption: null, cells: 1, classes: 'border-bottom-0' },
    {
      caption: 'Transfer',
      cells: 2,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Closing',
      cells: 5,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
  ];

  const headers = [
    { caption: 'Property', field: 'property.name', classes: 'text-center' },
    { caption: 'Enterprise', field: 'enterprise.name', classes: 'text-center' },
    { caption: 'Animal Class', field: 'name', classes: 'text-center' },
    {
      caption: 'RI',
      field: 'animal_class_reproduction.is_reproductive',
      classes: 'text-center border-left w-70px',
    },
    {
      caption: 'AU Rating',
      field: 'opening.au_rating.value',
      classes: 'text-center border-left w-70px',
      formattingRules: { includeDecimals: true },
    },
    {
      caption: 'Number',
      field: 'opening.quantity',
      classes: 'text-center',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Weight (kg/head)',
      field: 'opening.weight_per_head.value',
      classes: 'text-center w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Value ($/kg)',
      field: 'opening.value_per_unit.value',
      classes: 'text-center w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Value ($/head)',
      field: 'opening.value_per_head',
      classes: 'text-center border-right w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Lambs Marked',
      field: 'branded',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Number',
      field: 'purchases.quantity',
      classes: 'text-center border-left',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Value ($/head)',
      field: 'purchases.value_per_head',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Weight (kg/head)',
      field: 'purchases.weight_per_head.value',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Number',
      field: 'sales.quantity',
      classes: 'text-center border-left',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Value ($/head)',
      field: 'sales.value_per_head',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Weight (kg/head)',
      field: 'sales.weight_per_head.value',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Adjustments',
      field: 'adjustments.total',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Out',
      field: 'transfers.out',
      classes: 'text-center border-left',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'In',
      field: 'transfers.in',
      classes: 'text-center border-left border-right',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'AU Rating',
      field: 'closing.au_rating.value',
      classes: 'text-center border-left w-70px',
      formattingRules: { includeDecimals: true },
    },
    {
      caption: 'Number',
      field: 'closing.quantity',
      classes: 'text-center',
      totals: true,
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Weight (kg/head)',
      field: 'closing.weight_per_head.value',
      classes: 'text-center w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: false,
      },
    },
    {
      caption: 'Value ($/kg)',
      field: 'closing.value_per_unit.value',
      classes: 'text-center w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Value ($/head)',
      field: 'closing.value_per_head',
      classes: 'text-center border-right w-70px',
      formattingRules: {
        includeCommas: true,
        includeDecimals: false,
        includeDollarSign: true,
      },
    },
  ];

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  const totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  if (rows?.rows) ({ rows } = rows);
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'sheep';
  const emptyCaption =
    'No transactions, breedings or transfers found. Have you got the right date range set?';

  return (
    <Listview
      rows={rows || []}
      tableHeadTh={tableHeadTh}
      upperHeadTh={upperHeadTh}
      tableBodyTr={tableBodyTr}
      totals={totals}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default SheepEnterpriseStockflowLsv;
