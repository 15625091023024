import axios from 'axios';

export function setAuthCookie() {
  const createCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    document.cookie = name + '=' + value + expires + '; path=/;';
  };

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      const cookies = document.cookie.replace(' ', '').split(';');
      cookies.forEach(function (element) {
        if (element.indexOf('x-access-token') >= 0) {
          const token = element.split('=')[1];
          config.headers.common['x-access-token'] = token;
        }
      });

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      const token = response.headers['x-auth-token'];

      // Store token expiry information in localstorage
      const expiryToken = response.headers['x-auth-token-expiry'];

      // jwt.iat is in seconds, Date expects milliseconds. Convert to milliseconds
      const parsedExpiryToken = parseInt(expiryToken, 10);
      if (!isNaN(parsedExpiryToken)) {
        localStorage.setItem('expiry', parsedExpiryToken);
      }

      if (token) {
        document.cookie = createCookie('x-access-token', token, 3600);
        axios.defaults.headers.common = { 'x-access-token': token };
      }
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401 && error.response.status === 403) {
        window.location.replace('/login');
      }
      return Promise.reject(error);
    },
  );
}
