import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Alert } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import Charts from '../components/LivestockEnterpriseCharts';
import GenericLsv from '../../../core/form/components/GenericLsv';
import OtherLivestockToolbar from '../components/otherLivestock/OtherLivestockToolbar';
import ExternalLink from '../components/ExternalLink';
import DirectCostModal from './DirectCostModal';
import LivestockOpeningModal from '../containers/LivestockOpeningModal';
import OtherLivestockEnterpriseStockflowLsv from '../components/OtherLivestockEnterpriseStockflowLsv';
import LivestockEnterprises from './LivestockEnterprises';
import LivestockIncomeModal from './LivestockIncomeModal';
import LivestockValueModal from './LivestockValueModal';
import OtherLivestockModal from './OtherLivestockModal';
import { fetchAttribute } from '../actions/attributes';
import LivestockPurchasesLsv from './LivestockPurchasesLsv';
import LivestockAdjustmentModal from './LivestockAdjustmentModal';

class OtherLivestockEnterprises extends LivestockEnterprises {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      modalType: null,
      isAnimalClassValueModalOpen: false,
      isModalOpen: false,
      isDirectCostModalOpen: false,
      isLivestockAdjustmentModalOpen: false,
      isLivestockIncomeModalOpen: false,
      isLivestockOpeningModalOpen: false,
      isLivestockValueModalOpen: false,
      modalData: null,
      division_id: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
  }

  componentDidMount() {
    this.props
      .dispatch(fetchAttribute({ type: 'divisions', tag: 'other_livestock' }))
      .then((data) => {
        const division_id = data.id;
        this.setState({ division_id });
      });
  }

  toggleTab(tab) {
    super.toggleTab(tab);

    if (this.props.livestocks.activeLiveStockTab !== tab) {
      this.props.dispatch({
        type: 'SET_LIVESTOCK_OTHER_LIVESTOCK_ACTIVE_TAB',
        payload: tab,
      });
    }
  }

  onRefresh() {
    const tab_id = this.props.livestocks.activeLiveStockTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  setModalOptions(type) {
    const options = super.setModalOptions(type);
    return options;
  }

  render() {
    const {
      id,
      modalType,
      isModalOpen,
      isDirectCostModalOpen,
      isLivestockAdjustmentModalOpen,
      isLivestockIncomeModalOpen,
      isLivestockOpeningModalOpen,
      isLivestockValueModalOpen,
      modalData,
      division_id,
    } = this.state;

    const { activeLiveStockTab, purchases, sales, adjustments, stockflows, responseMessage } =
      this.props.livestocks;

    const { selectedRanges } = this.props.enterprises;

    const { direct_costs } = this.props.direct_costs;
    const { values } = this.props.animal_classes;

    const modalOptions = this.setModalOptions(modalType);

    const title = 'Other Livestock Enterprises';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} />
        <Charts />
        <OtherLivestockToolbar
          setModal={this.setModal}
          onRefresh={this.onRefresh}
          setDirectCostModal={this.setDirectCostModal}
          setLivestockAdjustmentModal={this.setLivestockAdjustmentModal}
          setLivestockOpeningModal={this.setLivestockOpeningModal}
        />
        <DirectCostModal
          onRefresh={this.onRefresh}
          setModal={this.setDirectCostModal}
          id={id}
          isOpen={isDirectCostModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <LivestockOpeningModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockOpeningModal}
          id={id}
          isOpen={isLivestockOpeningModalOpen}
          division_id={division_id}
        />
        <LivestockIncomeModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockIncomeModal}
          id={id}
          isOpen={isLivestockIncomeModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <OtherLivestockModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <LivestockAdjustmentModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockAdjustmentModal}
          data={modalData}
          isOpen={isLivestockAdjustmentModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
        />
        <LivestockValueModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockValueModal}
          data={modalData}
          isOpen={isLivestockValueModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Stockflow"
            tabId="1"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            tabTag="stockflow"
          />
          <FormTab
            caption="Values"
            tabId="2"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            disabled={false}
            tabTag="values"
          />
          <FormTab
            caption="Purchases"
            tabId="3"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            disabled={false}
            tabTag="values"
          />
          <FormTab
            caption="Sales"
            tabId="4"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            tabTag="purchases"
          />
          <FormTab
            caption="Adjustments"
            tabId="5"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            tabTag="adjustments"
          />
          <FormTab
            caption="Direct Costs"
            tabId="8"
            activeTab={activeLiveStockTab}
            toggle={this.toggleTab}
            tabTag="costs"
          />
        </Nav>

        <TabContent activeTab={activeLiveStockTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <ExternalLink
              divClasses="m-2"
              hasIcon={true}
              href="https://aucalc.rcsaustralia.com.au/sheep"
              name="calculator"
              iconClasses="mr-2 text-corporate"
              title="AU Rating Calculator"
            />
            <OtherLivestockEnterpriseStockflowLsv
              rows={stockflows}
              onValuationChange={this.onValuationChange}
              selectedRanges={selectedRanges}
              onRIChange={this.onRIChange}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. Enter the
              enterprises and animal classes that are used on the stockflow page. Be consistent with
              enterprises and animal classes used on all Other Livestock input tabs.
            </Alert>
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(valueId, { animal_class_id }) => {
                this.setLivestockValueModal(true, valueId, animal_class_id);
              }}
              rows={values}
            />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. Enter the
              enterprises and animal classes that are used on the stockflow page. Be consistent with
              enterprises and animal classes used on all Other Livestock input tabs.
            </Alert>
            <LivestockPurchasesLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other livestock purchases found"
              onClick={(purchaseId, { animal_class_id }) => {
                this.setModal(true, 'transaction', purchaseId, animal_class_id);
              }}
              rows={purchases}
            />
          </TabPane>
          <TabPane tabId="4" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. Enter the
              enterprises and animal classes that are used on the stockflow page. Be consistent with
              enterprises and animal classes used on all Other Livestock input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other livestock sales found"
              onClick={(saleId, { animal_class_id }) => {
                this.setModal(true, 'sales', saleId, animal_class_id);
              }}
              rows={sales}
            />
          </TabPane>
          <TabPane tabId="5" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. Enter the
              enterprises and animal classes that are used on the stockflow page. Be consistent with
              enterprises and animal classes used on all Other Livestock input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other livestock adjustments found"
              onClick={(adjustmentId) => this.setLivestockAdjustmentModal(true, adjustmentId)}
              rows={adjustments}
            />
          </TabPane>
          <TabPane tabId="8" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No direct costs found"
              onClick={(directCostId, { animal_class_id }) => {
                this.setDirectCostModal(true, directCostId, animal_class_id);
              }}
              rows={direct_costs}
              totalFormattingRules={{
                includeDollarSign: true,
                includeCommas: true,
              }}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  livestocks,
  realm,
}) => ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  livestocks,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(OtherLivestockEnterprises));
