import React, { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { getFormattedValue } from '../lib/metricFormat';

const Tree = (props) => {
  const { items, load = noop } = props;

  const treeItems = items?.map((item, index) => <TreeItem key={index} item={item} load={load} />);

  return <div className="m-2">{treeItems}</div>;
};

export const TreeItem = (props) => {
  const { item, load } = props;

  const [treeState, setTreeState] = useState({
    isOpen: false,
    iconName: item.endLeaf ? 'function' : 'plus',
    iconColour: item.endLeaf ? 'red' : 'blue',
  });

  const [subMetrics, setSubMetrics] = useState([]);

  const onClick = async () => {
    if (!item.endLeaf) {
      const toggleValue = treeState.isOpen
        ? { ...treeState, isOpen: false, iconName: 'plus' }
        : { ...treeState, isOpen: true, iconName: 'minus' };
      setTreeState(toggleValue);

      // load parent subMetrics and set in state
      if (item.id) {
        setSubMetrics(await load(item.id));
      }
    }
  };

  const treeItems = subMetrics?.map((subMetric, index) => (
    <TreeItem key={index} item={subMetric} load={load} />
  ));

  let iconStyle = { color: treeState.iconColour };
  let backgroundClass = 'bg-light';
  let textColour = 'text-black';
  let displayValue = item?.value || getFormattedValue(item).formattedValue;

  if (item.containsError) {
    iconStyle = { color: 'white' };
    backgroundClass = 'bg-danger';
    textColour = 'text-white';
    displayValue = 'Error';
  }

  return (
    <div className="ml-3 p-1">
      <div className={`d-flex justify-content-between ${backgroundClass}`}>
        <div className="d-flex justify-content-start">
          <Icon
            name={treeState.iconName}
            className="mt-1 ml-2"
            onClick={onClick}
            style={iconStyle}
          />
          <div className={`${textColour} ml-2`} role="presentation" onClick={onClick}>
            {item.name}
          </div>
        </div>
        <div className={`${textColour} mr-2`}>{displayValue}</div>
      </div>

      <div>
        {treeState.isOpen && (
          <div>
            {item.formulaDescription && (
              <small className="ml-3 bg-light text-success">
                FORMULA: {item.formulaDescription}
              </small>
            )}
            {treeItems}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tree;
