import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, CustomInput, Label, FormGroup } from 'reactstrap';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import Icon from 'jsx/components/core/icons/Icon';
import { fetchEnterpriseRanges } from 'jsx/components/modules/portrait/actions/enterprises';
import FormBase from '../../core/form/components/FormBase';
import { saveControls, updateControlOptions } from '../../core/form/lib/validateForm';
import { controls as toolbarControlsDefn } from '../forms/report_toolbar';
import { controls as previewControlsDefn } from '../forms/report_preview_toolbar';
import { controls as reportControlsDefn } from '../forms/reports';
import ReportToolbar from '../components/ReportToolbar';
import ReportPreviewToolbar from '../components/ReportPreviewToolbar';
import Report from '../../modules/portrait/components/analytics/Report';
import MetricModal from '../../modules/portrait/containers/MetricModal';
import ReportMetricsAttributeModal from './ReportMetricsAttributeModal';
import ReportMetricsSelectModal from './ReportMetricsSelectModal';

import FormInput from '../../core/form/components/FormInput';

import ResponseMessage from '../../core/form/components/ResponseMessageTab';
import {
  fetchLookupMetrics,
  fetchReport,
  updateReport,
  fetchReportVersions,
  createVersion,
  fetchReportVersion,
  createReportSection,
  updateReportSection,
  removeReportSection,
  createReportSectionRow,
  updateReportSectionRow,
  removeReportSectionRow,
  reorderReportSectionRows,
} from '../../modules/portrait/actions/reports';

import { fetchOrgs } from '../actions';

class ReportPreview extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      previewControls: cloneDeep(previewControlsDefn),
      reportControls: cloneDeep(reportControlsDefn),
      toolbarControls: cloneDeep(toolbarControlsDefn),

      client_id: null,
      col_id: null,
      data: {},

      isAttributeModalOpen: false,
      isClientSelected: false,
      isSelectModalOpen: false,
      isMetricModalOpen: false,

      metric_id: null,
      modalMetric: {},
      reportVersion: {},
      showReportEdit: false,
      version_id: null,
      currentReloadValue: null,
      versionOptions: [],
    };

    this.createVersion = this.createVersion.bind(this);
    this.updateVersionOptions = this.updateVersionOptions.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.createReportSection = this.createReportSection.bind(this);
    this.updateSection = this.updateSection.bind(this);
    this.removeSection = this.removeSection.bind(this);
    this.createSectionRow = this.createSectionRow.bind(this);
    this.updateSectionRow = this.updateSectionRow.bind(this);
    this.reorderSectionRows = this.reorderSectionRows.bind(this);
    this.removeSectionRow = this.removeSectionRow.bind(this);
    this.setSelectModal = this.setSelectModal.bind(this);
    this.setAttributeModal = this.setAttributeModal.bind(this);
    this.loadReportVersion = this.loadReportVersion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClientSelect = this.onClientSelect.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.handleReportEditableChange = this.handleReportEditableChange.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.onSaveReport = this.onSaveReport.bind(this);
    this.handleReportChange = this.handleReportChange.bind(this);
    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
    this.setMetricModal = this.setMetricModal.bind(this);
    this.publishReportVersion = this.publishReportVersion.bind(this);
  }

  async componentDidMount() {
    let { data } = this.state;

    // Fetch report structure
    const { id } = this.props.match.params;
    data = await this.props.dispatch(fetchReport(id));
    this.setState({ data });

    // Fetch report lookup metrics
    await this.props.dispatch(fetchLookupMetrics());

    // Get All versions for report
    await this.props.dispatch(fetchReportVersions(id));
    this.updateVersionOptions();

    // Get all clients for preview filter
    this.loadClients();

    // Set default date ranges
    this.props.dispatch(fetchEnterpriseRanges());
  }

  componentDidUpdate(prevProps) {
    const { versions } = this.props.reports;

    if (prevProps.reports.versions.length !== versions.length) this.updateVersionOptions();
  }

  async publishReportVersion() {
    const { version_id } = this.state;

    /* eslint-disable-next-line no-alert */
    const confirmed = window.confirm('Make this version the published version of the report?');
    if (confirmed) {
      const { id } = this.props.match.params;

      const data = { published_version_id: version_id, enabled: true };
      await this.props.dispatch(updateReport(id, data));

      this.updateVersionOptions();
    }
  }

  setMetricModal(isMetricModalOpen, modalMetric) {
    this.setState({
      isMetricModalOpen,
      modalMetric,
    });
  }

  async onSaveReport() {
    const { reportControls } = this.state;
    const { id } = this.props.match.params;

    const data = {
      name: reportControls.name.value,
      requires_subscription: reportControls.requiresSubscription.value,
    };
    await this.props.dispatch(updateReport(id, data));

    this.setState({ data });

    await this.props.dispatch(fetchReport(id));
    this.toggleEdit();
  }

  handleReportChange(event) {
    const { reportControls } = this.state;

    reportControls.name.value = event.target.value;

    this.setState({ reportControls });
  }

  handleSubscriptionChange(event) {
    const { reportControls } = this.state;

    reportControls.requiresSubscription.value = event.target.checked;

    this.setState({ reportControls });
  }

  toggleEdit() {
    const { reportControls, data } = this.state;

    reportControls.name.value = data.name;
    reportControls.requiresSubscription.value = data.requires_subscription;
    this.setState({ showReportEdit: !this.state.showReportEdit });
  }

  updateVersionOptions() {
    const { versions } = this.props.reports;
    const { data, version_id } = this.state;
    let { toolbarControls } = this.state;

    const versionOptions = versions.map(({ id, version }) => {
      const published = data.published_version_id === id ? '(Published)' : '';
      return { id, name: `Ver. ${version} ${published}` };
    });
    toolbarControls = updateControlOptions(toolbarControls, 'version', versionOptions);
    toolbarControls.version.value = version_id;

    this.setState({
      versionOptions,
      toolbarControls,
    });
  }

  async loadClients() {
    const { previewControls, client_id } = this.state;

    const { params } = this.props.manage;
    const clients = await this.props.dispatch(fetchOrgs(params));

    previewControls.client_id.options = clients.map(({ id, name }) => ({ id, name }));
    previewControls.client_id.value = client_id;

    this.setState({
      previewControls,
    });
  }

  onChange(event) {
    const { name, value } = event.target;

    if (name === 'client_id') {
      const { previewControls } = this.state;
      previewControls.client_id.value = value;
      this.setState({ isClientSelected: false, previewControls });
    }
  }

  onClientSelect({ id, name }) {
    const { previewControls } = this.state;
    previewControls.client_id.value = name;

    this.props.dispatch({ type: 'SET_PREVIEW_CLIENT_FULFILLED', payload: id });
    this.setState({ isClientSelected: true, previewControls });
  }

  async onDateChange(type, value) {
    const range = {};

    let date = moment(value);
    switch (type) {
      case 'to_date': {
        date = date.endOf('month');
        break;
      }
      case 'from_date':
      default: {
        date = date.startOf('month');
        break;
      }
    }

    range[type] = date.toISOString(true);
    await this.props.dispatch({ type: 'SET_ENTERPRISE_SELECTED_RANGES', payload: range });
  }

  handleReportEditableChange(checked) {
    this.props.dispatch({ type: 'SET_REPORT_EDITABLE_FULFILLED', payload: checked });
  }

  setSelectModal(isSelectModalOpen, col_id, metric_id = null) {
    this.setState({ isSelectModalOpen, col_id, metric_id });
  }

  setAttributeModal(isAttributeModalOpen) {
    this.setState({ isAttributeModalOpen });
  }

  async createVersion({ clone_from } = {}) {
    const { id } = this.props.match.params;
    const version = await this.props.dispatch(createVersion(id, { clone_from }));

    this.setState({ version, version_id: version.id }, async () => {
      this.props.dispatch(fetchReportVersions(id));
      this.loadReportVersion();
    });
  }

  async loadReportVersion() {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion, currentReloadValue: moment().valueOf() });
  }

  // Report Section functions
  async createReportSection(controls) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    const unsavedData = saveControls(controls, {});
    await this.props.dispatch(createReportSection(report_id, version_id, unsavedData));

    this.loadReportVersion();
  }

  async removeSection(id) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    await this.props.dispatch(removeReportSection(report_id, version_id, id));

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  async updateSection(id, controls) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    const unsavedData = saveControls(controls, {});

    // Hack, need to do better in the section component
    delete unsavedData.row_type;

    await this.props.dispatch(updateReportSection(report_id, version_id, id, unsavedData));

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  // Report Section Row functions
  async createSectionRow(section_id) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    await this.props.dispatch(createReportSectionRow(report_id, version_id, section_id));

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  async removeSectionRow(section_id, id) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    await this.props.dispatch(removeReportSectionRow(report_id, version_id, section_id, id));

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  async updateSectionRow(section_id, id, controls) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    const unsavedData = saveControls(controls, {});
    await this.props.dispatch(
      updateReportSectionRow(report_id, version_id, section_id, id, unsavedData),
    );

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  async reorderSectionRows(section_id, data) {
    const report_id = this.props.match.params.id;
    const { version_id } = this.state;

    await this.props.dispatch(reorderReportSectionRows(report_id, version_id, section_id, data));

    const reportVersion = await this.props.dispatch(fetchReportVersion(report_id, version_id));
    this.setState({ reportVersion });
  }

  async handleVersionChange(event) {
    const { value } = event.target;
    const { toolbarControls } = this.state;
    let { version_id, reportVersion } = this.state;

    const report_id = this.props.match.params.id;

    version_id = value === '-' ? null : value;
    toolbarControls.version.value = version_id;

    // Get the full report version
    reportVersion = version_id
      ? await this.props.dispatch(fetchReportVersion(report_id, version_id))
      : {};

    this.setState({ toolbarControls, version_id, reportVersion });
  }

  render() {
    const {
      toolbarControls,
      previewControls,
      reportControls,
      col_id,
      data,
      isAttributeModalOpen,
      isClientSelected,
      isMetricModalOpen,
      isSelectModalOpen,
      metric_id,
      modalMetric,
      showReportEdit,
      reportVersion,
      version_id,
      currentReloadValue,
      versionOptions,
    } = this.state;

    const { selectedRanges } = this.props.enterprises;
    const { responseMessage, reportEditable } = this.props.reports;

    const emptyCaption = 'No Report Version selected...';
    const functions = {
      updateSection: this.updateSection,
      removeSection: this.removeSection,
      updateSectionRow: this.updateSectionRow,
      reorderSectionRows: this.reorderSectionRows,
      removeSectionRow: this.removeSectionRow,
      createSectionRow: this.createSectionRow,
      setSelectModal: this.setSelectModal,
      setAttributeModal: this.setAttributeModal,
      loadReportVersion: this.loadReportVersion,
      setMetricModal: this.setMetricModal,
    };

    return (
      <div>
        <div className="p-0 m-2">
          <div className="ml-1 d-flex justify-content-start">
            <Button size="sm" color="success" onClick={this.props.history.goBack}>
              <Icon size="1x" name="chevron-left" className="mr-2" />
              Back
            </Button>
            {showReportEdit ? (
              <div className="d-flex justify-content-start ml-2 bg-light rounded p-1 m-1 border border-lightgray">
                <FormInput
                  handleChange={this.handleReportChange}
                  control={reportControls.name}
                  hideCaption
                  className="px-3"
                />
                <FormGroup check inline>
                  <CustomInput
                    type="switch"
                    role="switch"
                    name={reportControls.requiresSubscription.name}
                    id={reportControls.requiresSubscription.name}
                    checked={reportControls.requiresSubscription.value}
                    onChange={this.handleSubscriptionChange}
                    className="ml-3"
                  />
                  <Label
                    for={reportControls.requiresSubscription.name}
                    className="font-weight-bold mb-0"
                  >
                    Requires subscription
                  </Label>
                </FormGroup>
                <div className="d-flex">
                  <Icon
                    name="check"
                    className="text-success mr-3 mt-2"
                    onClick={this.onSaveReport}
                  />
                  <Icon name="x-mark" className="text-danger mr-3 mt-2" onClick={this.toggleEdit} />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <h5 className="m-1 ml-2">{`Report: ${data.name}`}</h5>
                <h5 className="m-1 ml-2">
                  {data.requires_subscription
                    ? '(Requires subscription)'
                    : '(Available to all clients)'}
                </h5>

                <Icon
                  name="pen-to-square"
                  className="text-success m-2"
                  onClick={this.toggleEdit}
                  style={{ cursor: 'position' }}
                />
              </div>
            )}
          </div>

          {responseMessage && <ResponseMessage responseMessage={responseMessage} />}
          <Row>
            <Col>
              <ReportPreviewToolbar
                controls={previewControls}
                isClientSelected={isClientSelected}
                onChange={this.onChange}
                onClientSelect={this.onClientSelect}
                onDateChange={this.onDateChange}
                selectedRanges={selectedRanges}
              />
            </Col>
            <Col>
              <ReportToolbar
                controls={toolbarControls}
                handleVersionChange={this.handleVersionChange}
                createVersion={this.createVersion}
                handleReportEditableChange={this.handleReportEditableChange}
                reportEditable={reportEditable}
                loadReportVersion={this.loadReportVersion}
                publishReportVersion={this.publishReportVersion}
                versionOptions={versionOptions}
              />
            </Col>
          </Row>
          {!version_id && (
            <div className="p-5 text-center">
              <div>
                <Icon size="3x" name="code-branch" className="text-corporate" />
              </div>
              <div className="mt-3">{emptyCaption}</div>
            </div>
          )}
          {version_id && (
            <div className="m-1 p-0">
              <Report
                data={reportVersion}
                attributes={{ reportEditable }}
                functions={functions}
                createSection={this.createReportSection}
                currentReloadValue={currentReloadValue}
              />
              <ReportMetricsSelectModal
                isOpen={isSelectModalOpen}
                setModal={this.setSelectModal}
                col_id={col_id}
                metric_id={metric_id}
                loadReportVersion={this.loadReportVersion}
              />
              <ReportMetricsAttributeModal
                isOpen={isAttributeModalOpen}
                setModal={this.setAttributeModal}
              />
              <MetricModal
                setModal={this.setMetricModal}
                id={modalMetric?.id}
                metric={modalMetric}
                orgId={modalMetric?.org_id}
                isOpen={isMetricModalOpen}
                isNew={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ enterprises, manage, reports }) => ({
  enterprises,
  manage,
  reports,
});

export default connect(mapStoreToProps)(ReportPreview);
