import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Nav, TabContent, TabPane, Col } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import { updateControls, initControls, saveControls } from '../../../core/form/lib/validateForm';

import CostcodesLsv from '../../../manage/components/CostcodesLsv';
import AttributeDefsLsv from '../../../manage/components/AttributeDefsLsv';

import TimekeeperTimesheetsLsv from '../components/TimekeeperTimesheetsLsv';
import TimekeeperTimesheetNew from '../components/TimekeeperTimesheetNew';
import TimekeeperWeekBar from '../components/TimekeeperWeekBar';
import TimekeeperSummariesLsv from '../components/TimekeeperSummariesLsv';
import TimekeeperTimerLsv from '../components/TimekeeperTimerLsv';
import TimekeeperTimerBar from '../components/TimekeeperTimerBar';
import TimekeeperLeaveLsv from '../components/TimekeeperLeaveLsv';

import TimekeeperLeaveModal from './TimekeeperLeaveModal';
import TimekeeperTimerModal from './TimekeeperTimerModal';

import { controls } from '../forms/timekeeper';

import TimeFormat from '../lib/timeFormat';

import {
  fetchTimesheets,
  fetchTimesheetSummaries,
  fetchLeaves,
  createTimesheet,
  downloadCsv,
  downloadApproverReport,
  bulkApproveTimesheets,
  updateTimesheet,
} from '../actions';

import {
  fetchCostcodes,
  fetchLeavecodes,
  fetchOrgs,
  fetchAttributeDefs,
  uploadCostCodes,
} from '../../../manage/actions';
import TimekeeperSummaryModal from './TimekeeperSummaryModal';

class TimeKeeper extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls,
      showTimesheetEditor: false,
      isCostCodeOpen: false,
      user: {},
      isNew: false,
      isLeaveNew: false,
      isTimerModalOpen: false,
      data: {},
      week: {},
      summarySelection: [],
      id: null,
      summaryId: null,
      leaveId: null,
      isSummaryModalOpen: false,
      isLeaveModalOpen: false,
    };

    this.format = new TimeFormat();

    this.toggleTab = this.toggleTab.bind(this);
    this.showTimesheetEditor = this.showTimesheetEditor.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.setWeek = this.setWeek.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setTimerModal = this.setTimerModal.bind(this);
    this.setSummaryModal = this.setSummaryModal.bind(this);
    this.setLeaveModal = this.setLeaveModal.bind(this);
    this.onTimerEdit = this.onTimerEdit.bind(this);
    this.onLeaveEdit = this.onLeaveEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveLeave = this.onSaveLeave.bind(this);
    this.onAddLeave = this.onAddLeave.bind(this);
    this.onSummarySelect = this.onSummarySelect.bind(this);
    this.onSummaryView = this.onSummaryView.bind(this);
    this.loadTimesheets = this.loadTimesheets.bind(this);
    this.loadSummaries = this.loadSummaries.bind(this);
    this.renderWeekDayTotals = this.renderWeekDayTotals.bind(this);
    this.handleDownloadCsv = this.handleDownloadCsv.bind(this);
    this.handleDownloadApproverReport = this.handleDownloadApproverReport.bind(this);
    this.handleUploadCostCodes = this.handleUploadCostCodes.bind(this);
    this.handleTimesheetsApproval = this.handleTimesheetsApproval.bind(this);
    this.handleStartTimer = this.handleStartTimer.bind(this);
    this.handleStopAllTimers = this.handleStopAllTimers.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchCostcodes());
    this.props.dispatch(fetchLeavecodes());
    this.props.dispatch(fetchOrgs({ user_orgs: true }));
  }

  componentDidUpdate() {
    const { user, week } = this.state;
    const { currentUser } = this.props.profile;
    const { currentWeek } = this.props.office;

    if (
      currentUser.id &&
      (user.id !== currentUser.id || currentWeek.from_date !== week.from_date)
    ) {
      this.setState({
        user: currentUser,
        week: currentWeek,
      });

      this.loadTimesheets();
    }
  }

  async setWeek(increment) {
    await this.props.dispatch({ type: 'UPDATE_CURRENT_WEEK', payload: increment });

    this.loadTimesheets();
    this.loadSummaries();
    this.loadLeaves();
  }

  loadTimesheets() {
    const { currentUser } = this.props.profile;
    const { currentWeek } = this.props.office;

    this.props.dispatch(
      fetchTimesheets({
        ...currentWeek,
        user_id: currentUser.id,
      }),
    );
  }

  loadSummaries() {
    const { currentWeek } = this.props.office;
    this.props.dispatch(fetchTimesheetSummaries(currentWeek));
  }

  loadLeaves() {
    const { currentUser } = this.props.profile;
    const { currentWeek } = this.props.office;
    this.props.dispatch(
      fetchLeaves({
        ...currentWeek,
        user_id: currentUser.id,
      }),
    );
  }

  showTimesheetEditor(showEditor, data) {
    let { isNew, controls } = this.state;
    this.setState({ showNewTimesheet: showEditor });

    // Set data
    isNew = true;
    this.setState({ controls: initControls(this.state.controls) });

    if (data.id) {
      isNew = false;
      controls = updateControls(controls, data);
      this.setState({ controls });
    }

    this.setState({ isNew });
  }

  toggleTab(tab, tag) {
    switch (tag) {
      case 'mytimer':
      case 'mytimesheets': {
        this.loadTimesheets();
        break;
      }
      case 'summaries': {
        this.loadSummaries();
        break;
      }
      case 'myleave': {
        this.loadLeaves();
        break;
      }
      case 'attributes': {
        this.props.dispatch(fetchAttributeDefs());
        break;
      }
      case 'costcodes': {
        this.props.dispatch(fetchCostcodes());
        break;
      }
      default: {
        break;
      }
    }

    this.props.dispatch({ type: 'SET_ACTIVETAB_TIMEKEEPER', payload: tab });
  }

  setModal(isCostCodeOpen) {
    this.setState({ isCostCodeOpen });
  }

  async onSave() {
    let { data } = this.state;
    const { currentUser } = this.props.profile;
    const { currentWeek } = this.props.office;

    data = saveControls(controls, data);
    const success = await this.props.dispatch(createTimesheet(data));
    if (success) {
      this.props.dispatch(
        fetchTimesheets({
          ...currentWeek,
          user_id: currentUser.id,
        }),
      );
    }
  }

  onSaveLeave() {}

  renderWeekDayTotals() {
    const { timesheets } = this.props.office;

    if (timesheets && timesheets.totals) {
      const keys = Object.keys(timesheets.totals).filter((total) => total !== 'total');
      return keys.map((key, index) => (
        <Col key={index}>
          <span className="text-white bg-corporate p-2 mr-2">{key}</span>
          <span>{timesheets.totals[key]}</span>
        </Col>
      ));
    }
  }

  async handleDownloadCsv(onProgressChange) {
    const { currentWeek } = this.props.office;
    return await this.props.dispatch(
      downloadCsv(
        {
          timezone: moment().format('Z'),
          ...currentWeek,
        },
        onProgressChange,
      ),
    );
  }

  async handleDownloadApproverReport(onProgressChange) {
    const { currentWeek } = this.props.office;
    return await this.props.dispatch(
      downloadApproverReport(
        {
          timezone: moment().format('Z'),
          ...currentWeek,
        },
        onProgressChange,
      ),
    );
  }

  async handleUploadCostCodes(event, onProgressChange) {
    if (event.target.files.length === 0) return false;

    const confirmed = window.confirm(
      'This will disable any cost/phase codes which are not included in this upload. Are you sure you wish to proceed?',
    );
    if (confirmed) {
      const [file] = event.target.files;
      const formData = new FormData();
      formData.append('document', file);

      const success = await this.props.dispatch(uploadCostCodes(formData, onProgressChange));
      if (success) this.props.dispatch(fetchCostcodes());
    }
  }

  selectDate(selectedDate) {
    this.props.dispatch({ type: 'SET_SELECTED_DATE', payload: selectedDate });
  }

  setTimerModal(isTimerModalOpen, isNew) {
    this.setState({
      isTimerModalOpen,
      isNew,
    });
  }

  setLeaveModal(isLeaveModalOpen, isLeaveNew) {
    this.setState({
      isLeaveModalOpen,
      isLeaveNew,
    });
  }

  setSummaryModal(isSummaryModalOpen) {
    this.setState({ isSummaryModalOpen });
  }

  onTimerEdit(id, isNew) {
    this.setState({ id });
    this.setTimerModal(true, isNew);
  }

  onLeaveEdit(leaveId) {
    this.setState({ leaveId });
    this.setLeaveModal(true, false);
  }

  onSummarySelect(event) {
    const { summarySelection } = this.state;

    const state = event.target.checked;
    const id = event.target.value;
    if (state) {
      summarySelection.push(id);
    } else {
      const idx = summarySelection.findIndex((summary_id) => summary_id === id);
      if (idx > -1) {
        summarySelection.splice(idx, 1);
      }
    }

    this.setState({
      summarySelection,
    });
  }

  onSummaryView(summaryId) {
    this.setState({ summaryId });
    this.setSummaryModal(true);
  }

  async handleTimesheetsApproval(is_approved, user_id) {
    const { summarySelection } = this.state;

    const { currentWeek } = this.props.office;

    if (summarySelection.length === 0 && !user_id) {
      alert('You must select at least one person before approving/rejecting.');
      return;
    }

    let user_ids = summarySelection;
    if (user_id) user_ids = [user_id];

    const success = await this.props.dispatch(
      bulkApproveTimesheets({
        ...currentWeek,
        is_approved,
        user_ids,
        approved_at: moment().format(this.format.output),
      }),
    );

    if (success) {
      this.setState({ summarySelection: [] });
      this.loadSummaries();
    }

    return success;
  }

  onAddLeave() {
    this.setState({ leaveId: null });
    this.setLeaveModal(true, true);
  }

  async handleStartTimer(newData) {
    const success = await this.props.dispatch(createTimesheet(newData));

    if (success) {
      // Set week will ensure we're on the correct day, and reload the timesheets
      this.setWeek();
    }

    return success;
  }

  /**
   * Stops all timers in the current day. If excludeId provided that timesheet will not be modified
   * @param {string} excludeId
   */
  async handleStopAllTimers(excludeId = null) {
    const { timesheets } = this.props.office;

    const timesheetsWithActiveTimers = timesheets.rows
      .filter(({ to_date }) => !to_date)
      .filter(({ id }) => id !== excludeId);

    if (timesheetsWithActiveTimers.length > 0) {
      const response = await Promise.all(
        timesheetsWithActiveTimers.map((currentTimesheet) => {
          currentTimesheet.to_date = moment().format(this.format.output);
          return this.props.dispatch(updateTimesheet(currentTimesheet));
        }),
      );
      this.loadTimesheets();
      return response;
    }
  }

  render() {
    const {
      showNewTimesheet,
      isTimerModalOpen,
      isLeaveModalOpen,
      isNew,
      isLeaveNew,
      id,
      summarySelection,
      summaryId,
      leaveId,
      isSummaryModalOpen,
    } = this.state;

    const { currentWeek, timesheets, summaries, leaves, selectedDate, activeTabTimekeeper } =
      this.props.office;

    const { costcodes, attributeDefs } = this.props.manage;

    const { currentUser } = this.props.profile;

    const totalHours =
      timesheets?.rows?.length > 0
        ? timesheets.rows.map((timesheet) => timesheet.net_hours).reduce((a, b) => a + b)
        : 0;
    const budgetHours =
      timesheets?.rows?.length > 0 ? timesheets.rows[0].user.week_budget_hours : 0;

    const iconName = 'stopwatch';
    const totalWeekClassName = totalHours < budgetHours ? 'text-danger' : 'text-success';
    const budgetWeek = `Budget Week: ${this.format.asString(budgetHours)} hrs`;
    const totalWeek = `Week Total:  ${this.format.asString(totalHours)} hrs`;

    const omEnabled = this.checkAccess('officeManagerView');
    const summaryTabEnabled = omEnabled || this.checkAccess('teamSummary');

    return (
      <div className="p-2" data-sentry-block>
        {!this.props.hideHeader && (
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row m-2">
              <Icon size="2x" name={iconName} className="appForeTint mr-3" />
              <h3>Time Keeper</h3>
            </div>
          </div>
        )}
        {!this.props.hideTabs && (
          <Nav tabs className="mt-2">
            <FormTab
              iconRightClass="primary"
              iconRightName="stopwatch"
              caption="My Timer"
              tabId="1"
              tabTag="mytimer"
              activeTab={activeTabTimekeeper}
              toggle={this.toggleTab}
            />
            <FormTab
              iconRightClass="primary"
              iconRightName="calendar"
              caption="My Timesheets"
              tabId="2"
              tabTag="mytimesheets"
              activeTab={activeTabTimekeeper}
              toggle={this.toggleTab}
            />
            <FormTab
              iconRightClass="success"
              iconRightName="house-person-leave"
              caption="My Leave"
              tabId="3"
              tabTag="myleave"
              activeTab={activeTabTimekeeper}
              toggle={this.toggleTab}
            />
            <FormTab
              iconRightClass="primary"
              iconRightName="users"
              caption="Team Summary"
              tabId="5"
              tabTag="summaries"
              activeTab={activeTabTimekeeper}
              toggle={this.toggleTab}
              disabled={!summaryTabEnabled}
            />
            <FormTab
              iconRightClass="primary"
              iconRightName="gear"
              caption="Cost Codes"
              tabId="6"
              tabTag="costcodes"
              activeTab={activeTabTimekeeper}
              toggle={this.toggleTab}
              disabled={!omEnabled}
            />
            <FormTab
              iconRightClass="primary"
              iconRightName="tag"
              caption="Custom Attributes"
              tabId="7"
              activeTab={activeTabTimekeeper}
              tabTag="attributes"
              toggle={this.toggleTab}
              disabled={!omEnabled}
            />
          </Nav>
        )}
        <TabContent activeTab={activeTabTimekeeper}>
          <TabPane tabId="1" className="mb-2 p-3">
            <TimekeeperTimerBar
              selectDate={this.selectDate}
              selectedDate={selectedDate}
              onEdit={this.onTimerEdit}
              setWeek={this.setWeek}
              week={currentWeek}
              timesheets={timesheets}
              reload={this.loadTimesheets}
            />
            <TimekeeperTimerLsv
              rows={timesheets.rows}
              selectedDate={selectedDate}
              onEdit={this.onTimerEdit}
              handleStartTimer={this.handleStartTimer}
              handleStopAllTimers={this.handleStopAllTimers}
            />
            <TimekeeperTimerModal
              setModal={this.setTimerModal}
              setWeek={this.setWeek}
              id={id}
              isOpen={isTimerModalOpen}
              isNew={isNew}
              onSave={this.onSave}
              onRefresh={this.loadTimesheets}
              handleStopAllTimers={this.handleStopAllTimers}
            />
          </TabPane>

          <TabPane tabId="2" className="mb-2 p-3">
            <TimekeeperWeekBar
              reload={this.loadTimesheets}
              showEditor={this.showTimesheetEditor}
              week={currentWeek}
              setWeek={this.setWeek}
              showNewButton
              showExportButtons={false}
            />
            {showNewTimesheet && (
              <TimekeeperTimesheetNew
                onSave={this.onSave}
                costcodes={costcodes}
                controls={controls}
                handleChange={this.handleChange}
              />
            )}

            <div className="d-flex justify-content-end p-2 mt-2 text-right">
              <h5 className={totalWeekClassName}>
                <span>{budgetWeek}</span>
                <br />
                <span>{totalWeek}</span>
              </h5>
            </div>

            <TimekeeperTimesheetsLsv rows={timesheets.rows} onEdit={this.onTimerEdit} />
          </TabPane>

          <TabPane tabId="3" className="mb-2 p-3">
            <TimekeeperWeekBar
              reload={this.loadLeaves}
              showEditor={false}
              week={currentWeek}
              setWeek={this.setWeek}
              showNewButton
              showExportButtons={false}
              showAddButton
              onAdd={this.onAddLeave}
            />
            <TimekeeperLeaveLsv rows={leaves} onEdit={this.onLeaveEdit} />
            <TimekeeperLeaveModal
              setModal={this.setLeaveModal}
              setWeek={this.setWeek}
              id={leaveId}
              isOpen={isLeaveModalOpen}
              isNew={isLeaveNew}
              onSave={this.onSaveLeave}
            />
          </TabPane>

          <TabPane tabId="5" className="mb-2 p-3">
            <TimekeeperWeekBar
              handleDownloadCsv={this.handleDownloadCsv}
              handleDownloadApproverReport={this.handleDownloadApproverReport}
              showEditor={this.showTimesheetEditor}
              showActions
              week={currentWeek}
              showNewButton={false}
              showExportButtons={omEnabled}
              handleTimesheetAction={this.handleTimesheetsApproval}
              setWeek={this.setWeek}
              reload={this.loadTimesheets}
              teamSummaryExportEnabled={omEnabled}
            />
            <TimekeeperSummariesLsv
              rows={summaries}
              rowSelection={summarySelection}
              onView={this.onSummaryView}
              handleSelect={this.onSummarySelect}
              currentUser={currentUser}
            />
            <TimekeeperSummaryModal
              setModal={this.setSummaryModal}
              id={summaryId}
              isOpen={isSummaryModalOpen}
              handleTimesheetAction={this.handleTimesheetsApproval}
              budgetHours={budgetHours}
              totalHours={totalHours}
            />
          </TabPane>

          <TabPane tabId="6" className="mb-2 p-3">
            <CostcodesLsv rows={costcodes} handleUpload={this.handleUploadCostCodes} />
          </TabPane>

          <TabPane tabId="7" className="mb-2 p-3">
            <AttributeDefsLsv rows={attributeDefs} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ office, profile, manage, realm }) => ({
  office,
  profile,
  manage,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(TimeKeeper));
